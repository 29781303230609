import React, { useEffect, useState } from 'react'
import Input from '../../components/form/Input'
import SelectInput from '../../components/form/SelectInput'
import { useGetDesignationsQuery, useUpdateDesignationMutation } from '../../services/designationSlice'
import { useNavigate, useParams } from 'react-router-dom'
import ApiErrorModal from '../../components/modals/ApiErrorModal'
import SuccessModal from '../../components/modals/SuccessModal'
import SectionHeader from '../../components/form/SectionHeader'
import Section from '../../components/form/Section'
import SectionBody from '../../components/form/SectionBody'
import Loading from '../../components/ui/Loading'
import FormattedInput from '../../components/form/FormattedInput'

const EditDesignation = () => {

    const navigate = useNavigate();
    const { id } = useParams()

    const [updateDesignation, updateDesignationResponse] = useUpdateDesignationMutation()
    const designationData = useGetDesignationsQuery()
    const [isChecked, setIsChecked] = useState(false);

    const btnClassName = "btn btn-primary rounded-pill px-5 text-white my-3"



    const handleSubmit = (e) => {

        e.preventDefault();
        const form = e.target;
        if (form.checkValidity()) {

            let body = {
                name: form['Designation Name'].value,
                desigId: null,
                offerLetter: form['tinymceId'].value,
                color: form['Color'].value,
                designationId: isChecked ? form['Designation Id'].value : 0
            }



            updateDesignation({ id, body })
                .then(() => {
                    designationData.refetch()
                    navigate('/designations')
                })
        }
        else {
            form.classList.add('was-validated')
        }

    }

    useEffect(() => {
        if (designationData.isSuccess) {
            if (designationData.data.filter((item) => item.id == id)[0].designationId) setIsChecked(true)
        }
    }, [designationData.isSuccess])


    if (designationData.isLoading) return <Loading />
    if (designationData.isError) return <ApiErrorModal response={designationData} />
    const filteredData = designationData.data.filter((item) => item.id == id)

    return (
        <div className='container p-3 p-lg-5'>
{
    console.log('ddf',filteredData)
}
            {/* {updateDesignationResponse.isSuccess && <SuccessModal message={"Designation has been updated."} />} */}
            {updateDesignationResponse.isError && <ApiErrorModal response={updateDesignationResponse} />}

            <h4 className='fw-bold mb-3'>Edit Designation</h4>

            <form onSubmit={handleSubmit} noValidate >

                <Section title={'Personal Details'} className='row-cols-1 row-cols-lg-3 g-3 mb-3'>
                    <Input containerClass="" name={"Designation Name"} defaultValue={filteredData[0].name} required />
                    {/* <Input containerClass="" name={"desigId"} placeholder={'Designation ID'} defaultValue={filteredData[0].desigId} required /> */}
                    <Input containerClass={''} inputClassName={''} type={'color'} name={"Color"} defaultValue={filteredData[0].color} placeholder={'Color'} required style={{ height: 38 }} />


                    {
                        isChecked &&
                        <SelectInput
                            name={"Designation Id"}
                            placeholder={"Leader"}
                            options={[
                                ...(designationData?.data?.map((item) => ({
                                    value: item?.id,
                                    label: item?.name,
                                })) || [])
                            ]}
                            defaultValue={filteredData[0].designationId}
                            required
                        />
                    }

                </Section>
                {/* <SectionBody className='row-cols-1 row-cols-lg-3 g-3 mb-3'>
                    <div className="col">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" checked={isChecked} id="flexCheckDefault" onChange={(e) => setIsChecked(e.target.checked)} />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                Leader
                            </label>
                        </div>
                    </div>

                    
                    
                </SectionBody> */}

                <SectionBody className=''>
                    <div className="d-flex gap-2 flex-column w-100">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" checked={isChecked} id="flexCheckDefault" onChange={(e) => setIsChecked(e.target.checked)} />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                Leader
                            </label>
                        </div>

                        <div className="form-check ">
                            <div className="d-flex gap-3 mb-3">
                                <label class="fw-bold mb-1 ms-1" for="designationOfferLetter">Designation Offer Letter</label>
                                <button class="btn btn-outline-primary ms-2" data-bs-target="#variablesModal" data-bs-toggle="modal" type="button">Available Variables</button>
                            </div>


                            {/* <textarea class="form-control rounded px-3 border-primary border-2 mt-3" id="Offer Letter" name={"Offer Letter"} placeholder="Designation Offer Letter" required="" spellcheck="false"></textarea> */}

                            <FormattedInput name={'tinymceId'} label={' '} defaultValue={filteredData[0].offerLetter} />

                        </div>



                    </div>
                </SectionBody>

                {
                    updateDesignationResponse.isLoading
                        ?
                        <button class={btnClassName} type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Updating...
                        </button>
                        :
                        <button type='submit' className={btnClassName}>Update</button>
                }
            </form>

            <div class="modal fade" id="variablesModal" tabindex="-1" aria-labelledby="variablesModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="variablesModalLabel">Available Variables</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <ul>
                           
                            <li>[ISSUE_DATE]</li>
                            <li>[DESIGNATION]</li>
                            <li>[ASSOCIATE_NAME]</li>
                            <li>[ASSOCIATE_ID]</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        </div>
    )
}

export default EditDesignation