import React from 'react';
import Select from 'react-select';
import { convertToFiveDigits } from '../../utils/ConvertToFive';

const SearchSelectInput = ({ name, options, containerClass, placeholder, inputClass, noLabel, value, onChange,  width, ...attributes }) => {
    const containerClassName = 'd-flex flex-column align-items-start col  ' + (containerClass ? containerClass : '');
    const selectClassName = ' border-primay border-2 ' + (inputClass ? inputClass : '');
    const lableClass = "fw-bold mb-1 ms-1";

    const handleChange = (selectedOption) => {
        onChange(selectedOption.value);
    };

    return (
        <div className={containerClassName}>
            {noLabel || <label htmlFor={name} className={lableClass}>{placeholder ? placeholder : name}</label>}
            <Select
                className={selectClassName}
                options={options}
                placeholder={placeholder ? placeholder : name}
                value={options.find(option => option.value === value)}
                onChange={handleChange} 
               
                getOptionLabel={(option) => `${option.label} (S3A${convertToFiveDigits(option.serialNo)})`}

                getOptionValue={(option) => option.value} // Use the ID as the value for selection
                {...attributes}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? 'grey' : '#70018B',
                        width: width || 200,
                        borderRadius: '50px', // Adjust the value to control the roundness
                        paddingLeft: '15px', // Add some padding to the left and right
                        paddingRight: '15px',
                        border: '2px solid #70018B', // Add a border
                        backgroundColor: '#f8f9fa', // Add a background color
                        color: '#495057', // Text color
                        outline: 'none',

                    }),
                }}
            />
        </div>
    );
};



export default SearchSelectInput;
