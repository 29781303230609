// import React, { useRef } from 'react';
// import Icons from '../../components/ui/Icons';
// import Api from '../../constants/Api';
// import ReactToPrint from 'react-to-print';
// import { convertToFiveDigits } from '../../utils/ConvertToFive';
// import logo from '../../assets/images/logo_sudikshya.png'

// const ProfileModal = ({ item }) => {
//     const modalId = "profileViewDownloadModal" + item.associate.id;
//     const modalBodyRef = useRef();

//     const ModalBody = () => (
//         <div className="modal-body p-0" id={modalId + "ModalBody"} ref={modalBodyRef}>
//             {/* Your existing modal body content */}
//             <div className='w-100 bg-primary mb-3' style={{ height: 10 }}>
//                 <div className="bg-warning h-100 w-50 mx-auto"></div>
//             </div>

//             <div className="d-flex justify-content-between mb-4 px-3">
//                 <div>Ref. ID:   {item?.sponsor?.id && `S3A${convertToFiveDigits(item?.sponsor?.id)}`}</div>
//                 <div>Date of Joining: { item?.associate?.createdAt && !isNaN(new Date(item?.associate?.createdAt)) ? new Date(item?.associate?.createdAt).toLocaleDateString('en-GB').split('/').join('-') : ''}</div>
//             </div>

//             <div className="d-flex justify-content-between px-3 mb-5">

//                 <div className=' d-flex flex-column'>
//                     <h4 className='fw-bold  text-uppercase mb-2 '>{item?.associate?.name}</h4>
//                     <h5 className='fw-bold text-white text-uppercase bg-primary py-1 px-3 rounded mb-2 overflow-hidden' style={{ flexShrink: 0, maxWidth: '75%' }}>
//                         {item?.designation?.name}
//                     </h5>
//                     <div>Mob. No.: {item?.associate?.mobileNumber}</div>
//                     <div>Associate ID: {item?.associate?.serialNo && `S3A${convertToFiveDigits(item?.associate?.serialNo)}`}</div>
//                     <div>Mobile No.: {item?.associate?.mobileNumber}</div>
//                     <div>Date of Birth: {item?.associate?.dob}</div>
//                     <div>Sponsor Name: {item?.sponsor?.name}</div>
//                     <div>Address: {item?.address?.flatNumber}, {item?.address?.addressLine1}, {item?.address?.landmark}, {item?.address?.cityVillage}, {item?.address?.district}, {item?.address?.state}</div>
//                 </div>

//                 <div>
//                     <img id={'#image' + modalId} src={Api.BASE_URL + `${item?.associate?.passPhoto}`} className='border border-primary border-5 rounded-3 object-fit-cover ' style={{ width: 100, height: 150 }} alt="profile" />


//                 </div>


//             </div>

//             <div className=' d-flex justify-content-center align-items-center position-relative  my-5' style={{height:'100px'}}>
//                 <img src={logo} alt="" className=' bg-white w-50 border border-4 border-warning px-4 py-2 rounded-pill  position-absolute'  style={{zIndex: '2000',marginTop:'-0px'}} />
//                 <div className='bg-success text-success min  z-0' style={{ minHeight: '10px', minWidth: '100%', padding: '5px', bottom:'33px',  }}>            </div>
//             </div>



//         </div>
//     );

//     return (
//         <>

//             <div className='bg-primary rounded text-center px-2 py-1 text-nowrap'>
//                 <span className='' data-bs-toggle="modal" data-bs-target={"#" + modalId}>{Icons.view()}</span>

//             </div>

//             <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby={modalId + "Label"} aria-hidden="true">
//                 <div className="modal-dialog">
//                     <div className="modal-content">
//                         <div className="modal-header">
//                             <h1 className="modal-title fs-5" id={modalId + "Label"}>{item.associate.name}'s Profile</h1>
//                             <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//                         </div>
//                         <ModalBody />
//                         <div className="modal-footer">
//                             {/* <ReactToPrint
//                                 trigger={() => (
//                                     <div className='bg-primary  rounded text-center px-2 py-1 text-nowrap'>
//                                         <span className="text-white me-1">Download</span> <span>{Icons.download('')}</span>
//                                     </div>
//                                 )}
//                                 content={() => modalBodyRef.current}
//                             /> */}

//                             <ReactToPrint
//                                 trigger={() => (
//                                     <div className='bg-primary  rounded text-center px-2 py-1 text-nowrap'>
//                                         <span className="text-white me-1">Download</span> {Icons.download('')}
//                                     </div>
//                                 )}
//                                 content={() => modalBodyRef.current}
//                                 pageStyle={`@page { size: A4; margin: 1 0; }`}
//                             />

//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default ProfileModal;

// import React, { useRef } from "react";
// import Icons from "../../components/ui/Icons";
// import Api from "../../constants/Api";
// import ReactToPrint from "react-to-print";
// import { convertToFiveDigits } from "../../utils/ConvertToFive";
// import logo from "../../assets/images/logo.png";

import React, { useRef } from 'react';
import Icons from '../../components/ui/Icons';
import Api from '../../constants/Api';
import ReactToPrint from 'react-to-print';
import { convertToFiveDigits } from '../../utils/ConvertToFive';
import logo from '../../assets/images/logo_sudikshya.png'
import Mail from "../../assets/images/mail.png";



const ProfileModal = ({ item, desigName }) => {
    const modalId = "profileViewDownloadModal" + item.associate.id;
    const modalBodyRef = useRef();

    const ModalBody = () => (
        <div
            className="modal-body p-0  bgprofile"
            id={modalId + "ModalBody"}
            ref={modalBodyRef}
        // style={{ width: 450 }}
        >
            {/* Your existing modal body content */}
            <div className="pt-3 px-3">
                <div className="text-end"><button
                    type="button"
                    className="btn-close bg-white p-2"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button></div>
                <div className="ps-3">
                    <img src={logo} className="w-50" alt="" />
                </div>
                <div className="d-flex justify-content-between mt-5 pt-5">
                    <div className="fs-6">
                        Ref. ID :
                        {item?.associate?.serialNo &&
                            ` SUP${convertToFiveDigits(item?.associate?.serialNo)}`}
                    </div>
                    <div className="fs-6">
                        Date of Joining: {item?.associate?.createdAt}
                    </div>
                </div>

                <div className="d-flex justify-content-between mt-5">
                    <div className=" d-flex flex-column">
                        <div className="fs-1 fw-bold  text-uppercase">
                            {item?.associate?.name}
                        </div>
                        <div
                            className="fs-3 fw-bold text-primary text-uppercase mb-2 overflow-hidden"
                            style={{ flexShrink: 0 }}
                        >
                            {
                                desigName?.find((i) => i.id === item?.associate?.designationId)
                                    ?.name
                            }
                        </div>
                        <div className="mt-4">
                            Mob. No.: {item?.associate?.mobileNumber}
                        </div>
                        <div className="mt-2">
                            Associate ID:{" "}
                            {item?.associate?.serialNo &&
                                `S3A${convertToFiveDigits(item?.associate?.serialNo)}`}
                        </div>
                        <div className="mt-2">
                            Mobile No.: {item?.associate?.mobileNumber}
                        </div>
                        <div className="mt-2">Date of Birth: {item?.associate?.dob}</div>
                        <div className="mt-2">Sponsor Name: {item?.sponsor?.name}</div>
                        <div className="mt-2">
                            Address: {item?.address?.flatNumber},{" "}
                            {item?.address?.addressLine1}, {item?.address?.landmark},{" "}
                            {item?.address?.cityVillage}, {item?.address?.district},{" "}
                            {item?.address?.state}
                        </div>
                        <div className="mt-4 d-flex align-items-center">
                            <img
                                className="me-2"
                                src={Mail}
                                style={{ maxWidth: "50px" }}
                                alt="icon"
                            />
                            <div className="fs-6 fw-bold">support@sudikshya.com</div>
                        </div>
                    </div>

                    <div>
                        <img
                            id={"#image" + modalId}
                            src={Api.BASE_URL + `${item?.associate?.passPhoto}`}
                            className="border border-primary border-5 rounded-3 object-fit-cover "
                            style={{ width: 100, height: 150 }}
                            alt="profile"
                        />
                    </div>
                </div>

                <div className="mt-5 pb-3 fs-3 fw-bold text-center text-primary">
                    Toll-Free: 1800 103 5040
                </div>

                {/* <div
          className=" d-flex justify-content-center align-items-center position-relative  my-5"
          style={{ height: "100px" }}
        >
          <img
            src={logo}
            alt=""
            className=" bg-white w-50 border border-4 border-warning px-4 py-2 rounded-pill  position-absolute"
            style={{ zIndex: "2000", marginTop: "-0px" }}
          />
          <div
            className="bg-success text-success min  z-0"
            style={{
              minHeight: "10px",
              minWidth: "100%",
              padding: "5px",
              bottom: "33px",
            }}
          >
            {" "}
          </div>
        </div> */}
            </div>

            {/* <div className="w-100 bg-primary mb-3" style={{ height: 10 }}>
        <div className="bg-warning h-100 w-50 mx-auto"></div>
      </div> */}
        </div>
    );

    return (
        <>
            <div className='bg-primary rounded text-center px-2 py-1 text-nowrap'>
                <span className='' data-bs-toggle="modal" data-bs-target={"#" + modalId}>{Icons.view()}</span>
            </div>


            <div
                className="modal fade "
                id={modalId}
                tabIndex="-1"
                aria-labelledby={modalId + "Label"}
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content rounded-3 overflow-hidden" >
                        {/* <div className="modal-header">
              <h1 className="modal-title fs-5" id={modalId + "Label"}>
                {item.associate.name}'s Profile
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div> */}
                        <div className="rounded-bottom-3" style={{ overflow: "scroll" }}>
                            <ModalBody />
                        </div>
                        <div className="modal-footer">
                            {/* <ReactToPrint
                                trigger={() => (
                                    <div className='bg-primary  rounded text-center px-2 py-1 text-nowrap'>
                                        <span className="text-white me-1">Download</span> <span>{Icons.download('')}</span>
                                    </div>
                                )}
                                content={() => modalBodyRef.current}
                            />  */}

                            <ReactToPrint
                                trigger={() => (
                                    <div className='bg-primary  rounded text-center px-2 py-1 text-nowrap'>
                                        <span className="text-white me-1">Download</span> {Icons.download('')}
                                    </div>
                                )}
                                content={() => modalBodyRef.current}
                                pageStyle={`@page { size: A4; margin: 1 0; }`}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProfileModal;
