import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useGetLeadsQuery, useUpdateLeadStatusMutation } from '../../services/leadSlice'
import Loading from '../../components/ui/Loading';
import ApiErrorModal from '../../components/modals/ApiErrorModal';
import Icons from '../../components/ui/Icons';
import SelectInput from '../../components/form/SelectInput';
import InputSearch from '../../components/form/InputSearch';


const Leads = () => {

  const response = useGetLeadsQuery();
  const [updateStatus, setUpdateStatusResponse] = useUpdateLeadStatusMutation()

  const [searchValue, setSearchyValue] = useState("")
  const [selectValue, setSelectValue] = useState("")

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 100;


  const handleUpdateStatus = (leadId) => {
    const confirmation = window.confirm("Do you want to update the status?");

    if (confirmation) {
      let status = prompt("Enter Status to Update");

      if (status !== null && status.trim() !== "") {

        updateStatus({ id: leadId, status })
          .then(() => response.refetch())
      } else {
        alert("Update canceled or empty status entered.");
      }
    } else {
      alert("Update canceled.");
    }
  };


  if (response.isLoading) return <Loading />
  if (response.isError) return <ApiErrorModal response={response} />


  const filteredData = response?.data
    .filter((item) => {
      if (searchValue?.length === 0 && selectValue?.length === 0) return true;


      const isSearchMatch = searchValue?.length > 0 && (
        item?.id.toLowerCase().includes(searchValue.toLowerCase()) ||
        item?.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        item?.associateName.toLowerCase().includes(searchValue.toLowerCase()) ||
        item?.mobileNumber.toLowerCase().includes(searchValue.toLowerCase()) ||
        item?.address.toLowerCase().includes(searchValue.toLowerCase())
      );

      let isProductMatch = selectValue?.length > 0;

      if (isProductMatch) {
        switch (selectValue) {
          case 'Active':
            isProductMatch = item.status == 'Approved';
            break;
          case 'InActive':
            isProductMatch = item.status == 'SIGN_UP_PENDING' || item.status === 'Pending' || item.status === 'ReApplied'  ;
            break;
          case 'Rejected':
            isProductMatch = item.status == 'Rejected';
            break;
          default:
            isProductMatch = true;
        }
      }

      if (searchValue?.length === 0) return isProductMatch
      if (selectValue?.length === 0) return isSearchMatch

      return isProductMatch && isSearchMatch;
    })

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className='p-3 p-lg-5'>

      <h4 className='text-primary fw-bold mb-3'>Leads</h4>

      {/* <div className='d-flex mb-3 align-items-center'>
        <Link className='btn btn-primary rounded-pill px-5 ms-auto me-3' to={"/create/lead"}>Create Lead</Link>
      </div> */}

      <div className="ms-auto d-flex gap-3 align-items-center justify-content-center w-25 mb-2">
        {/* <SelectInput
          name={"Select Status"}
          noLabel
          options={
            [
              { value: '', label: 'Select' },
              { value: 'Active', label: 'Active' },
              { value: 'InActive', label: 'InActive' },
              { value: 'Rejected', label: 'Rejected' },
            ]
          }

          value={selectValue} onChange={(e) => setSelectValue(e.target.value)}
          containerClass={''}
          required
        /> */}
        <InputSearch name={"Search Lead"} containerClass={" w-50"} value={searchValue} onChange={(e) => setSearchyValue(e.target.value)} />

      </div>


      <div className='card rounded-4 overflow-hidden shadow p mb-2'>

        <div className="table-responsive">
          <table className="table table-borderless table-hover">
            <thead>
              <tr className='border-bottom'>
                <th scope="col" className='text-muted'>Ld. ID</th>
                <th scope="col" className='text-muted'>Name</th>
                <th scope="col" className='text-muted'>Lead Referrer</th>
                <th scope="col" className='text-muted'>Address</th>
                <th scope="col" className='text-muted'>Mobile Number</th>
                <th scope="col" className='text-muted'>Date</th>
                <th scope="col" className='text-muted'>Status</th>
                <th scope="col" className='text-muted'>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                //333333333333333333333333333333333

                currentItems?.reverse()?.map(item =>
                  <tr key={item.id}>
                    <th scope="row">{item?.id}</th>
                    <th>{item?.name}</th>
                    <th>{item?.associateName}</th>
                    <th>{item?.address}</th>
                    <th>{item?.mobileNumber}</th>
                    <th>{ item?.createdAt && !isNaN(new Date(item?.createdAt)) ? new Date(item?.createdAt).toLocaleDateString('en-GB').split('/').join('-') : ''}</th>
                    <th className='text-success'>{item?.status} <span>{Icons?.upArrow}</span></th>
                    <td>
                      <button
                        className='btn btn-sm btn-success rounded-pill px-3 text-white'
                        onClick={() => handleUpdateStatus(item.id)}
                      >
                        Update Status
                      </button>
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
        </div>

      </div>

      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-center">
          {Array.from({ length: Math.ceil(filteredData.length / itemsPerPage) }, (_, index) => (
            <li key={index} className={`page-item ${index + 1 === currentPage ? 'active' : ''}`}>
              <button className="page-link" onClick={() => setCurrentPage(index + 1)}>
                {index + 1}
              </button>
            </li>
          ))}
        </ul>
      </nav>

    </div>
  )
}

export default Leads