import React from 'react'
import ErrorModal from './ErrorModal'

const ApiErrorModal = ({ response ,pid }) => {

    const error = response.isError ? response.error : null
    // const [signIn, signInResponse] = useSignInMutation({ fixedCacheKey: "signIn" });

    // const signOut = () => {
    //     signInResponse.reset()
    //     firebaseSignOut(auth).catch(console.error);
    //     return;
    // }
    
    if (error) {
        if (error.status === "FETCH_ERROR") return <ErrorModal label={"No Internet"} message={"Kindly check your internet connection."} />
        else if (error?.data?.status === 400 || error?.data?.status === 404)  return <ErrorModal label={"Bad Request"} message={ error?.data?.messages?.error} pid={pid} />
        else if (error.status === 401) return <ErrorModal label={"Unauthorized"} message={error?.message} />
        else if (error.status === 400) return <ErrorModal label={"Bad Request"} message={error?.message} />
        else if (error.data?.message) return <ErrorModal label={"Bad Request"} message={error?.message} />
        else return <ErrorModal label={"Error"} message={"Something went wrong"} />
    }

    return;
}

export default ApiErrorModal