


import React, { useState } from 'react'
import { useGetOrdersQuery } from '../../services/orderSlice'
import Input from '../../components/form/Input';
import { Link, useNavigate } from 'react-router-dom';
import Loading from '../../components/ui/Loading';
import ApiErrorModal from '../../components/modals/ApiErrorModal';
import { useGetAssociatesQuery, useGetPendingAssociateQuery, useUpdateAssociatePaymentModeMutation, useUpdateAssociateStatusMutation } from '../../services/associateSlice';
import { useGetStudentsQuery, useUpdateStudentMutation, useUpdateStudentPaymentModeMutation, useUpdateStudentStatusMutation } from '../../services/studentSlice';
import SuccessModal from '../../components/modals/SuccessModal';
import StudentInvoiceModal from '../student/StudentInvoiceModal';
import AssociateInvoiceModal from '../associate/AssociateInvoiceModal';
import { convertToFiveDigits } from '../../utils/ConvertToFive';
import SelectInput from '../../components/form/SelectInput';
import InputSearch from '../../components/form/InputSearch';
import PayModeModal from '../../components/modals/PayModeModal';

const Orders = () => {
  const navigate = useNavigate();

  const response = useGetAssociatesQuery();
  const studentResponse = useGetStudentsQuery();

  const [action, setAction] = useState(null);
  const [updateId, setUpdateId] = useState(null);
  const [searchValue, setSearchyValue] = useState("")
  const [selectValue, setSelectValue] = useState("")

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 100;


  const [studentAction, setStudentAction] = useState(null);
  const [updateStudentId, setUpdateStudentId] = useState(null)

  const [updateStatus, updateStatusResponse] = useUpdateAssociateStatusMutation();
  const [updateStudentStatus, updateStudentStatusResponse] = useUpdateStudentStatusMutation();

  const [updateStudent, updateStudentResponse] = useUpdateStudentPaymentModeMutation()
  const [updateAssociate, updateAssociateResponse] = useUpdateAssociatePaymentModeMutation();


  const getStatusColor = (status) => {
    if (status === 'Pending' || status === 'ReApplied') return 'orange';
    else if (status === 'Approved') return 'green';
    else if (status === 'Rejected') return 'red';

  }

  if (response.isLoading || studentResponse.isLoading) return <Loading />
  if (response.isError) return <ApiErrorModal response={response} />


  const mergedData = [...response.data, ...studentResponse.data];


  mergedData.sort((a, b) => (a.orders[0]?.id || 0) - (b.orders[0]?.id || 0));

  const filteredData = mergedData
    .filter((item) => {
      if (searchValue?.length === 0 && selectValue?.length === 0) return true;
      const isSearchMatch = searchValue?.length > 0 && (
        `SU${convertToFiveDigits(item?.orders[0]?.id)}`.toLowerCase().includes(searchValue.toLowerCase()) ||
        item?.student?.studentName.toLowerCase().includes(searchValue.toLowerCase()) ||
        item?.associate?.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        `S3A${convertToFiveDigits(item?.student?.id)}`.toLowerCase().includes(searchValue.toLowerCase()) ||
        `S3A${convertToFiveDigits(item?.associate?.serialNo)}`.toLowerCase().includes(searchValue.toLowerCase()) ||
        item?.student?.mobileNumber.toLowerCase().includes(searchValue.toLowerCase()) ||
        item?.associate?.mobileNumber.toLowerCase().includes(searchValue.toLowerCase()) ||
        item?.sponsor?.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      let isMatch = selectValue?.length > 0;
      if (isMatch) {
        switch (selectValue) {
          case 'Approved':
            isMatch = item?.student?.status == 'Approved' || item?.associate?.status == 'Approved';
            break;
          case 'Pending':
            isMatch = item?.student?.status == 'SIGN_UP_PENDING' || item?.student?.status === 'Pending' || item?.associate?.status == 'SIGN_UP_PENDING' || item?.associate?.status === 'Pending';
            break;
          case 'Rejected':
            isMatch = item?.student?.status == 'Rejected' || item.student?.status == 'Rejected';
            break;
          default:
            isMatch = true;
        }
      }
      if (searchValue?.length === 0) return isMatch
      if (selectValue?.length === 0) return isSearchMatch
      return isMatch && isSearchMatch;
    })



  const sortedData = [...filteredData].sort((a, b) => (b.orders[0]?.id) - (a.orders[0]?.id));



  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  const handleApprove = (id, isAssociate) => {
    const isApproved = window.confirm(`Are you sure you want to approve this ${isAssociate ? 'associate' : 'student'}?`);
    if (isApproved) {
      if (isAssociate) {
        setAction('APPROVE');
        setUpdateId(id);
        updateStatus({ id, status: 'Approved' }).then((res) => response.refetch());
      } else {
        setStudentAction('APPROVE');
        setUpdateStudentId(id);
        updateStudentStatus({ id, status: 'Approved' }).then((res) => studentResponse.refetch());
      }
    }
  };



  const handleReject = (id, isAssociate) => {
    const isRejected = window.confirm(
      `Are you sure you want to reject this ${isAssociate ? 'associate' : 'student'}?`
    )


    const reason = prompt('Enter rejection reason:');

    console.log("isRejected", reason, isRejected)



    if (isRejected && reason) {
      if (reason !== null) {

        if (isAssociate) {
          setAction('REJECT');
          setUpdateId(id);
          updateStatus({ id, status: 'Rejected', reason }).then((res) => {
            if (!res.error) response.refetch();
          });
        } else {
          setStudentAction('REJECT');
          setUpdateStudentId(id);
          updateStudentStatus({ id, status: 'Rejected', reason }).then((res) => {
            if (!res.error) studentResponse.refetch();
          });
        }
      }
    }


  };

  return (
    <div className='p-3 p-lg-5'>
      {updateStatusResponse.isSuccess && <SuccessModal message={"Associate has been " + (action === "APPROVE" ? "approved." : "rejected.")} />}
      {updateStatusResponse.isError && <ApiErrorModal response={updateStatusResponse} />}

      {updateAssociateResponse.isSuccess && <SuccessModal message={"Payment mode has been updated"} />}
      {updateStudentResponse.isSuccess && <SuccessModal message={"Payment mode has been updated"} />}


      {updateStudentStatusResponse.isSuccess && <SuccessModal message={"Student has been " + (studentAction === "APPROVE" ? "approved." : "rejected.")} />}
      {updateStudentStatusResponse.isError && <ApiErrorModal response={updateStudentStatusResponse} />}


      <h4 className='text-primary fw-bold mb-3'>Order Management</h4>

      <div className="container">
        <div className="row row-cols-1 row-cols-lg-3 g-3 mb-3">
          <div className="col">
            <SelectInput
              name={"Select Status"}
              noLabel
              options={
                [
                  { value: '', label: 'Select' },
                  { value: 'Approved', label: 'Approved' },
                  { value: 'Rejected', label: 'Rejected' },
                  { value: 'Pending', label: 'Pending' },
                ]
              }

              value={selectValue} onChange={(e) => setSelectValue(e.target.value)}
              containerClass={'w-75'}
              required
            />
          </div>
          <div className="col"><InputSearch name={"Search"} containerClass={"w-100"} value={searchValue} onChange={(e) => setSearchyValue(e.target.value)} /></div>
        </div>
      </div>

      <div className='card rounded-4 overflow-hidden shadow mb-3 '>

        <div className="table-responsive">
          <table className="table table-borderless table-hover "  >
            <thead>
              <tr className='border-bottom'>
                <th scope="col" className='text-muted'>Order ID</th>
                <th scope="col" className='text-muted'>Date</th>
                <th scope="col" className='text-muted'>Name</th>
                <th scope="col" className='text-muted'>Mobile No.</th>
                <th scope="col" className='text-muted'>Designation</th>
                <th scope="col" className='text-muted'>Product</th>
                <th scope="col" className='text-muted'>Payment Mode</th>
                <th scope="col" className='text-muted'>Payment</th>
                <th scope="col" className='text-muted'>Invoice</th>
                <th scope="col" className='text-muted'>Status</th>
                <th scope="col" className='text-muted'>Action</th>
              </tr>
            </thead>

            <tbody>
              {currentItems
                ?.filter((item) => item?.associate?.status != 'SIGN_UP_PENDING')?.map((item, i) => (
                  <tr key={i}>
                    <th scope='row'>{item.orders[0]?.id && `SU${convertToFiveDigits(item.orders[0]?.id)}`}</th>
                    <th>
                      {item?.associate?.createdAt
                        ? new Date(item.associate.createdAt).toLocaleDateString('en-GB', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                        }).replace(/\//g, '-')
                        : new Date(item.student.createdAt).toLocaleDateString('en-GB', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                        }).replace(/\//g, '-')}
                    </th>


                    <th>{item?.associate?.name || item?.student?.studentName}</th>
                    <th>{item?.associate?.mobileNumber || item?.student?.mobileNumber}</th>
                    <th>{item?.designation?.name || "Student"}</th>
                    <th>{item?.associate ? "Associate" : item?.category?.name + '-' + item?.course?.name}</th>
                    {/* <th>{item?.associate?.paymentMode || item?.student?.modeOfPayment}</th> */}

                    <th>
                      {item?.associate?.paymentMode &&
                        item?.payment?.paymentType &&
                        <>
                          {(item?.payment?.paymentType !== 'Manual' ?
                            `App Payment (${item?.payment?.paymentType})` :
                            `Manual (${item?.associate?.paymentMode})`
                          )}
                        </>
                      }


                      {item?.student?.modeOfPayment &&
                      item?.payment[0]?.paymentType &&
                        <>
                          {
                            (item?.payment[0]?.paymentType !== 'Manual' ?
                              `App Payment (${item?.payment[0]?.paymentType || ''})` :
                              `Manual (${item?.student?.modeOfPayment || ''})`
                            )
                          }
                        </>
                      }


                    </th>




                    <th>{(item?.associate?.paid == '1' || item?.student?.paid == '1') ? <span className='text-success'>Done</span> : <span className='text-danger'>Due</span>}</th>
                    <th>{(item?.associate?.paid == '1' || item?.student?.paid == '1') ? (item?.associate ? <AssociateInvoiceModal item={item} /> : <StudentInvoiceModal item={item} />) : ''}</th>
                    <th style={{ color: getStatusColor(item?.associate?.status || item?.student?.status) }}>
                      {item?.associate?.status || item?.student?.status}
                    </th>

                    <td className='d-flex'>
                      {item.associate &&
                        <>
                          <div className='btn btn-sm btn-primary rounded-pill px-3 me-3 py-0 ' onClick={() => navigate(`/team/${item.associate.id}`)}>Details</div>
                          {(updateStatusResponse.isLoading && action === "APPROVE" && updateId === item?.associate?.id)
                            ?
                            <button class={'btn btn-sm btn-success rounded-pill px-3 me-3 text-white py-0'} type="button" disabled>
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </button>
                            :
                            <div>
                              {
                                item?.associate?.paid !== '0' &&

                                <>
                                  {item?.associate?.status === 'Approved'
                                    ?
                                    <div className='btn btn-sm btn-success rounded-pill px-3 me-3 text-white py-0'
                                      style={{ opacity: item?.associate?.status === 'Approved' ? 0.5 : 1, cursor: item?.associate?.status === 'Approved' ? 'not-allowed' : 'pointer' }}>
                                      Approve
                                    </div>
                                    :
                                    <div
                                      className={`btn btn-sm btn-success rounded-pill px-3 me-3 text-white py-0 ${item?.associate?.status === 'Approved' ? 'disabled-btn' : ''}`}
                                      onClick={() => handleApprove(item?.associate?.id, true)}
                                    >
                                      Approve
                                    </div>}
                                </>
                              }
                            </div>
                          }
                          {(updateStatusResponse.isLoading && action === "REJECT" && updateId === item?.associate?.id)
                            ?
                            <button class={'btn btn-sm btn-danger rounded-pill px-3 me-3 text-white py-0'} type="button" disabled>
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </button>
                            :
                            <div>
                              {
                                item?.associate?.status === 'Rejected'
                                  ?
                                  <div className='btn btn-sm btn-danger rounded-pill px-3 me-3 text-white py-0'
                                    style={{ opacity: item?.associate?.status === 'Rejected' ? 0.5 : 1, cursor: item?.associate?.status === 'Rejected' ? 'not-allowed' : 'pointer' }}>
                                    Reject
                                  </div>
                                  :
                                  <div className='btn btn-sm btn-danger rounded-pill px-3 me-3 text-white py-0'
                                    onClick={() => handleReject(item?.associate?.id, true)}>
                                    Reject
                                  </div>
                              }
                            </div>
                          }


                          {

                            item?.associate?.paid === '0' &&
                            <PayModeModal
                              id={item.associate.id}
                              isAssociate={true}
                              updateStudent={updateStudent}
                              updateStudentResponse={updateStudentResponse}
                              updateAssociate={updateAssociate}
                              updateAssociateResponse={updateAssociateResponse}
                              response={response}
                              studentResponse={studentResponse}
                            />

                          }


                        </>
                      }
                      {!item.associate &&
                        <>
                          <Link className='btn btn-sm btn-primary rounded-pill px-3 me-3 py-0' to={`/student/${item.student.id}`} >Details</Link>
                          {(updateStudentStatusResponse.isLoading && studentAction === "APPROVE" && updateStudentId === item.student.id)
                            ?
                            <button class={'btn btn-sm btn-success rounded-pill px-3 me-3 text-white py-0'} type="button" disabled>
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </button>
                            :
                            <div>
                              {
                                (item.student.paid !== '0') &&
                                <>
                                  {
                                    item?.student?.status === 'Approved'
                                      ?
                                      <div className='btn btn-sm btn-success rounded-pill px-3 me-3 text-white py-0'
                                        style={{ opacity: item?.student?.status === 'Approved' ? 0.5 : 1, cursor: item?.student?.status === 'Approved' ? 'not-allowed' : 'pointer' }}>
                                        Approve
                                      </div>
                                      :
                                      <div className='btn btn-sm btn-success rounded-pill px-3 me-3 text-white py-0'
                                        onClick={() => handleApprove(item.student.id, false)}>
                                        Approve
                                      </div>
                                  }
                                </>
                              }
                            </div>
                          }
                          {(updateStudentStatusResponse.isLoading && studentAction === "REJECT" && updateStudentId === item.student.id)
                            ?
                            <button class={'btn btn-sm btn-danger rounded-pill px-3 me-3 text-white py-0'} type="button" disabled>
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </button>
                            :
                            <div>
                              {
                                item?.student?.status === 'Rejected'
                                  ?
                                  <div className='btn btn-sm btn-danger rounded-pill px-3 me-3 text-white py-0'
                                    style={{ opacity: item?.student?.status === 'Rejected' ? 0.5 : 1, cursor: item?.student?.status === 'Rejected' ? 'not-allowed' : 'pointer' }}>
                                    Reject
                                  </div>
                                  :
                                  <div className='btn btn-sm btn-danger rounded-pill px-3 me-3 text-white py-0'
                                    onClick={() => handleReject(item.student.id, false)}>
                                    Reject
                                  </div>
                              }
                            </div>
                          }

                          {

                            (item.student.paid === '0') &&
                            <PayModeModal
                              id={item.student.id}
                              isAssociate={false}
                              updateStudent={updateStudent}
                              updateStudentResponse={updateStudentResponse}
                              updateAssociate={updateAssociate}
                              updateAssociateResponse={updateAssociateResponse}
                              response={response}
                              studentResponse={studentResponse}
                            />




                          }

                        </>
                      }
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-center">
          {Array.from({ length: Math.ceil(filteredData.length / itemsPerPage) }, (_, index) => (
            <li key={index} className={`page-item ${index + 1 === currentPage ? 'active' : ''}`}>
              <button className="page-link" onClick={() => setCurrentPage(index + 1)}>
                {index + 1}
              </button>
            </li>
          ))}
        </ul>
      </nav>

    </div>
  )

}

export default Orders



