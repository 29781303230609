import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";


export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getProducts: builder.query({ query: () => `/course`, keepUnusedDataFor: 0 }),
        getProduct: builder.query({ query: (id) => `/course?id=${id}`, keepUnusedDataFor: 0 }),
        getStaffProduct: builder.query({ query: () => `/admin/getPackage?id=1`, keepUnusedDataFor: 0 }),
        postProduct: builder.mutation({ query: (body) => ({ url: `/course/create`, method: 'POST', body }) }),
        setProductOrder: builder.mutation({ query: ({ id, order }) => ({ url: `/course/updateOrder?id=${id}&order=${order}`, method: 'POST', body: {} }) }),
        setProductStaff: builder.mutation({ query: ({ packageName, packagePrice }) => ({ url: `/admin/setPackage?id=1&packageName=${packageName}&packagePrice=${packagePrice}`, method: 'POST', body: {} }) }),

        deleteProduct: builder.mutation({ query: (id) => ({ url: `/course/delete?id=${id}`, method: 'DELETE', }) }),
        updateProduct: builder.mutation({ query: ({id,body}) => ({ url: `/course/edit?id=${id}`, method: 'PUT', body }) }),


    }),
})

export const {
    useGetProductsQuery,
    usePostProductMutation,
    useSetProductOrderMutation,
    useGetStaffProductQuery,
    useSetProductStaffMutation,
    useDeleteProductMutation,
    useGetProductQuery,
    useUpdateProductMutation
} = extendedApiSlice
