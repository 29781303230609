import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";


export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPackage: builder.query({ query: (id) => `/admin/getPackage?id=${id}`, keepUnusedDataFor: 0 }),

        
        getSiteInfo: builder.query({ query: (id) => `/admin/getSiteInfo?id=${id}`, keepUnusedDataFor: 0 }),
        setSiteInfo: builder.mutation({ query: ({id,body}) => ({ url: `/admin/setSiteInfo?id=${id}`, method: 'POST', body }) }),

        
        adminOtpSend: builder.mutation({ query: (body) => ({ url: `/admin/adminOtpSend`, method: 'POST', body }) }),
        changePassword: builder.mutation({ query: (body) => ({ url: `/admin/changePassword`, method: 'POST', body }) }),

       

    }),
})

export const {useGetPackageQuery ,useGetSiteInfoQuery,useSetSiteInfoMutation ,useAdminOtpSendMutation,useChangePasswordMutation} = extendedApiSlice
