import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const ErrorModal = ({ label, message, pid }) => {

    useEffect(() => {
        document.getElementById("errorModalButton")?.click()
    }, [])

    return (
        <>
            <button type="button" id="errorModalButton" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#errorModal">
                Launch demo modal
            </button>

            <div className="modal fade" id="errorModal" tabindex="-1" aria-labelledby="errorModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="errorModalLabel">{label ? label : "Error"}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">{message ? message : "Error"}</div>
                        <div className="modal-footer">
                            {pid && <Link to={`/product/courses/${pid}`} className="btn btn-danger">Delete Category</Link>}
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ErrorModal