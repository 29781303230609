import React, { useEffect, useState } from 'react'
import Icons from '../../components/ui/Icons'
import Input from '../../components/form/Input'
import { useDeleteCategoryMutation, useGetCategoriesQuery, usePostCategoryMutation, useUpdateCategoryMutation } from '../../services/categorySlice'
import { useNavigate, useParams } from 'react-router-dom'
import Loading from '../../components/ui/Loading'
import ApiErrorModal from '../../components/modals/ApiErrorModal'
import SuccessModal from '../../components/modals/SuccessModal'
import { useDeleteProductMutation, useGetProductsQuery, useSetProductOrderMutation } from '../../services/productSlice'
import SelectInput from '../../components/form/SelectInput'

const ProductCourses = () => {

  const { id } = useParams();
  
  const navigate = useNavigate();

  const response = useGetCategoriesQuery()
  const courseRes = useGetProductsQuery()
  const [isLoading, setIsLoading] = useState(false)

  const [selectValue, setSelectValue] = useState("")




  const [setProductOrder, setProductOrderResponse] = useSetProductOrderMutation()
  const [deleteProduct, deleteProductResponse] = useDeleteProductMutation()


  const handleProductDelete = (id) => {
    const shouldDelete = window.confirm('Are you sure you want to delete this Product?');

    if (shouldDelete) {
      deleteProduct(id)
        .then(() => courseRes.refetch())

    }
  };



  // const onClickUp = async (index, array) => {
  //   try {
  //     setIsLoading(true)
  //     const current = { id: array[index].course.id, order: array[index - 1].course.order }
  //     const prev = { id: array[index - 1].course.id, order: array[index].course.order }
  //     await setProductOrder(current)
  //     await setProductOrder(prev)
  //     setIsLoading(false)
  //   }
  //   catch (error) {
  //     console.log(error)
  //     setIsLoading(false)
  //   }
  // }

  // const onClickDown = async (index, array) => {
  //   try {
  //     setIsLoading(true)
  //     const current = { id: array[index].course.id, order: array[index + 1].course.order }
  //     const next = { id: array[index + 1].course.id, order: array[index].course.order }
  //     await setProductOrder(current)
  //     await setProductOrder(next)
  //     setIsLoading(false)
  //   }
  //   catch (error) {
  //     console.log(error)
  //     setIsLoading(false)
  //   }
  // }

  const updateOrder = (slide1, slide2) => {
    console.log("eeeeee",slide1.course.order ,slide2.course.order)
    setProductOrder({ id: slide1.course.id, order: slide2.course.order  })
        .then((res1) => {
            if (!res1.error) {
                setProductOrder({ id: slide2.course.id,  order: slide1.course.order  })
                    .then((res2) => {
                        if (!res2.error) courseRes.refetch();
                    });
            }
        });
};


  
  useEffect(()=>{

    if(courseRes.isSuccess && id){
      setSelectValue(id)
      console.log(id);
    }

  },[courseRes.isSuccess])

  useEffect(() => {
    if (setProductOrderResponse.isSuccess) {
      courseRes.refetch()
    }
  }, [setProductOrderResponse.isSuccess])

  if (isLoading || response.isLoading || courseRes.isLoading || courseRes.isFetching  || deleteProductResponse.isLoading) return <Loading />
  if (response.isError) return <ApiErrorModal response={response} />

  const courses = courseRes.data
  const orderedCourses = courses?.slice().sort((a, b) => parseInt(b.course.order) - parseInt(a.course.order))
  const orderedCoursesFiltered =orderedCourses
  .filter((item) => {
    if (selectValue.length > 0)
      return item.category.id === selectValue
    else return true
  })

  return (
    <div className='p-5'>

     
      
      {deleteProductResponse.isSuccess && <SuccessModal message={"Product Deleted Successfully."} />}     
      {deleteProductResponse.isError && <ApiErrorModal response={deleteProductResponse} />}

     
   

      <div className='d-flex justify-content-between mb-3'>
        <h4 className='text-primary fw-bold'>{'Product > Courses'}</h4>

        <div className="btn btn-primary btn-sm text-white px-5 rounded-pill" onClick={() => navigate(`/create/product`)} >Create New Product</div>
      </div>

      <SelectInput
        name={"Select Category"}
        options={[
          ...[{
            value: '',
            label: 'Select',
          }],
          ...response?.data?.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        ]}

        value={selectValue} onChange={(e) => setSelectValue(e.target.value)}
        containerClass={'w-25 mb-5'}
        required
      />

      <div className="row row-cols-1 g-4">



        {
          orderedCoursesFiltered
            .map((item, index) =>
              <div className="">

                <div className="card container p-3 rounded-4 shadow">
                  <div className="row text-center">

                    <div className="col-2">
                      <p className='fs-13'>Category</p>
                      <p className='bg-primary px-2 rounded-pill text-white fs-13'>{item?.category?.name}</p>
                    </div>

                    <div className="col-2">
                      <p className='fs-13'>Product Name</p>
                      <p className='text-primary fs-13 fw-bold'>{item?.course?.name}</p>
                    </div>

                    <div className="col-2">
                      <p className='fs-13'>Amount</p>
                      <p className='fw-bold'>₹{item?.course?.amount}</p>
                    </div>

                    <div className="col-2">
                      <div className='rounded-circle bg-danger d-flex justify-content-center align-items-center' onClick={() => handleProductDelete(item?.course?.id)} style={{ height: 40, width: 40 }}>{Icons.delete()}</div>
                    </div>

                    <div className="col-2">
                      <div className='rounded-circle bg-success d-flex justify-content-center align-items-center' onClick={() => navigate(`/edit/product/${item.course.id}`)} style={{ height: 40, width: 40 }}>{Icons.edit()}</div>
                    </div>

                    {
                      index === 0 ?
                        <div className="col-1">{Icons.upArrow("opacity-0")}</div>
                        :
                        <div className="col-1"
                        //  onClick={() => onClickUp(index, orderedCourses)}
                        
                        onClick={() => updateOrder(orderedCoursesFiltered[index], orderedCoursesFiltered[index - 1])}
                        // onClick={() => updateOrder(images[index], images[index - 1])}
                         
                         >{Icons.upArrow()}</div>
                    }

                    {
                      index === orderedCoursesFiltered.length - 1 ?
                        <div className="col-1">{Icons.downArrow("opacity-0")}</div>
                        :
                        <div className="col-1" 
                        // onClick={() => onClickDown(index, orderedCoursesFiltered)}
                        onClick={() => updateOrder(orderedCoursesFiltered[index], orderedCoursesFiltered[index + 1])}
                          >{Icons.downArrow()}</div>
                    }




                  </div>
                </div>

              </div>
            )
        }

      </div>

    </div>
  )
}

export default ProductCourses