import React, { useRef } from 'react';
import Icons from '../../components/ui/Icons';
import Api from '../../constants/Api';
import ReactToPrint from 'react-to-print';
import logo from '../../assets/images/logo_sudikshya.png'
import { convertToFiveDigits } from '../../utils/ConvertToFive';
import { useGetOfferQuery, useGetOffersQuery } from '../../services/offerSlice';

const OfferLetterModal = ({ item }) => {
    console.log("-----------item", item);
    const modalId = "offerLetterDownloadModal" + item?.associate?.id;
    const modalBodyRef = useRef();

    // const getTerms = useGetOffersQuery()

    // const getTerm = getTerms?.data?.find((data) => data.id == item.designation.id)


    const ModalBody = () => (
        <div className="modal-body p-0" id={modalId + "ModalBody"} ref={modalBodyRef}>
            {/* Your existing modal body content */}
            <div className='w-100 bg-primary mb-3' style={{ height: 10 }}>
                <div className="bg-warning h-100 w-50 mx-auto"></div>
            </div>

            {/* <div className="d-flex justify-content-between mb-4 px-3">
                <div>Ref. ID: {item?.sponsor?.id}</div>
                <div>Date of Joiningf: {item.associate.createdAt}</div>
            </div> */}



            <div className="d-flex px-3 mb-5">
                <div className=''>
                    {/* Create offer letter template here */}

                    {/* <div className='float-start'>
                    <img src={logo} alt="" className='w-25 ' />
                      <p>  Ref No -</p>

                    </div>
                    
                    <div className='float-end'>
                    <img src={logo} alt="" className='w-25 ' />
                      <p>  Ref No -</p>
                    </div> */}

                    <div className='d-flex  justify-content-between'>

                        <div>
                            <img src={logo} alt="" className='w-25 ' />
                            <p className='mt-4'> Ref Id - {item?.associate?.serialNo &&
                                ` SUP${convertToFiveDigits(item?.associate?.serialNo)}`}</p>
                        </div>

                        <div className='w-50 '   >
                            <div className='text-end text-primary'>
                                <p>SUDIKSHYA  LEARNING PVT LTD</p>
                                <span className="me-1">CIN</span>: <span className="fw-bold">U80904OR2022PTC038955</span>
                            </div>
                            <p className='text-end mt-2'> Issue Date-  {item?.associate?.createdAt && !isNaN(new Date(item?.associate?.createdAt)) ? new Date(item?.associate?.createdAt).toLocaleDateString('en-GB').split('/').join('-') : ''} </p>


                        </div>

                    </div>

                    <h4 className="mb-4 mt-4 text-center">Associate Offer Letter</h4>

                    <p>To</p>
                    <p>{item?.associate?.name},</p>
                    <p>C/o: {item?.associate?.fatherName},</p>
                    <p>Associate ID:S3A000{item?.associate?.serialNo},</p>

                    {/* <div className='mt-2' dangerouslySetInnerHTML={{ __html: item.designation.offerLetter }} /> */}

                    <div className='mt-2' dangerouslySetInnerHTML={{ __html: item.designation.offerLetter?.replace('[ASSOCIATE_NAME]', item.associate.name)?.replace('[ISSUE_DATE]', item.associate.createdAt)?.replace('[DESIGNATION]', item.designation.name)?.replace('[ASSOCIATE_ID]', item.associate.id) }} />


                </div>
            </div>



        </div>
    );





    return (
        <>

            <div className='bg-primary rounded text-center px-2 py-1 text-nowrap'>
                <span className='' data-bs-toggle="modal" data-bs-target={"#" + modalId}>{Icons.view()}</span>
            </div>

            <div className="modal fade  " id={modalId} tabIndex="-1" aria-labelledby={modalId + "Label"} aria-hidden="true" >
                <div className="modal-dialog  modal-lg">
                    <div className="modal-content " >
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id={modalId + "Label"}>{item.associate.name}'s Id Card</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <ModalBody />
                        <div className="modal-footer">
                            {/* <ReactToPrint
                                trigger={() => (
                                    <div className='bg-primary  rounded text-center px-2 py-1 text-nowrap'>
                                        <span className="text-white me-1">Download</span> <span>{Icons.download('')}</span>
                                    </div>
                                )}
                                content={() => modalBodyRef.current}
                            /> */}

                            <ReactToPrint
                                trigger={() => (
                                    <div className='bg-primary  rounded text-center px-2 py-1 text-nowrap'>
                                        <span className="text-white me-1">Download</span> {Icons.download('')}
                                    </div>
                                )}
                                content={() => modalBodyRef.current}
                                pageStyle={`@page { size: A4; margin: 10; }`}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OfferLetterModal;
