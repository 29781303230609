import React from 'react'
import { NavLink } from 'react-router-dom'
import Icons from '../ui/Icons';
import logo from '../../assets/images/logo_sudikshya.png'
import SignOut from './SignOut';
import { useGetSiteInfoQuery } from '../../services/adminSlice';
import Api from '../../constants/Api';

export const sideBarLinks = [
    { to: "/", text: "Dashboard", icon: Icons.dashboard },
    { to: "/order", text: "Order Management", icon: Icons.order },
    { to: "/team", text: "Team Management", icon: Icons.team },
    { to: "/student", text: "Student Management", icon: Icons.student },
    { to: "/designations", text: "Designations", icon: Icons.student },
    { to: "/product", text: "Product", icon: Icons.product, items: [{ text: "Associates", to: "/product/staffs" }, { text: "Courses", to: "/product/courses" }, { text: "Category", to: "/product/category" }] },
    { to: "/document", text: "Document Management", icon: Icons.document },
    { to: "/lead", text: "Leads", icon: Icons.leads },
    { to: "/report", text: "Report", icon: Icons.report },
    { to: "/settings", text: "Settings", icon: Icons.settings },
    // { to: "/selectGateway", text: "Gateway", icon: Icons.settings },

];

const Sidebar = () => {

    const getinfo = useGetSiteInfoQuery(1)

    const navWrapItem = (link) => {
        return (
            <li className="nav-item py-1">
               {
                console.log("dfdsdfafd",link.items.length)
               }
                <button className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed nav-link" data-bs-toggle="collapse" data-bs-target="#home-collapse" aria-expanded="true">
                    {link.icon("me-2", { width: 20, height: 20 })}
                    {link.text}
                </button>
                <div className="collapse show" id="home-collapse" bis_skin_checked="1">
                    <ul className="list-unstyled bg-light rounded-3 p-3">
                        {
                            link?.items?.map((item) => <li><NavLink to={item.to} className="link-dark d-inline-flex text-decoration-none rounded">{item.text}</NavLink></li>)
                        }
                    </ul>
                </div>
            </li>
        )
    }

    const navItem = (link) => {
        return (
            <li key={link.text} className="nav-item py-1">
                <NavLink to={link.to} className="nav-link d-flex align-items-center">
                    {link.icon("me-2", { width: 20, height: 20 })}
                    {link.text}
                </NavLink>
            </li>
        )
    }

    const renderLinks = (link) => link.items ? navWrapItem(link) : navItem(link)

    return (
        <div className="d-none d-lg-flex flex-column flex-shrink-0 bg-white h-100 shadow" style={{ width: 280, zIndex: 1 }}>
            <div className='px-3 py-5'>
                <img src={ Api.BASE_URL+ getinfo?.data?.logo} alt="" className='w-100' />
              
            </div>
            <div className='overflow-auto py-3'>
                <ul className="nav nav-pills flex-column mb-auto px-3">
                    {sideBarLinks.map(renderLinks)}
                    <SignOut />
                </ul>
            </div>
        </div>
    )
}

export default Sidebar