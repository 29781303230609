import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useGetStudentQuery } from '../../services/studentSlice';
import Loading from '../../components/ui/Loading';
import StudentInvoiceModal from './StudentInvoiceModal';
import { convertToFiveDigits } from '../../utils/ConvertToFive';

const Student = () => {
    const { id } = useParams();

    const response = useGetStudentQuery(id)

    if (response.isLoading) return <Loading />

    return (
        <div className='p-5'>
            {
                console.log("=re", response.data)
            }
            <h4 className='text-primary fw-bold mb-3'>Student Details</h4>
            <div className='d-flex flex-column'>

                <div className='card p-3 rounded-4 border-primary me-5 flex-grow-1'>

                    <h5>Personal Details</h5>

                    <div className='row mb-3'>
                        <div className='col'>
                            <div className='text-muted'>Student Name:</div>
                            <div className='fw-bold text-primary'>{response?.data?.student?.studentName}</div>
                        </div>
                        <div className='col'>
                            <div className='text-muted'>Mobile Number:</div>
                            <div className='fw-bold text-primary'>{response?.data?.student?.mobileNumber}</div>
                        </div>
                        <div className='col'>
                            <div className='text-muted'>Course:</div>
                            <div className='fw-bold text-primary'>{response?.data?.course?.name}</div>
                        </div>
                    </div>

                    <div className='row mb-3'>

                    </div>

                    <div className='row mb-3'>

                    </div>

                    <h5 className='mt-5'>Rank & Sponsor</h5>

                    <div className='row mb-3'>
                        {/* <div className='col'>
                            <div className='text-muted'>Rank:</div>
                            <div className='fw-bold text-primary'>Commercial Manager</div>
                        </div> */}
                        <div className='col'>
                            <div className='text-muted'>Sponsor’s Associate ID:</div>
                            {/* <div className='fw-bold text-primary'>{response?.data?.sponsor?.id}</div> */}
                            <div className='fw-bold text-primary'>{response?.data?.sponsor?.id && `S3A${convertToFiveDigits(response?.data?.sponsor?.id)}`}</div>
                        </div>
                        <div className='col'>
                            <div className='text-muted'>Registration Date:</div>
                            <div className='fw-bold text-primary'>

                                {new Date(response?.data?.student?.registrationDate).toLocaleDateString('en-GB', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                }).replace(/\//g, '-')

                                }



                            </div>
                        </div>

                        <div className='col'>
                            <div className='text-muted'>Sponsor Name:</div>
                            <div className='fw-bold text-primary'>{response?.data?.sponsor?.name}</div>
                        </div>
                    </div>

                    <div className='row mb-3'>
                        <div className='col'>
                            <div className='text-muted'>App Student ID:</div>
                            <div className='fw-bold text-primary'>{response?.data?.student?.appStudentId}</div>
                        </div>
                        <div className='col'>
                            <div className='text-muted'>Ref Id:</div>
                            <div className='fw-bold text-primary'>{response?.data?.student?.refId}</div>
                        </div>
                        <div className='col'>
                            <div className='text-muted'>Payment Mode:</div>
                            <div className='fw-bold text-primary'>

                                {response?.data?.student?.modeOfPayment &&
                                    (response?.data?.payment[0]?.paymentType !== 'Manual' ?
                                        `App Payment (${response?.data?.payment[0]?.paymentType})` :
                                        `Manual (${response?.data?.student?.modeOfPayment})`
                                    )
                                }
                            </div>
                        </div>

                    </div>

                    <StudentInvoiceModal item={response?.data} />


                </div>




            </div>

        </div >
    )
}

export default Student