import React from 'react'

const InputSearch = ({ name, placeholder, type, containerClass, inputClass, ...attributes }) => {


    const containerClassName = "d-flex flex-column align-items-start col " + (containerClass ? containerClass : "");
    const inputClassName = "form-control rounded-pill px-3 border-primary border-2 " + (inputClass ? inputClass : "");
    const inputType = type ? type : "text";
  

    return (
        <div className={containerClassName}>
            <input
                type={inputType}
                className={inputClassName}
                id={name}
                name={name}
                placeholder={placeholder ? placeholder : name}
                {...attributes}
            />
        </div>
    )
}

export default InputSearch