import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Input from '../../components/form/Input'
import Icons from '../../components/ui/Icons'
import SelectInput from '../../components/form/SelectInput'
import { useGetProductsQuery } from '../../services/productSlice'
import Loading from '../../components/ui/Loading'
import { useGetStudentsQuery } from '../../services/studentSlice'
import { useGetAssociatesQuery } from '../../services/associateSlice'
import ReactToPrint from 'react-to-print';
import { useParams } from 'react-router-dom';
import SearchSelectInput from '../../components/form/SearchSelectInput'



const UserStudentReport = () => {

  const [selectValue, setSelectValue] = useState("")
  const [fdate, setFdate] = useState(null)
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);


  const { id } = useParams();

  let count = 1;
  const modalBodyRef = useRef();
  const response = useGetAssociatesQuery()




  useEffect(() => {

    if (response.isSuccess && id) {
      setSelectValue(id)
      console.log(id);
    }

  }, [response.isSuccess])

  if (response.isLoading) return <Loading />



  return (
    <div className='p-5'>
      {console.log("courseRes", response.data)}

      <h4 className='text-primary fw-bold mb-3'>Reports</h4>

      <div className='d-flex mb-3 flex-wrap align-items-center justify-content-between mb-5'>

        <div className='d-flex'>

          <SearchSelectInput
            name={"Select Associate"}
            options={[
              ...[{
                value: '',
                label: 'Select',
              }],
              ...response?.data?.map((item) => ({
                value: item?.associate?.id,
                label: item?.associate?.name,
                serialNo: item?.associate?.serialNo,
              }))
            ]}


            value={selectValue} defaultValue={selectValue} onChange={(val) => setSelectValue(val)}
            containerClass={''}
          />

          <Input
            type={'date'}
            name={'Start Date'}
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            containerClass={'w-75 ms-3'}
          />

          <Input
            type={'date'}
            name={'End Date'}
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            containerClass={'w-75 ms-3'}
          />

        </div>


        <div className='d-flex align-items-centers'>


          <ReactToPrint
            trigger={() => (
              <div className='bg-primary  rounded text-center px-2 py-1 text-nowrap'>
                <span className="text-white me-1">Download</span> {Icons.download('')}
              </div>
            )}
            content={() => modalBodyRef.current}
            pageStyle={`@page { size: A5; margin: 0; }`}
          />

        </div>

      </div>

      {/* <div className='d-flex justify-content-between mb-3'>

        <div className='bg-primary rounded-3 p-3 me-3'>{Icons.report()}</div>
        

        <div className='flex-grow-1'></div>

        <Input name={"Search Invoice"} containerClass={"ms-3"} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
      </div> */}

      <div className='card rounded-4 overflow-hidden shadow '>

        <div className="table-responsive">
          <table className="table table-borderless table-hover" ref={modalBodyRef}>
            <thead>
              <tr className='border-bottom'>
                <th scope="col" className='text-muted'>Sl.No</th>
                <th scope="col" className='text-muted'>Des</th>
                <th scope="col" className='text-muted'>SI</th>
                <th scope="col" className='text-muted'>Id</th>
                <th scope="col" className='text-muted'>Name</th>
                <th scope="col" className='text-muted'>Mobile No</th>
                <th scope="col" className='text-muted'>Joining</th>
              </tr>
            </thead>
            <tbody>
              {response.data
                ?.filter((item) => {
                  if (selectValue.length === 0) return true
                  const isProductMatch = selectValue.length > 0 && item?.associate?.serialNo === selectValue;
                  return (isProductMatch);
                })

                ?.map((item, i) =>
                  <>
                    <tr className='table-warning' key={i}>
                      <th scope="row" >{count++}</th>
                      <th>{item?.designation?.name}</th>
                      <th scope="row" >si</th>
                      <th scope="row" >AS{item?.associate?.serialNo}</th>
                      <th>{item?.associate?.name}</th>
                      <th>{item?.associate?.mobileNumber}</th>
                      <th className=''>{item?.associate?.createdAt && !isNaN(new Date(item?.associate?.createdAt)) ? new Date(item?.associate?.createdAt).toLocaleDateString('en-GB').split('/').join('-') : ''}</th>
                    </tr>

                    {
                      item.allStudent

                        .filter((item) => {
                          if (startDate === null || endDate === null || startDate === '' || endDate === '') return true;
                          const isDateMatch = item?.createdAt >= startDate && item?.createdAt <= endDate;
                          return isDateMatch;
                        })
                        .map((subItem, index) =>
                          <>
                            <tr key={index} className='bg-danger'>
                              <th scope="row" >{count++}</th>
                              <th>Student</th>
                              <th scope="row" >si</th>
                              <th scope="row" >ST{subItem?.id}</th>
                              <th>{subItem?.studentName}</th>
                              <th>{subItem?.mobileNumber}</th>
                              <th className=''>{subItem?.createdAt && !isNaN(new Date(subItem?.createdAt)) ? new Date(subItem?.createdAt).toLocaleDateString('en-GB').split('/').join('-') : ''}</th>
                            </tr>

                          </>)
                    }



                  </>

                )}


            </tbody>
          </table>
        </div>

      </div>

    </div>
  )
}

export default UserStudentReport