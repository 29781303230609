import React from 'react'
import Input from '../../components/form/Input'
import SelectInput from '../../components/form/SelectInput'
import { useGetAssociatesQuery } from '../../services/associateSlice'
import { usePostProductMutation } from '../../services/productSlice'
import { usePostLeadMutation } from '../../services/leadSlice'
import SuccessModal from '../../components/modals/SuccessModal'
import ApiErrorModal from '../../components/modals/ApiErrorModal'

const CreateLead = () => {

    const [postLead, postLeadResponse] = usePostLeadMutation()

    const sponserData = useGetAssociatesQuery()

    const handleSubmit = (e) => {

        e.preventDefault();
        const form = e.target;

        if (form.checkValidity()) {
            const body = {
                name: e.target['Name'].value,
                associateId: e.target["Associate Id"].value,
                address: e.target["Address"].value,
                mobileNumber: e.target["Mobile Number"].value
            }



            postLead(body)
                .then(() => {
                    form.classList.remove('was-validated')
                    form.reset()
                })
        }
        else {
            form.classList.add('was-validated')
        }

    }


    return (
        <div className='container p-5'>


            {postLeadResponse.isSuccess && <SuccessModal message={"Lead has been created."} />}
            {postLeadResponse.isError && <ApiErrorModal response={postLeadResponse} />}

            <h4 className='fw-bold mb-3'>Create Lead</h4>

            <h5 className='text-muted mb-3'> Details</h5>
            <form onSubmit={handleSubmit} noValidate>
                <div className='row row-cols-3 g-3'>
                    <Input containerClass="" name={"Name"} required />
                    <SelectInput
                        name={"Associate Id"}
                        options={
                            sponserData?.data?.map((sponsor) => ({
                                value: sponsor?.associate?.id,
                                label: sponsor?.associate?.name,
                            })) || []
                        }
                    />

                    <Input containerClass="" name={"Address"} required />
                    <Input containerClass="" name={"Mobile Number"} required />

                </div>
                <input type='submit' value='Submit' className='btn btn-primary mt-5 float-end' required />
            </form>

        </div>
    )
}

export default CreateLead