import React, { useState } from 'react'
import Icons from '../../components/ui/Icons'
import Input from '../../components/form/Input'
import { useDeleteCategoryMutation, useGetCategoriesQuery, usePostCategoryMutation, useUpdateCategoryMutation } from '../../services/categorySlice'
import { useNavigate } from 'react-router-dom'
import Loading from '../../components/ui/Loading'
import ApiErrorModal from '../../components/modals/ApiErrorModal'
import SuccessModal from '../../components/modals/SuccessModal'
import { useDeleteProductMutation, useGetProductsQuery } from '../../services/productSlice'
import EditProductCategoryModal from '../../components/modals/EditProductCategoryModal'

const ProductCategory = () => {

  const response = useGetCategoriesQuery()
  const courseRes = useGetProductsQuery()
  const [isLoading, setIsLoading] = useState(false)

  const [postCategory, postCategoryResponse] = usePostCategoryMutation()
  // const [setProductOrder, setProductOrderResponse] = useSetProductOrderMutation()
  const [deleteCategory, deleteCategoryResponse] = useDeleteCategoryMutation()
  const [updateCategory, updateCategoryResponse] = useUpdateCategoryMutation();
  const [deleteProduct, deleteProductResponse] = useDeleteProductMutation()


  const [editCategoryId, setEditCategoryId] = useState(null);
  const [editCategoryName, setEditCategoryName] = useState('');

  const [pid, setPid] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.target;

    if (form.checkValidity()) {
      const body = {
        name: form['Category Name'].value,
      };

      if (editCategoryId) {
        // Perform edit operation
        const id = editCategoryId;
        updateCategory({ id, body })
          .then(() => response.refetch())
          .then(() => {
            // Reset the edit state
            setEditCategoryId(null);
            setEditCategoryName('');
            form.reset();
          });
      } else {
        // Perform create operation
        postCategory(body)
          .then(() => response.refetch())
          .then(() => {
            form.classList.remove('was-validated');
            form.reset();
          });
      }
    } else {
      form.classList.add('was-validated');
    }
  };

  const handleDelete = (id) => {
    const shouldDelete = window.confirm('Are you sure you want to delete this category?');
    setPid(parseInt(id));
    if (shouldDelete) {
      deleteCategory(id)
        .then(() => response.refetch())
    }
  };

  if (isLoading || response.isLoading || courseRes.isLoading || courseRes.isFetching || deleteCategoryResponse.isLoading || updateCategoryResponse.isLoading || deleteProductResponse.isLoading) return <Loading />
  if (response.isError) return <ApiErrorModal response={response} />


  return (
    <div className='p-5'>

      {postCategoryResponse.isSuccess && <SuccessModal message={"Successfully Added."} />}
      {deleteCategoryResponse.isSuccess && <SuccessModal message={"Category Deleted Successfully."} />}
      {deleteProductResponse.isSuccess && <SuccessModal message={"Product Deleted Successfully."} />}

      {postCategoryResponse.isError && <ApiErrorModal response={postCategoryResponse} />}
      {deleteCategoryResponse.isError && <ApiErrorModal response={deleteCategoryResponse} pid={pid} />}
      {updateCategoryResponse.isError && <ApiErrorModal response={updateCategoryResponse} />}
      {deleteProductResponse.isError && <ApiErrorModal response={deleteProductResponse} />}

      <h4 className='text-primary fw-bold mb-3'>Categories</h4>

      <div className='row mb-5 '>

        <div className='col-12 col-md-7  '>
          <div className="card rounded-4 overflow-hidden shadow">
            <table className="table text-start table-hover">
              <thead>
                <tr className='border-bottom'>
                  <th scope="col" className='text-muted'>Category ID</th>
                  <th scope="col" className='text-muted'>Category Name</th>
                  <th scope="col" className='text-muted'>Action</th>
                </tr>
              </thead>
              <tbody>

                {
                  response.data?.map((item,i) => <tr key={i}>
                    <th scope="row" className='text-muted text-start'>{item.id}</th>
                    <td className=''>{item.name}</td>
                    <td className='d-flex'>
                      <div className='rounded-4 bg-danger d-flex justify-content-center align-items-center mx-2'
                        onClick={() => handleDelete(item?.id)} style={{ height: 40, width: 40 }}>
                        {Icons.delete()}
                      </div>
                      <EditProductCategoryModal id={item?.id} refetch={response.refetch} />
                    </td>
                  </tr>)
                }

              </tbody>
            </table>
          </div>
        </div>



        <div className='col-12 col-md-5'>
          <form onSubmit={handleSubmit} noValidate>
            <div className='card rounded-5 overflow-hidden p-3 py-5 shadow align-items-center'>
              <h4 className='text-primary fw-bold mb-3'>{editCategoryId ? 'Edit Category' : 'Create Category'}</h4>
              <Input name={'Category Name'} containerClass={'mb-3'} required value={editCategoryName} onChange={(e) => setEditCategoryName(e.target.value)} />
              {postCategoryResponse.isLoading ? (
                <button className='btn btn-primary text-white px-5 btn-lg rounded-pill' type='button' disabled>
                  <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span> Creating...
                </button>
              ) : (
                <button type='submit' className='btn btn-primary text-white px-5 btn-lg rounded-pill'>
                  {editCategoryId ? 'Update' : 'Create'}
                </button>
              )}
            </div>
          </form>
        </div>

      </div>

    </div>
  )
}

export default ProductCategory