import React from 'react'
import SectionHeader from './SectionHeader'
import SectionBody from './SectionBody'

const Section = ({ children, className, title }) => {
    return (
        <>
            <SectionHeader title={title} />
            <SectionBody className={className}>{children}</SectionBody>
        </>
    )
}

export default Section