import React, { useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

const LineChart = ({ labels,datasets }) => {



    useEffect(() => {

        const data = {
            labels,
            datasets
        };

        const config = {
            type: 'line',
            data: data,
        };

        const lineChart = new Chart(document.getElementById('chart'), config);
        return () => { lineChart.destroy() }
    }, [datasets])

    return (
        <canvas id='chart' style={{width:500 ,height:100}}>

        </canvas >
    );
};

export default LineChart;
