import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";


export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getOffers: builder.query({ query: () => `/offers`, keepUnusedDataFor: 0 }),
        getOffer: builder.query({ query: (id) => `/offers?id=${id}`, keepUnusedDataFor: 0 }),
        postOffer: builder.mutation({ query: (body) => ({ url: `/offers/create`, method: 'POST', body }) }),
        updateOffer: builder.mutation({ query: ({ id, body }) => ({ url: `offers/update?id=${id}`, method: 'POST', body }) }),
        deleteOffer: builder.mutation({ query: (id) => ({ url: `/offers/delete?id=${id}`, method: 'DELETE', }) }),




    }),
})

export const { useGetOffersQuery,usePostOfferMutation,useUpdateOfferMutation ,useDeleteOfferMutation,useGetOfferQuery} = extendedApiSlice
