import React, { useEffect, useState } from 'react';
import { useGetGatewayQuery, usePostGatewayMutation, useUpdateGatewayMutation } from '../services/selectedGatewaySlice';
import Loading from '../components/ui/Loading';
import Section from '../components/form/Section';
import Input from '../components/form/Input';
import SelectInput from '../components/form/SelectInput';

const SelectedGateway = () => {
    const { data: gatewayData, isLoading ,isSuccess } = useGetGatewayQuery();
    const [postGateway, { isLoading: isCreating }] = usePostGatewayMutation();
    const [updateGateway, { isLoading: isUpdating }] = useUpdateGatewayMutation();
    const [gatewayName, setGatewayName] = useState('PhonePe');

    const btnClassName = "btn btn-primary rounded-pill px-5 text-white my-3";

    const handleSubmit = (e) => {
        e.preventDefault();
        if (gatewayData?.length === 0) {
            postGateway({ gateway: gatewayName });
        } else {
            const body={ gateway: gatewayName };
            updateGateway({id:1,body});
        }
    }

    useEffect(() => {
        // Set default value for gatewayName if data is available
        if (gatewayData && gatewayData.length > 0) {
            setGatewayName(gatewayData[0].gateway);
        }
    }, [gatewayData,isSuccess]);


    if (isLoading ) return <Loading />;

    return (
        <div className='container p-3 p-lg-5'>
            <h4 className='fw-bold mb-3'>Create Gateway</h4>
            <form onSubmit={handleSubmit}>
                <Section title={' '} className='row-cols-1 row-cols-lg-3 g-3 mb-3'>
                    {/* <Input containerClass="" name={"Gateway Name"} value={gatewayName} onChange={(e) => setGatewayName(e.target.value)} required /> */}
                    <SelectInput
                        name={"Gateway Name"}
                        options={
                             [
                                {value:'PhonePe',label:'PhonePe'},
                                {value:'ccAvenue',label:'ccAvenue'},
                            ]
                        }
                        defaultValue={gatewayName}
                        value={gatewayName} onChange={(e) => setGatewayName(e.target.value)}
                        required
                    />
                </Section>
                {isCreating || isUpdating ? (
                    <button className={btnClassName} type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> {isCreating ? 'Creating...' : 'Updating...'}
                    </button>
                ) : (
                    <button type='submit' className={btnClassName}>{gatewayData?.length === 0 ? 'Create' : 'Update'}</button>
                )}
            </form>
        </div>
    );
};

export default SelectedGateway;
