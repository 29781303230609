

import React, { useEffect, useState } from 'react'
import logo from '../assets/images/logo_sudikshya.png'
import childImg from '../assets/images/child.png'
import Input from '../components/form/Input'
import { useSignInMutation } from '../services/authSlice'
import { useDispatch } from 'react-redux'
import { setAuthToken } from '../redux/authSlice'

const SignIn = () => {
  const dispatch = useDispatch();

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [signIn, signInResponse] = useSignInMutation()

  const signedIn = () => {
    signIn({ username: username, password: password })
      .then((res) => {
        if (res.error && res.error.status === 404) {
          alert(res.error.data.error);
        } else if (res.error && res.error.status === 401) {
          alert(res.error.data.error);
        }
        if (!res.error) {
          dispatch(setAuthToken(res.data.token))
      }
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('Some error occurred while signing in.'); // Provide a user-friendly message
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      signedIn();
    }
  };

  useEffect(() => {
    const classList = document.body.classList
    if (classList.contains("modal-open")) classList.remove("modal-open")

    const backdrops = document.getElementsByClassName("modal-backdrop")
    for (const backdrop of backdrops) backdrop.remove()
  }, [])

  return (
    <div className='d-flex h-100'>
      <style>
        {`

        @media (max-width: 767px) {
          .login-form-container {
            width: 100%!important; /* Make the login form take full width on small screens */
          }
          .right-image-container {
            display: none!important; /* Hide the right image container on small screens */
          }
          .input-holder {
            width: 100%!important; /* Make the login form take full width on small screens */
            padding-right:10px;
            padding-left:10px;
          }
        }
        
        @media (min-width: 992px) {
          .login-form-container,
          .right-image-container {
            width: 50%; /* Set the width to 50% for larger screens */
          }
          .input-holder {
            width: 50%!important; /* Make the login form take full width on small screens */
          }
          .logo-holder{
            width:50%;
          }
        }
        
        }
        `}
      </style>

      <div className='bg-light w-50 d-flex flex-column align-items-center justify-content-center login-form-container'>


        <div className='mb-5  px-5 logo-holder'>
          <img src={logo} alt="" className='w-100' />
        </div>


        {/* make the below with to take full width in mobile screen */}
        <div className='input-holder'>
          <Input name={"User Id"} inputClass={"border-0 primary-placeholder fw-bold px-4 form-control-lg"} containerClass={"mb-3"} value={username} onChange={(e) => setUsername(e.target.value)} onKeyPress={handleKeyPress} />
          <Input name={"Password"} type={"password"} inputClass={"border-0 primary-placeholder fw-bold px-4 form-control-lg"} containerClass={"mb-3"} value={password} onChange={(e) => setPassword(e.target.value)} onKeyPress={handleKeyPress} />

          <div className='d-flex justify-content-between mb-4'>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
              <label className="form-check-label text-primary" htmlFor="flexCheckDefault">
                Remember Me
              </label>
            </div>
            <span className='fw-bold'>Forgot Password?</span>
          </div>

          <div className="d-flex align-items-start">

            {
              signInResponse.isLoading ?
                <button class="btn btn-primary rounded-pill btn-lg px-5" type="button" disabled>
                  <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                  <span role="status"> Proceeding...</span>
                </button>
                :
                <button className="btn btn-primary rounded-pill btn-lg px-5" onClick={signedIn} >Login</button>

            }
          </div>

        </div>

      </div>
      {/* //dont show deblow div when screen is mobiile screen */}
      <div className='bg-primary w-50 d-flex align-items-center justify-content-center right-image-container'>

        <img src={childImg} alt="" className='w-75' />
      </div>

    </div>
  )
}

export default SignIn
