import React, { useEffect, useState } from 'react'
import { useGetAssociatesQuery, useGetPendingAssociateQuery } from '../services/associateSlice'
import { useNavigate } from 'react-router-dom';
import ApiErrorModal from '../components/modals/ApiErrorModal';
import Loading from '../components/ui/Loading';
import ApprovalRequests from '../components/pages/ApprovalRequests';
import { useGetStudentsQuery } from '../services/studentSlice';
import LineChart from '../components/LineChart';
import { useGetPackageQuery } from '../services/adminSlice';
import SelectInput from '../components/form/SelectInput';
const Dashboard = () => {

  const navigate = useNavigate();

  const [selectValue, setSelectValue] = useState('Select');



  const response = useGetPendingAssociateQuery()
  const assoRes = useGetAssociatesQuery()

  const packageData = useGetPackageQuery(1)

  const studentData = useGetStudentsQuery()

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);


  const [items, setItems] = useState([]);
  const [dataSets, setDataSets] = useState([]);
  const [labelSets, setLabelSets] = useState([]);


  const calculateSalesReceipt = (associateResponse, studentResponse, startDate, endDate) => {


    let totalSales = 0;
    let totalPaid = 0;
    let totalPayments = 0;
    let totalInvoice = 0;

    associateResponse?.forEach((item) => {
      const payment = item?.payment
      const associateCreatedAt = new Date(item?.associate.createdAt)
      if (associateCreatedAt >= startDate && associateCreatedAt <= endDate) {
        totalSales += parseFloat(packageData?.data?.packagePrice);
        totalInvoice += 1;
        if (payment) {
          const paymentCreatedAt = new Date(payment?.createdAt)
          if (paymentCreatedAt >= startDate && paymentCreatedAt <= endDate) {
            if (payment.status === 'Success') totalPaid += parseFloat(payment.amount)
          }
        }
      }
      if (payment) {
        const paymentCreatedAt = new Date(payment?.createdAt)
        if (paymentCreatedAt >= startDate && paymentCreatedAt <= endDate) {
          if (payment.status === 'Success') totalPayments += parseFloat(payment.amount)
        }
      }
    });


    studentResponse?.forEach((item) => {
      const studentCreatedAt = new Date(item?.student.createdAt)
      if (studentCreatedAt >= startDate && studentCreatedAt <= endDate) {
        totalSales += parseFloat(item.course?.amount);
        totalInvoice += 1;
        item?.payment?.forEach((pay) => {
          let paymentCreatedAt = new Date(pay?.createdAt)
          if (paymentCreatedAt >= startDate && paymentCreatedAt <= endDate) {
            if (pay.status === 'Success') totalPaid += parseFloat(pay.amount)
          }
        });
      }
      item?.payment?.forEach((pay) => {
        let paymentCreatedAt = new Date(pay?.createdAt)
        if (paymentCreatedAt >= startDate && paymentCreatedAt <= endDate) {
          if (pay.status === 'Success') totalPayments += parseFloat(pay.amount)
        }
      });
    });


    const dueAmount = totalSales - totalPaid;
    console.log("totoalinvoice", totalInvoice)
    return {
      totalSales,
      totalPayments,
      dueAmount,
      totalInvoice,
    };
  };

  const getDataSets = (startDate, endDate) => {

    const SECOND = 1000
    const MINUTE = 60 * SECOND
    const HOUR = 60 * MINUTE
    const DAY = 24 * HOUR
    const WEEK = 7 * DAY
    const MONTH = 30 * DAY
    const YEAR = 365 * DAY

    let duration = YEAR

    if (startDate && endDate) {
      duration = endDate - startDate
    }


    const associateApiResponse = assoRes?.data;
    const studentApiResponse = studentData?.data;

    const tempDataSets = []



    const today = new Date();
    const currentYear = today.getFullYear();

    const salesDataSets = []
    const receiptDataSets = []
    const duesDataSets = []
    const labelDataSets = []

    if (duration <= DAY) {
      for (
        let datasetStart = new Date(startDate), datasetEnd = new Date(startDate.setHours(startDate.getHours() + 1));
        datasetEnd <= endDate;
        datasetStart = new Date(datasetStart.setHours(datasetStart.getHours() + 1)), datasetEnd = new Date(datasetEnd.setHours(datasetEnd.getHours() + 1))
      ) {
        const receipt = calculateSalesReceipt(associateApiResponse, studentApiResponse, datasetStart, datasetEnd)
        salesDataSets.push(receipt.totalSales.toFixed(2))
        receiptDataSets.push(receipt.totalPayments.toFixed(2))
        duesDataSets.push(receipt.dueAmount.toFixed(2))
        labelDataSets.push(datasetStart.toLocaleTimeString('en-IN', { hour: 'numeric', minute: 'numeric', hour12: true, timeZone: 'Asia/Kolkata' }));



      }
    }
    else if (duration <= WEEK) {
      for (
        let datasetStart = new Date(startDate), datasetEnd = new Date(startDate.setDate(startDate.getDate() + 1));
        datasetEnd <= endDate;
        datasetStart = new Date(datasetStart.setDate(datasetStart.getDate() + 1)), datasetEnd = new Date(datasetEnd.setDate(datasetEnd.getDate() + 1))
      ) {
        const receipt = calculateSalesReceipt(associateApiResponse, studentApiResponse, datasetStart, datasetEnd)
        salesDataSets.push(receipt.totalSales.toFixed(2))
        receiptDataSets.push(receipt.totalPayments.toFixed(2))
        duesDataSets.push(receipt.dueAmount.toFixed(2))
        labelDataSets.push(datasetStart.toLocaleDateString('en-IN', { weekday: 'short' }));

      }
    }
    else if (duration <= MONTH) {
      for (
        let datasetStart = new Date(startDate), datasetEnd = new Date(startDate.setDate(startDate.getDate() + 1));
        datasetEnd <= endDate;
        datasetStart = new Date(datasetStart.setDate(datasetStart.getDate() + 1)), datasetEnd = new Date(datasetEnd.setDate(datasetEnd.getDate() + 1))
      ) {
        const receipt = calculateSalesReceipt(associateApiResponse, studentApiResponse, datasetStart, datasetEnd)
        salesDataSets.push(receipt.totalSales.toFixed(2))
        receiptDataSets.push(receipt.totalPayments.toFixed(2))
        duesDataSets.push(receipt.dueAmount.toFixed(2))
        labelDataSets.push(datasetStart.toLocaleDateString())
      }
    }
    else if (duration <= 12 * MONTH) {
      for (
        let datasetStart = new Date(startDate), datasetEnd = new Date(startDate.setMonth(startDate.getMonth() + 1));
        datasetEnd <= endDate;
        datasetStart = new Date(datasetStart.setMonth(datasetStart.getMonth() + 1)), datasetEnd = new Date(datasetEnd.setMonth(datasetEnd.getMonth() + 1))
      ) {
        const receipt = calculateSalesReceipt(associateApiResponse, studentApiResponse, datasetStart, datasetEnd)
        salesDataSets.push(receipt.totalSales.toFixed(2))
        receiptDataSets.push(receipt.totalPayments.toFixed(2))
        duesDataSets.push(receipt.dueAmount.toFixed(2))
        labelDataSets.push(datasetStart.toLocaleString('default', { month: 'long' }))
      }
    }
    else if (duration <= 2 * YEAR) {
      //here i am getting errror
      for (

        let datasetStart = new Date(startDate), datasetEnd = new Date(datasetStart);
        datasetEnd <= endDate;
        datasetStart = new Date(datasetEnd), datasetEnd.setMonth(datasetEnd.getMonth() + 3)


      ) {
        const receipt = calculateSalesReceipt(associateApiResponse, studentApiResponse, datasetStart, datasetEnd)
        salesDataSets.push(receipt.totalSales.toFixed(2))
        receiptDataSets.push(receipt.totalPayments.toFixed(2))
        duesDataSets.push(receipt.dueAmount.toFixed(2))
        labelDataSets.push(datasetStart.toLocaleString('default', { month: 'long', year: 'numeric' }))
      }
    }
    else {
      for (
        let datasetStart = new Date(startDate), datasetEnd = new Date(new Date(startDate).setMonth(startDate.getMonth() + 6));
        datasetEnd <= endDate;
        datasetStart = new Date(datasetStart.setMonth(datasetStart.getMonth() + 6)), datasetEnd = new Date(datasetEnd.setMonth(datasetEnd.getMonth() + 6))
      ) {
        const receipt = calculateSalesReceipt(associateApiResponse, studentApiResponse, datasetStart, datasetEnd)
        salesDataSets.push(receipt.totalSales.toFixed(2))
        receiptDataSets.push(receipt.totalPayments.toFixed(2))
        duesDataSets.push(receipt.dueAmount.toFixed(2))
        labelDataSets.push(datasetStart.getFullYear())
      }
    }

    tempDataSets.push({ label: 'Sale', data: salesDataSets, fill: false, borderColor: '#70018B', tension: 0.1 })
    tempDataSets.push({ label: 'Receipt', data: receiptDataSets, fill: false, borderColor: 'green', tension: 0.1 })
    tempDataSets.push({ label: 'Dues', data: duesDataSets, fill: false, borderColor: 'red', tension: 0.1 })

    setLabelSets(labelDataSets)
    setDataSets(tempDataSets)
  }

  const getDataItems = () => {

    const associateApiResponse = assoRes?.data;
    const studentApiResponse = studentData?.data;

    const tempItems = []

    const todayEndDate = new Date();
    todayEndDate.setHours(23, 59, 59, 999)

    const todayStartDate = new Date();
    todayStartDate.setHours(0, 0, 0, 0)

    const todayReceipt = calculateSalesReceipt(associateApiResponse, studentApiResponse, todayStartDate, todayEndDate);
    tempItems.push({ id: 'Today', sale: todayReceipt.totalSales.toFixed(2), receipt: todayReceipt.totalPayments.toFixed(2), due: todayReceipt.dueAmount.toFixed(2), invoice: todayReceipt.totalInvoice })

    const currentDay = todayStartDate.getDay();


    const weeklyStartDate = new Date(todayStartDate);
    weeklyStartDate.setDate(todayStartDate.getDate() - currentDay);
    weeklyStartDate.setHours(0, 0, 0, 0);


    const weeklyEndDate = new Date(todayStartDate);
    weeklyEndDate.setDate(todayStartDate.getDate() + (6 - currentDay));
    weeklyEndDate.setHours(23, 59, 59, 999);

    const weeklyReceipt = calculateSalesReceipt(associateApiResponse, studentApiResponse, weeklyStartDate, weeklyEndDate);
    tempItems.push({ id: 'Weekly', sale: weeklyReceipt.totalSales.toFixed(2), receipt: weeklyReceipt.totalPayments.toFixed(2), due: weeklyReceipt.dueAmount.toFixed(2), invoice: weeklyReceipt.totalInvoice });



    const monthlyEndDate = new Date(todayStartDate.getFullYear(), todayStartDate.getMonth() + 1, 0);
    monthlyEndDate.setHours(23, 59, 59, 999)

    const monthlyStartDate = new Date();
    monthlyStartDate.setHours(0, 0, 0, 0)
    monthlyStartDate.setDate(1)

    const monthlyReceipt = calculateSalesReceipt(associateApiResponse, studentApiResponse, monthlyStartDate, monthlyEndDate);
    tempItems.push({ id: 'Monthly', sale: monthlyReceipt.totalSales.toFixed(2), receipt: monthlyReceipt.totalPayments.toFixed(2), due: monthlyReceipt.dueAmount.toFixed(2), invoice: monthlyReceipt.totalInvoice })

    const currentQuarter = Math.floor((todayStartDate.getMonth() + 3) / 3);


    const quarterStartDate = new Date(todayStartDate.getFullYear(), (currentQuarter - 1) * 3, 1);
    quarterStartDate.setHours(0, 0, 0, 0);


    const quarterEndDate = new Date(quarterStartDate);
    quarterEndDate.setMonth(quarterStartDate.getMonth() + 3);
    quarterEndDate.setDate(quarterEndDate.getDate() - 1);
    quarterEndDate.setHours(23, 59, 59, 999);

    const quarterReceipt = calculateSalesReceipt(associateApiResponse, studentApiResponse, quarterStartDate, quarterEndDate);
    tempItems.push({ id: 'Quarterly', sale: quarterReceipt.totalSales.toFixed(2), receipt: quarterReceipt.totalPayments.toFixed(2), due: quarterReceipt.dueAmount.toFixed(2), invoice: quarterReceipt.totalInvoice });


    const yearlyEndDate = new Date(todayStartDate.getFullYear() + 1, 0, 0);
    yearlyEndDate.setHours(23, 59, 59, 999)

    const yearlyStartDate = new Date(todayStartDate.getFullYear(), 0, 1);
    yearlyStartDate.setHours(0, 0, 0, 0)

    const yearlyReceipt = calculateSalesReceipt(associateApiResponse, studentApiResponse, yearlyStartDate, yearlyEndDate);
    tempItems.push({ id: 'Yearly', sale: yearlyReceipt.totalSales.toFixed(2), receipt: yearlyReceipt.totalPayments.toFixed(2), due: yearlyReceipt.dueAmount.toFixed(2), invoice: yearlyReceipt.totalInvoice })




    setItems(tempItems);
  }



  useEffect(() => {
    let filterStartDate;
    let filterEndDate;

    if (selectValue === 'Select') {
      filterStartDate = new Date();
      filterStartDate.setFullYear(filterStartDate.getFullYear() - 10);
      filterEndDate = new Date();
      filterEndDate.setFullYear(filterEndDate.getFullYear() + 20);
    }
    else if (selectValue === 'last 7 days') {
      filterEndDate = new Date();
      filterStartDate = new Date(new Date().setDate(new Date().getDate() - 6));
    } else if (selectValue === 'last month') {
      filterEndDate = new Date();
      filterStartDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
    }

    else if (selectValue === 'Previous year') {
      filterStartDate = new Date(new Date().getFullYear() - 1, 3, 1); // 1st April of previous year
      filterEndDate = new Date(new Date().getFullYear(), 2, 31); // 31st March of current year
    } else if (selectValue === 'Current year') {
      filterStartDate = new Date(new Date().getFullYear(), 3, 1); // 1st April of current year
      filterEndDate = new Date(new Date().getFullYear() + 1, 2, 31); // 31st March of next year
    }

    else if (selectValue === 'Current day') {
      filterEndDate = new Date();
      filterStartDate = new Date();
      filterStartDate.setHours(0, 0, 0, 0);
      filterEndDate.setHours(23, 59, 59, 999);
    } else if (selectValue === 'Yesterday') {
      filterEndDate = new Date(new Date().setDate(new Date().getDate() - 1));
      filterStartDate = new Date(new Date().setDate(new Date().getDate() - 1));
      filterStartDate.setHours(0, 0, 0, 0);
      filterEndDate.setHours(23, 59, 59, 999);
    } else if (selectValue === 'This Week') {
      const today = new Date();
      const dayOfWeek = today.getDay();
      filterStartDate = new Date(today.setDate(today.getDate() - dayOfWeek));
      filterEndDate = new Date();
      filterStartDate.setHours(0, 0, 0, 0);
      filterEndDate.setHours(23, 59, 59, 999);
    } else if (selectValue === 'Current Month') {
      const today = new Date();
      filterStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
      filterEndDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      filterStartDate.setHours(0, 0, 0, 0);
      filterEndDate.setHours(23, 59, 59, 999);
    }
    else if (selectValue === 'Custom' && startDate && endDate) {
      filterStartDate = new Date(startDate);
      filterEndDate = new Date(endDate);
    }

    getDataSets(filterStartDate, filterEndDate);
    getDataItems();
  }, [assoRes.isSuccess, studentData.isSuccess, packageData.isSuccess, selectValue, startDate, endDate]);



  if (response.isLoading) return <Loading />
  if (response.isError) return <ApiErrorModal response={response} />

  return (

    <div className='p-5'>
      <h4 className='text-primary fw-bold mb-3'>Dashboard</h4>

      <div className="container-fluid p-0 mb-5">
        <div className="row">
          <div className="col-lg-8">

            <div className="row mb-1 ">
              <div className={selectValue === 'Custom' ? `col-4` : 'col-6 col-lg-4'}>
                <SelectInput
                  name={"Select Status"}
                  noLabel
                  options={[
                    { value: 'Select', label: 'Select' },
                    { value: 'Current day', label: 'Today' },
                    { value: 'Yesterday', label: 'Yesterday' },
                    { value: 'This Week', label: 'This Week' },
                    { value: 'Current Month', label: 'Current Month' },
                    { value: 'last month', label: 'Previous month' },
                    { value: 'Current year', label: 'Current year' },
                    { value: 'Previous year', label: 'Previous year' },
                    { value: 'Custom', label: 'Custom' },
                    // { value: 'last 7 days', label: 'last 7 days' },
                  ]}
                  value={selectValue}
                  onChange={(e) => setSelectValue(e.target.value)}
                  containerClass={'w-100 mb-3'}
                  required
                />
              </div>


              {selectValue === 'Custom' && (
                <>
                  <div className="col-6 col-lg-3">
                    <input
                      type="date"
                      className="form-control mb-3"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                  <div className="col-6 col-lg-3">
                    <input
                      type="date"
                      className="form-control mb-3"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                </>
              )}
            </div>

            <div className="card h-100 rounded-4 shadow">
              <LineChart datasets={dataSets} labels={labelSets} />
            </div>
          </div>

          <div className="col-lg-4   mt-lg-0 ">
            <div className="card rounded-4 overflow-hidden shadow " style={{ marginTop: 59 }}>
              <table className="table text-end table-hover">
                <thead>
                  <tr className='border-bottom'>
                    <th scope="col" className='text-muted'></th>
                    <th scope="col" className='text-muted'>SALES</th>
                    <th scope="col" className='text-muted'>Invoice</th>
                    <th scope="col" className='text-muted'>DUE</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item, index) => (
                    <tr key={index}>
                      <th scope="row" className='text-muted text-start'>{item.id}</th>
                      <td className='text-primary'>{item?.sale}</td>
                      <td className='text-primary'>{item?.invoice}</td>
                      <td className='text-primary'>{item?.due}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <ApprovalRequests items={response.data} refetch={response.refetch} studentData={studentData} />

    </div>


  )
}

export default Dashboard