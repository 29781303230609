import { useEffect } from 'react';
import { useSignOutMutation, useSignInMutation } from '../../services/authSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Icons from '../ui/Icons';
import SignOutConfirmation from '../modals/SignOutConfirmation';
import { setAuthToken } from '../../redux/authSlice';

export default function SignOut() {
  const [signOut, signOutResponse] = useSignOutMutation();
  const [signIn, signInResponse] = useSignInMutation();
  const token = useSelector(state => state.apiSlice.mutations?.adminsignIn?.data?.token);
  const dispatch = useDispatch()

  const handleSignOut = (event) => {
    event.preventDefault()
    signOut();
    signInResponse.reset()
    dispatch(setAuthToken(null))
  }

  return (
    <>

      {/* <button type="button" id="successModalButton" className="nav-link d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#SignOutConfirmation">
        SIGN OUT
      </button> */}

      <li  className="nav-item py-1">
        {/* <NavLink to={link.to} className="nav-link d-flex align-items-center">
          {link.icon("me-2", { width: 20, height: 20 })}
          {link.text}
        </NavLink> */}
        <button type="button" id="confirmationModalButton" className="nav-link d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#SignOutConfirmation">
        { Icons.settings("me-2", { width: 20, height: 20 })}  SIGN OUT
      </button>
      </li>




    </>
  );
}

