import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";


export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAssociates: builder.query({ query: () => `/associates`, keepUnusedDataFor: 0 }),
        getAssociateId: builder.query({ query: (id) => `/associates?id=${id}`, keepUnusedDataFor: 0 }),
        getPendingAssociate: builder.query({ query: () => `/associates/pending`, keepUnusedDataFor: 0 }),
        postAssociate: builder.mutation({ query: (body) => ({ url: `/associates/create`, method: 'POST', body }) }),


        updateAssociateStatus: builder.mutation({ query: ({ id, status, reason }) => ({ url: `/associates/updateStatus?id=${id}&status=${status}&reason=${reason}`, method: 'POST' }) }),

        updateAssociate: builder.mutation({ query: ({ id, body }) => ({ url: `associates/update?id=${id}`, method: 'POST', body }), invalidatesTags: ['sub'], }),
        updateIdCardStatus: builder.mutation({ query: ({ id, profileStatus, idCardStatus ,offerLetterStatus}) => ({ url: `/associates/updateIdCardStatus?id=${id}&profileStatus=${profileStatus}&idCardStatus=${idCardStatus}&offerLetterStatus=${offerLetterStatus}`, method: 'POST' }) }),

        createPaymentFromAdmin: builder.mutation({ query: ({id,amount}) => ({ url: `/associates/paymentFromAdmin?id=${id}&amount=${amount}`, method: 'POST',  }) }),

        updateAssociatePaymentMode: builder.mutation({ query: ({ id, body }) => ({ url: `associates/updatePaymentMode?id=${id}`, method: 'POST', body }) }),


    }),
})

export const { useGetAssociateIdQuery, useCreatePaymentFromAdminMutation, useGetAssociatesQuery, useUpdateAssociatePaymentModeMutation, useGetPendingAssociateQuery, usePostAssociateMutation, useUpdateAssociateStatusMutation, useUpdateAssociateMutation ,useUpdateIdCardStatusMutation} = extendedApiSlice
