import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { apiSlice } from './apiSlice'
import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage'

import authReducer from './authSlice'

const persistConfig = { key: 'admin-sudikshya-app-26', storage }


const persistedReducer = persistReducer(persistConfig, authReducer)
const reducer = combineReducers({ auth: persistedReducer, [apiSlice.reducerPath]: apiSlice.reducer })

export const store = configureStore({ reducer, middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware) })



setupListeners(store.dispatch)