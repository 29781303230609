import "./assets/scss/bootstrap.scss"
import "./assets/css/global.css"
import "./assets/css/fonts.css"
import "./assets/css/scrollbar.css"
import SignIn from './pages/SignIn';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './components/layout/Layout';
import { useEffect, useState } from 'react';
import Teams from "./pages/associate/Teams";
import Documents from "./pages/document/Documents";
import CreateAssociate from "./pages/associate/CreateAssociate";
import Associate from "./pages/associate/Associate";
import Reports from "./pages/report/Reports";
import Settings from "./pages/Settings";
import Dashboard from "./pages/Dashboard";
import Students from "./pages/student/Students";
import Student from "./pages/student/Student";
import CreateDesignation from "./pages/designation/CreateDesignation";
import CreateStudent from "./pages/student/CreateStudent";
import Designations from "./pages/designation/Designations";
import Orders from "./pages/order/Orders";
import CreateOrder from "./pages/order/CreateOrder";
import Leads from "./pages/lead/Leads";
import CreateLead from "./pages/lead/CreateLead";
import ProductStaffs from "./pages/product/ProductStaffs";
import ProductCourses from "./pages/product/ProductCourses";
import CreateProduct from "./pages/product/CreateProduct";
import EditCreateStudent from "./pages/student/EditCreateStudent";
import EditCreateAssociate from "./pages/associate/EditCreateAssociate";
import EditProducts from "./pages/product/EditProducts";
import EditDesignation from "./pages/designation/EditDesignation";
import { useSelector } from "react-redux";
import SignOut from "./components/layout/SignOut";
import ProductSalesReport from "./pages/report/ProductSalesReport";
import DesignationUserReport from "./pages/report/DesignationUserReport";
import UserAssociateReport from "./pages/report/UserAssociateReport";
import UserStudentReport from "./pages/report/UserStudentReport";
import UserSalesReport from "./pages/report/UserSalesReport";
import ProductCategory from "./pages/product/ProductCategory";
import SelectedGateway from "./pages/SelectedGateway";
import { useGetSiteInfoQuery } from "./services/adminSlice";
import Api from "./constants/Api";
import NetSalesReport from "./pages/report/NetSalesReport";




function App() {

  const isSignedIn = useSelector(state => state.auth.authToken);
  const getinfo = useGetSiteInfoQuery(1)

  console.log('isSignedIn',isSignedIn);
  useEffect(() => { import('bootstrap') }, [])


  useEffect(() => {
    if (getinfo.data) {
      document.title = getinfo.data.siteName; // Update document title
      const favicon = document.querySelector("link[rel='icon']");
      favicon.href = Api.BASE_URL+ getinfo.data.favIcon; // Update favicon
    }
  }, [getinfo.data]);

  // console.log("sitename",getinfo)
  // console.log("tagline",getinfo.data.tagline)
  // console.log("favicon",Api.BASE_URL+ getinfo.data.favIcon)

  return (
  
    
      <Routes>
        {
          isSignedIn ?
            <Route path='/' element={<Layout />}>

              <Route index element={<Dashboard />} />
                <Route path="designations" element={<Designations />} />

              <Route path='team'>
                <Route index element={<Teams />} />
                <Route path=':id' element={<Associate />} />
              </Route>

              <Route path='student'>
                <Route index element={<Students />} />
                <Route path=':id' element={<Student />} />
              </Route>

              <Route path='order'>
                <Route index element={<Orders />} />
                {/* <Route path=':id' element={<Team />} /> */}
              </Route>

              <Route path='document'>
                <Route index element={<Documents />} />
                {/* <Route path=':id' element={<Team />} /> */}
              </Route>

              <Route path='lead'>
                <Route index element={<Leads />} />
                {/* <Route path=':id' element={<Team />} /> */}
              </Route>

              <Route path='product'>
                {/* <Route index element={<Products />} /> */}
                <Route path='staffs' element={<ProductStaffs />} />
                <Route path='category' element={<ProductCategory/>} />
                <Route path='courses' element={<ProductCourses />} />
                <Route path='courses/:id' element={<ProductCourses />} />

              </Route>

              <Route path='report'>
                <Route index  element={<Reports />} />
                <Route path='product' element={<ProductSalesReport />} />
                <Route path='designation' element={<DesignationUserReport />} />

                <Route path='user' element={<UserAssociateReport />} />
                <Route path='user/:id' element={<UserAssociateReport />} />
                
                <Route path='student' element={<UserStudentReport />} />
                <Route path='student/:id' element={<UserStudentReport />} />
                
                <Route path='user/sales' element={<UserSalesReport />} />
                <Route path='user/net-sales' element={<NetSalesReport />} />
                <Route path='user/sales/:id' element={<UserSalesReport />} />



              </Route>

              <Route path='settings'>
                <Route index element={<Settings />} />
                {/* <Route path=':id' element={<Team />} /> */}
              </Route>

              {/* <Route path='document'>
                <Route index element={<Documents />} />
              </Route> */}

              <Route path='create'>
                <Route path='associate' element={<CreateAssociate />} />    
                <Route path='associate/:id' element={<CreateAssociate />} />

                <Route path='student' element={<CreateStudent />} />
                <Route path='student/:id' element={<CreateStudent />} />

                <Route path='order' element={<CreateOrder />} />
                <Route path='product' element={<CreateProduct />} />
                <Route path='lead' element={<CreateLead />} />
                <Route path='designation' element={<CreateDesignation />} />

              </Route>

              <Route path='edit'>
                <Route path='associate/:id' element={<EditCreateAssociate />} />
                <Route path='student/:id' element={<EditCreateStudent />} />
                <Route path='product/:id' element={<EditProducts />} />
                <Route path='designation/:id' element={<EditDesignation />} />
               
              </Route>

              <Route path='selectGateway' element={<SelectedGateway />} />
              <Route path='signout' element={<SignOut />} />
              
            </Route>
            :
            <Route path='/'>
              <Route index element={<SignIn  />} />
              <Route path='*' element={<SignIn />} />
            </Route>
        }
      </Routes>
    
  );
}

export default App;
