// import React, { useEffect, useRef, useState } from 'react'

// const FileInput = ({ name, placeholder, type, containerClass, inputClass, ...attributes }) => {

//     const [file, setFile] = useState(null)
//     const fileInputRef = useRef(null);

//     const containerClassName = " col " + (containerClass ? containerClass : "")
//     const inputClassName = "form-control rounded-pill px-3 border-primary border-2 " + (inputClass ? inputClass : "")
//     const lableClass = "fw-bold mb-1 ms-1";

//     const onChange = (e) => setFile(e.target.files[0])

//     useEffect(() => {
//         const inputElement = fileInputRef.current;
//         const form = inputElement?.form;

//         if (form) {
//             const handleReset = () => setFile(null);
//             form.addEventListener('reset', handleReset);

//             // Cleanup the event listener
//             return () => {
//                 form.removeEventListener('reset', handleReset);
//             };
//         }
//     }, []);


//     return (
//         <div className={containerClassName}>
//             <label htmlFor={name} className={lableClass}>{name}</label>
//             <label className={inputClassName} htmlFor={name}>{(file ? "Change " : "Upload ") + name}</label>
//             <input
//                 type={"file"}
//                 className={"d-none"}
//                 ref={fileInputRef}
//                 id={name}
//                 name={name}
//                 {...attributes}
//                 accept="image/*"
//                 onChange={onChange}
//                 onInput={onChange}
//                 onChangeCapture={onChange}
//                 onInvalid={onChange}
//             />
//             <div className="invalid-feedback ms-3">Select Image</div>
//         </div>
//     )
// }

// export default FileInput


import React, { useEffect, useRef, useState } from 'react';

const FileInput = ({ name, placeholder, type, containerClass, inputClass, ...attributes }) => {
    const [file, setFile] = useState(null);
    const fileInputRef = useRef(null);

    const containerClassName = "col " + (containerClass ? containerClass : "");
    const inputClassName = "form-control rounded-pill px-3 border-primary border-2 " + (inputClass ? inputClass : "");
    const labelClass = "fw-bold mb-1 ms-1";

    const onChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);

        // Show the name of the selected file
        const fileNameElement = document.getElementById(`file-name-${name}`);
        if (fileNameElement) {
            fileNameElement.innerText = selectedFile ? selectedFile.name : '';
        }
    };

    useEffect(() => {
        const inputElement = fileInputRef.current;
        const form = inputElement?.form;

        if (form) {
            const handleReset = () => setFile(null);
            form.addEventListener('reset', handleReset);

            // Cleanup the event listener
            return () => {
                form.removeEventListener('reset', handleReset);
            };
        }
    }, []);

    return (
        <div className={containerClassName}>
            <label htmlFor={name} className={labelClass}>{name}</label>
            <label className={inputClassName} htmlFor={name} id={`file-name-${name}`} >
                {(file ? `file-name-${name}` : "Upload " + name) }
            </label>
            <input
                type="file"
                className="d-none"
                ref={fileInputRef}
                id={name}
                name={name}
                {...attributes}
                accept="image/*"
                onChange={onChange}
                onInput={onChange}
                onChangeCapture={onChange}
                onInvalid={onChange}
            />
            <div className="invalid-feedback ms-3">Select Image</div>
            {/* Display the selected file name */}
        </div>
    );
};

export default FileInput;
