import React, { useEffect } from 'react'
import { useSignInMutation, useSignOutMutation } from '../../services/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthToken } from '../../redux/authSlice';

const SignOutConfirmation = ({ label }) => {

  const dispatch = useDispatch()
  const [signOut, signOutResponse] = useSignOutMutation();
  const [signIn, signInResponse] = useSignInMutation();
  const token = useSelector(state => state.auth.authToken);



  const onConfirm = (event) => {
    event.preventDefault()
    signOut({ token });
    dispatch(setAuthToken(null))
  }

  return (
    <>


      <div className="modal fade" id="SignOutConfirmation" tabindex="-1" aria-labelledby="SignOutConfirmationLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="SignOutConfirmationLabel">{label ? label : "Confirmation"}</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">Do You Want to Sign Out? </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={onConfirm}>yes</button>
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SignOutConfirmation