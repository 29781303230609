


import React, { useState } from 'react';
import { useGetAssociatesQuery, useUpdateIdCardStatusMutation } from '../../services/associateSlice';
import Loading from '../../components/ui/Loading';
import ApiErrorModal from '../../components/modals/ApiErrorModal';
import InputSearch from '../../components/form/InputSearch';
import { convertToFiveDigits } from '../../utils/ConvertToFive';
import ProfileModal from './ProfileModal';
import IdCardModal from './IdCardModal';
import OfferLetterModal from './OfferLetterModal';
import AssociateInvoiceModal from '../associate/AssociateInvoiceModal';
import SuccessModal from '../../components/modals/SuccessModal';

const Documents = () => {
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 100; 
  const response = useGetAssociatesQuery();
  const [updateAssociate, updateAssociateResponse] = useUpdateIdCardStatusMutation();



  if (response.isLoading) return <Loading />;
  if (response.isError) return <ApiErrorModal response={response} />;

  const filteredData = response.data.filter((item) => {
    if (searchValue.length === 0) return true;
    return (
      item?.associate?.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      `S3A${convertToFiveDigits(item?.associate?.serialNo)}`.toLowerCase().includes(searchValue.toLowerCase()) ||
      item?.associate?.mobileNumber.toLowerCase().includes(searchValue.toLowerCase())
    );
  }).filter((item) => item.associate.status == 'Approved')

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className='p-3 p-lg-5'>
      {updateAssociateResponse.isSuccess && <SuccessModal message={"Associate has been updated."} />}
      {updateAssociateResponse.isError && <ApiErrorModal response={updateAssociateResponse} />}


      <h4 className='text-primary fw-bold mb-3'>Document Management</h4>
      <div className="row row-cols-1 row-cols-lg-3 g-3 mb-3">
        <div className="col"><InputSearch name={"Search"} containerClass={"w-100"} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} /></div>
      </div>

      <div className='card rounded-4 overflow-hidden shadow mb-3 '>
        <div className="table-responsive">
          <table className="table table-borderless table-hover">
            <thead>
              <tr className='border-bottom'>
                <th scope="col" className='text-muted'>Associate ID</th>
                <th scope="col" className='text-muted'>Name</th>
                <th scope="col" className='text-muted'>Date</th>
                <th scope="col" className='text-muted'>Mobile No.</th>
                <th scope="col" className='text-muted'>Rank</th>
                <th scope="col" className='text-muted'>Profile</th>
                <th scope="col" className='text-muted'>ID Card</th>
                <th scope="col" className='text-muted'>Offer Letter</th>
                <th scope="col" className='text-muted'>Invoice</th>
              </tr>
            </thead>
            <tbody>
              {currentItems?.reverse()?.map((item, i) => (
                <tr key={i}>
                  <th scope='row'>{item?.associate?.serialNo && `S3A${convertToFiveDigits(item?.associate?.serialNo)}`}</th>
                  <th>{item?.associate?.name}</th>
                  <th>{ item?.associate?.createdAt && !isNaN(new Date(item?.associate?.createdAt)) ? new Date(item?.associate?.createdAt).toLocaleDateString('en-GB').split('/').join('-') : ''}</th>

                  <th>{item?.associate?.mobileNumber}</th>
                  <th>{item?.designation?.name}</th>
                  <th>
                  <div className='d-flex gap-3 '>
                      <div className=''>
                        <select
                          value={item.associate.profileStatus}
                          onChange={(e) =>
                            updateAssociate({
                              id: item.associate.id,
                              profileStatus:   e.target.value,
                              idCardStatus: item.associate.idCardStatus,
                              offerLetterStatus: item.associate.offerLetterStatus
                            }).then(() => response.refetch())
                          }
                          className='fw-bold'
                          style={{
                            appearance: 'none',
                            border: 'none',
                            backgroundColor: 'transparent',
                            padding: '0',
                            fontFamily: 'inherit',
                            background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23000' width='24px' height='24px'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E") no-repeat right 0.5rem center/1.5rem`,
                            paddingRight: '2rem', // Space for the arrow icon
                            borderBottom: 'none', // Remove bottom border
                            color: item.associate.profileStatus === 'Pending' ? 'red' : 'green' // Change color based on status
                          }}
                        >
                          <option value="Pending">Pending</option>
                          <option value="Issued">Issued</option>
                          <option value="Submitted">Submitted</option>
                        </select>

                      </div>
                      {(item.associate.profileStatus != 'Pending') && <ProfileModal item={item} />}
                    </div>
                  </th>
                  {/* //--------- */}


                  <th>
                    <div className='d-flex gap-3 '>
                      <div className=''>
                        <select
                          value={item.associate.idCardStatus}
                          onChange={(e) =>
                            updateAssociate({
                              id: item.associate.id,
                              profileStatus: item.associate.profileStatus,
                              idCardStatus: e.target.value,
                              offerLetterStatus: item.associate.offerLetterStatus
                            }).then(() => response.refetch())
                          }
                          className='fw-bold'
                          style={{
                            appearance: 'none',
                            border: 'none',
                            backgroundColor: 'transparent',
                            padding: '0',
                            fontFamily: 'inherit',
                            background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23000' width='24px' height='24px'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E") no-repeat right 0.5rem center/1.5rem`,
                            paddingRight: '2rem', // Space for the arrow icon
                            borderBottom: 'none', // Remove bottom border
                            color: item.associate.idCardStatus === 'Pending' ? 'red' : 'green' // Change color based on status
                          }}
                        >
                          <option value="Pending">Pending</option>
                          <option value="Issued">Issued</option>
                          <option value="Submitted">Submitted</option>
                        </select>

                      </div>
                      {(item.associate.idCardStatus != 'Pending') && <IdCardModal item={item} />}
                    </div>
                  </th>
                  <th>
                    <div className='d-flex gap-3'>
                      <div className=''>
                        <select
                          value={item.associate.offerLetterStatus}
                          onChange={(e) =>
                            updateAssociate({
                              id: item.associate.id,
                              profileStatus: item.associate.profileStatus,
                              idCardStatus: item.associate.idCardStatus,
                              offerLetterStatus: e.target.value
                            }).then(() => response.refetch())
                          }
                          className='fw-bold'
                          style={{
                            appearance: 'none',
                            border: 'none',
                            backgroundColor: 'transparent',
                            padding: '0',
                            fontFamily: 'inherit',
                            background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23000' width='24px' height='24px'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E") no-repeat right 0.5rem center/1.5rem`,
                            paddingRight: '2rem', // Space for the arrow icon
                            borderBottom: 'none', // Remove bottom border
                            color: item.associate.offerLetterStatus === 'Pending' ? 'red' : 'green' // Change color based on status
                          }}
                        >
                          <option value="Pending">Pending</option>
                          <option value="Issued">Issued</option>
                          <option value="Submitted">Submitted</option>
                        </select>

                      </div>
                      {(item.associate.offerLetterStatus != 'Pending') && <OfferLetterModal item={item} />}

                    </div>
                  </th>



                  {/* ==================== */}
                  <th>
                    <div className='d-flex'>
                      <div className='me-3'></div>
                      <AssociateInvoiceModal item={item} linkk={true} />
                    </div>
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-center">
          {Array.from({ length: Math.ceil(filteredData.length / itemsPerPage) }, (_, index) => (
            <li key={index} className={`page-item ${index + 1 === currentPage ? 'active' : ''}`}>
              <button className="page-link" onClick={() => setCurrentPage(index + 1)}>
                {index + 1}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Documents;
