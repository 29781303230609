import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Input from '../../components/form/Input'
import Icons from '../../components/ui/Icons'
import SelectInput from '../../components/form/SelectInput'
import { useGetProductsQuery } from '../../services/productSlice'
import Loading from '../../components/ui/Loading'
import { useGetStudentsQuery } from '../../services/studentSlice'
import { useGetAssociatesQuery } from '../../services/associateSlice'
import ReactToPrint from 'react-to-print';
import SearchSelectInput from '../../components/form/SearchSelectInput'
import { convertToFiveDigits } from '../../utils/ConvertToFive'

const UserAssociateReport = () => {

  const [selectValue, setSelectValue] = useState("")
  const [selectFilter, setSelectFilter] = useState("Select")


  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  let count = 1;
  const { id } = useParams();

  const modalBodyRef = useRef();

  const courseRes = useGetProductsQuery()
  const response = useGetAssociatesQuery()


  useEffect(() => {

    if (response.isSuccess && id) {
      setSelectValue(id)
    }

  }, [response.isSuccess])



  useEffect(() => {
    let filterStartDate;
    let filterEndDate;

    if (selectFilter === 'Select') {
      filterStartDate = new Date();
      filterStartDate.setFullYear(filterStartDate.getFullYear() - 10);
      filterEndDate = new Date();
      filterEndDate.setFullYear(filterEndDate.getFullYear() + 20);
    }
    else if (selectFilter === 'last 7 days') {
      filterEndDate = new Date();
      filterStartDate = new Date(new Date().setDate(new Date().getDate() - 6));
    } else if (selectFilter === 'last month') {
      filterEndDate = new Date();
      filterStartDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
    }

    else if (selectFilter === 'Previous year') {
      filterStartDate = new Date(new Date().getFullYear() - 1, 3, 1); // 1st April of previous year
      filterEndDate = new Date(new Date().getFullYear(), 2, 31); // 31st March of current year
    } else if (selectFilter === 'Current year') {
      filterStartDate = new Date(new Date().getFullYear(), 3, 1); // 1st April of current year
      filterEndDate = new Date(new Date().getFullYear() + 1, 2, 31); // 31st March of next year
    }


    else if (selectFilter === 'Current day') {
      filterEndDate = new Date();
      filterStartDate = new Date();
      filterStartDate.setHours(0, 0, 0, 0);
      filterEndDate.setHours(23, 59, 59, 999);
    } else if (selectFilter === 'Yesterday') {
      filterEndDate = new Date(new Date().setDate(new Date().getDate() - 1));
      filterStartDate = new Date(new Date().setDate(new Date().getDate() - 1));
      filterStartDate.setHours(0, 0, 0, 0);
      filterEndDate.setHours(23, 59, 59, 999);
    } else if (selectFilter === 'This Week') {
      const today = new Date();
      const dayOfWeek = today.getDay();
      filterStartDate = new Date(today.setDate(today.getDate() - dayOfWeek));
      filterEndDate = new Date();
      filterStartDate.setHours(0, 0, 0, 0);
      filterEndDate.setHours(23, 59, 59, 999);
    } else if (selectFilter === 'Current Month') {
      const today = new Date();
      filterStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
      filterEndDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      filterStartDate.setHours(0, 0, 0, 0);
      filterEndDate.setHours(23, 59, 59, 999);
    }
    else if (selectFilter === 'Custom' && startDate && endDate) {
      filterStartDate = new Date(startDate);
      filterEndDate = new Date(endDate);
    }
    setStartDate(filterStartDate)
    setEndDate(filterEndDate)
  }, [response.isSuccess, selectFilter]);


  if (response.isLoading) return <Loading />


  const filteredDataTop = response.data?.filter((item) => {
    if (selectValue.length === 0) return true;
    return selectValue.length > 0 && item?.associate?.id == selectValue;
  })
    ?.filter((item) => item?.associate?.status == 'Approved');





  return (
    <div className='p-5'>
      {console.log("courseRes", response.data)}

      <h4 className='text-primary fw-bold mb-3'>Reports</h4>

      <div className='d-flex mb-3 flex-wrap align-items-center justify-content-between mb-5'>

        <div className='d-flex gap-4'>



          <SearchSelectInput
            name={"Select Associate"}
            options={[
              ...[{
                value: '',
                label: 'Select',
              }],
              ...response?.data?.filter(item => item?.associate?.status == 'Approved')?.map((item) => ({
                value: item?.associate?.id,
                label: item?.associate?.name,
                serialNo: item?.associate?.serialNo,
              }))
            ]}


            value={selectValue} defaultValue={selectValue} onChange={(val) => setSelectValue(val)}
            containerClass={''}
          />

          <SelectInput
            name="Select Filter"
            options={[
              { value: 'Select', label: 'Select' },
              { value: 'Current day', label: 'Today' },
              { value: 'Yesterday', label: 'Yesterday' },
              { value: 'This Week', label: 'This Week' },
              { value: 'Current Month', label: 'Current Month' },
              { value: 'last month', label: 'Previous month' },
              { value: 'Current year', label: 'Current year' },
              { value: 'Previous year', label: 'Previous year' },
              { value: 'Custom', label: 'Custom' },
            ]}
            value={selectFilter}
            onChange={(e) => setSelectFilter(e.target.value)}
            containerClass="w-100"
            required
          />

          {selectFilter === 'Custom' && (
            <>
              <div className=" mt-4 ">
                <input
                  type="date"
                  className="form-control"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div className="mt-4">
                <input
                  type="date"
                  className="form-control"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </>
          )}
        </div>


        <div className='d-flex align-items-center'>
          <ReactToPrint
            trigger={() => (
              <div className='bg-primary  rounded text-center px-2 py-1 text-nowrap'>
                <span className="text-white me-1">Download</span> {Icons.download('')}
              </div>
            )}
            content={() => modalBodyRef.current}
            pageStyle={`@page { size: A5; margin: 0; }`}
          />

        </div>

      </div>


      <div className=""  >
        {
          selectValue &&

          <>

            {filteredDataTop?.map((item, i) => (

              <>
                <h3 class="text-primary fw-bold">#S3A{convertToFiveDigits(item?.associate.serialNo)} - {item?.associate?.name}</h3>
                <p>{item?.associate?.mobileNumber}</p>
                <p>Team Size: {item?.allAssoc?.filter(itm => itm.status == 'Approved').length}</p>
              </>


            ))}

          </>

        }

        {
          selectValue &&
          <div className='card rounded-4 mt-2 overflow-auto shadow p-0 '>

            <div className="table-responsive">
              <table className="table table-borderless table-hover p-0">
                <thead>


                  <tr className='border-bottom'>
                    <th scope="col" className='text-muted'>AssociateId</th>
                    <th scope="col" className='text-muted'>Date</th>
                    <th scope="col" className='text-muted'>Associate Name</th>
                    <th scope="col" className='text-muted'>Mobile No</th>
                    <th scope="col" className='text-muted'>Sponsor</th>
                    <th scope="col" className='text-muted'>Team Size</th>
                  </tr>
                </thead>
                <tbody>
                  {response.data
                    ?.filter((item) => {
                      if (selectValue.length === 0) return true
                      const isProductMatch = selectValue.length > 0 && item?.associate?.id === selectValue;
                      return (isProductMatch);
                    })
                    .map((item, i) =>
                      <>


                        {
                          item?.allAssoc
                            ?.filter((subItem) => subItem?.status === 'Approved')
                            .filter((subItem) => {
                              if (startDate === null || endDate === null || startDate === '' || endDate === '') return true;
                              const startDateObj = new Date(startDate);
                              const endDateObj = new Date(endDate);
                              const subItemDate = new Date(subItem?.createdAt);
                              return (subItemDate >= startDateObj && subItemDate <= endDateObj)
                            })
                            ?.map((subItem, index) =>
                              <>
                                <tr key={index} className='table-danger'>
                                  <th scope="row">S2A{convertToFiveDigits(subItem?.serialNo)}</th>
                                  <th className=''>{subItem?.createdAt && !isNaN(new Date(subItem?.createdAt)) ? new Date(subItem?.createdAt).toLocaleDateString('en-GB').split('/').join('-') : ''}</th>
                                  <th>{subItem?.name}</th>
                                  <th>{subItem?.mobileNumber}</th>
                                  <th scope="row" >{response?.data?.find((item) => item?.associate?.id == subItem?.associateId)?.associate?.name}</th>

                                  <th scope="row" >{subItem?.allAssociate?.filter(itm => itm.status == 'Approved').length}</th>

                                </tr>

                                {
                                  subItem?.allAssociate
                                    ?.filter((subItem2) => subItem2?.status === 'Approved')
                                    ?.filter((subItem2) => {
                                      if (startDate === null || endDate === null || startDate === '' || endDate === '') return true;
                                      const startDateObj = new Date(startDate);
                                      const endDateObj = new Date(endDate);
                                      const subItemDate = new Date(subItem2?.createdAt);
                                      return (subItemDate >= startDateObj && subItemDate <= endDateObj)
                                    })
                                    ?.map((subItem2, index) =>
                                      <>
                                        <tr key={index} className='table-info'>
                                          <th scope="row">S2A{convertToFiveDigits(subItem2?.serialNo)}</th>
                                          <th className=''>{subItem2?.createdAt && !isNaN(new Date(subItem2?.createdAt)) ? new Date(subItem2?.createdAt).toLocaleDateString('en-GB').split('/').join('-') : ''}</th>
                                          <th>{subItem2?.name}</th>
                                          <th>{subItem2?.mobileNumber}</th>
                                          <th scope="row" >{response?.data?.find((item) => item?.associate?.id == subItem2?.associateId)?.associate?.name}</th>

                                          <th scope="row" >{subItem2?.allAssociate?.filter(itm => itm.status == 'Approved').length}</th>

                                        </tr>

                                        {
                                          subItem2?.allAssociate
                                            ?.filter((subItem3) => subItem3?.status === 'Approved')
                                            ?.filter((subItem3) => {
                                              if (startDate === null || endDate === null || startDate === '' || endDate === '') return true;
                                              const startDateObj = new Date(startDate);
                                              const endDateObj = new Date(endDate);
                                              const subItemDate = new Date(subItem3?.createdAt);
                                              return (subItemDate >= startDateObj && subItemDate <= endDateObj)
                                            })

                                            ?.map((subItem3, index) =>
                                              <>
                                                <tr key={index} className='table-success'>
                                                  <th scope="row">S2A{convertToFiveDigits(subItem3?.serialNo)}</th>
                                                  <th className=''>{subItem3?.createdAt && !isNaN(new Date(subItem3?.createdAt)) ? new Date(subItem3?.createdAt).toLocaleDateString('en-GB').split('/').join('-') : ''}</th>
                                                  <th>{subItem3?.name}</th>
                                                  <th>{subItem3?.mobileNumber}</th>
                                                  <th scope="row" >{response?.data?.find((item) => item?.associate?.id == subItem3?.associateId)?.associate?.name}</th>

                                                  <th scope="row" ></th>
                                                  {
                                                    console.log("qqqqqqqqqq", subItem3.allAssociate)
                                                  }
                                                </tr>

                                              </>)
                                        }


                                      </>)
                                }



                              </>)
                        }



                      </>

                    )}


                </tbody>
              </table>
            </div>

          </div>
        }
      </div>


      <div className="d-none mb-5">
        <div className="mt-5 mx-2" ref={modalBodyRef}>
          {
            selectValue &&
            <>
              {filteredDataTop?.map((item, i) => (
                <>
                  <h3 class="text-primary fw-bold">#S3A{convertToFiveDigits(item?.associate.serialNo)} - {item?.associate?.name}</h3>
                  <p>{item?.associate?.mobileNumber}</p>
                  <p>Team Size: {item?.allAssoc?.filter(itm => itm.status == 'Approved').length}</p>
                </>
              ))}

            </>

          }

          {
            selectValue &&
            <div className='card  mt-2 overflow-hidden shadow p-0 '>

              <table className="table table-borderless table-hover p-0" style={{ whiteSpace: 'normal', zoom: '50%' }}>
                <thead>


                  <tr className='border-bottom'>
                    <th scope="col" className='text-muted'>AssociateId</th>
                    <th scope="col" className='text-muted'>Date</th>
                    <th scope="col" className='text-muted'>Associate Name</th>
                    <th scope="col" className='text-muted'>Mobile No</th>
                    <th scope="col" className='text-muted'>Sponsor</th>
                    <th scope="col" className='text-muted'>Team Size</th>
                  </tr>
                </thead>
                <tbody>
                  {response.data
                    ?.filter((item) => {
                      if (selectValue.length === 0) return true
                      const isProductMatch = selectValue.length > 0 && item?.associate?.id === selectValue;
                      return (isProductMatch);
                    })
                    .map((item, i) =>
                      <>
                        {
                          item?.allAssoc
                            ?.filter((subItem) => subItem?.status === 'Approved')
                            .filter((subItem) => {
                              if (startDate === null || endDate === null || startDate === '' || endDate === '' || selectFilter == '') return true;
                              const startDateObj = new Date(startDate);
                              const endDateObj = new Date(endDate);
                              const subItemDate = new Date(subItem?.createdAt);
                              return (subItemDate >= startDateObj && subItemDate <= endDateObj)
                            })
                            ?.map((subItem, index) =>
                              <>
                                <tr key={index} className='table-danger'>
                                  <th scope="row">S2A{convertToFiveDigits(subItem?.serialNo)}</th>
                                  <th className=''>{subItem?.createdAt && !isNaN(new Date(subItem?.createdAt)) ? new Date(subItem?.createdAt).toLocaleDateString('en-GB').split('/').join('-') : ''}</th>
                                  <th>{subItem?.name}</th>
                                  <th>{subItem?.mobileNumber}</th>
                                  <th scope="row" >{response?.data?.find((item) => item?.associate?.id == subItem?.associateId)?.associate?.name}</th>
                                  <th scope="row" >{subItem?.allAssociate?.filter(itm => itm.status == 'Approved').length}</th>
                                </tr>

                                {
                                  subItem?.allAssociate
                                    ?.filter((subItem2) => subItem2?.status === 'Approved')
                                    ?.filter((subItem2) => {
                                      if (startDate === null || endDate === null || startDate === '' || endDate === '' || selectFilter == '') return true;
                                      const startDateObj = new Date(startDate);
                                      const endDateObj = new Date(endDate);
                                      const subItemDate = new Date(subItem2?.createdAt);
                                      return (subItemDate >= startDateObj && subItemDate <= endDateObj)
                                    })
                                    ?.map((subItem2, index) =>
                                      <>
                                        <tr key={index} className='table-info'>
                                          <th scope="row">S2A{convertToFiveDigits(subItem2?.serialNo)}</th>
                                          <th className=''>{subItem2?.createdAt && !isNaN(new Date(subItem2?.createdAt)) ? new Date(subItem2?.createdAt).toLocaleDateString('en-GB').split('/').join('-') : ''}</th>
                                          <th>{subItem2?.name}</th>
                                          <th>{subItem2?.mobileNumber}</th>
                                          <th scope="row" >{response?.data?.find((item) => item?.associate?.id == subItem2?.associateId)?.associate?.name}</th>
                                          <th scope="row" >{subItem2?.allAssociate?.filter(itm => itm.status == 'Approved').length}</th>
                                        </tr>
                                        {
                                          subItem2?.allAssociate
                                            ?.filter((subItem3) => subItem3?.status === 'Approved')
                                            ?.filter((subItem3) => {
                                              if (startDate === null || endDate === null || startDate === '' || endDate === '' || selectFilter == '') return true;
                                              const startDateObj = new Date(startDate);
                                              const endDateObj = new Date(endDate);
                                              const subItemDate = new Date(subItem3?.createdAt);
                                              return (subItemDate >= startDateObj && subItemDate <= endDateObj)
                                            })

                                            ?.map((subItem3, index) =>
                                              <>
                                                <tr key={index} className='table-success'>
                                                  <th scope="row">S2A{convertToFiveDigits(subItem3?.serialNo)}</th>
                                                  <th className=''>{subItem3?.createdAt && !isNaN(new Date(subItem3?.createdAt)) ? new Date(subItem3?.createdAt).toLocaleDateString('en-GB').split('/').join('-') : ''}</th>
                                                  <th>{subItem3?.name}</th>
                                                  <th>{subItem3?.mobileNumber}</th>
                                                  <th scope="row" >{response?.data?.find((item) => item?.associate?.id == subItem3?.associateId)?.associate?.name}</th>

                                                  <th scope="row" ></th>
                                                  {
                                                    console.log("qqqqqqqqqq", subItem3.allAssociate)
                                                  }
                                                </tr>
                                              </>)
                                        }
                                      </>)
                                }
                              </>)
                        }



                      </>

                    )}


                </tbody>
              </table>

            </div>
          }
        </div>
      </div>

    </div>
  )
}

export default UserAssociateReport



