import React from 'react'
import Icons from '../ui/Icons'
import { useGetCategoriesQuery, useUpdateCategoryMutation } from '../../services/categorySlice'
// import { getApiErrorMessage } from '../../utility/errorMessages'
import Input from '../form/Input'
import Loading from '../ui/Loading'

const EditProductCategoryModal = ({ id, refetch }) => {

    const modalId = "editProductCategoryModal" + id

    const [updateCategory, updateCategoryRes] = useUpdateCategoryMutation()
    // const categoryRes = useGetCategoryByIdQuery(id)
    const categoryRes = useGetCategoriesQuery()

    const onUpdate = (e) => {
        e.preventDefault()

        const body = {
            name: e.target['Category Name'].value
        }

        updateCategory({ id, body })
            .then((res) => {
                if (!res.error) {
                    document.getElementById(modalId + 'CloseBtn').click()
                    refetch()
                }
            })
    }

    if (categoryRes.isLoading) return <Loading />
    if (categoryRes.isError) return <div>Something went wrong...</div>

    return (
        <>
            <button type="button" className='rounded-4 bg-success d-flex justify-content-center align-items-center mx-2' style={{ height: 40, width: 40 }} data-bs-toggle="modal" data-bs-target={"#" + modalId}>
                {Icons.edit()}
            </button>

            <div className="modal fade" id={modalId} tabindex="-1" aria-labelledby={modalId + "Label"} aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content">
                        <form className="" onSubmit={onUpdate}>
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id={modalId + "Label"}>Edit Category</h1>
                                <button id={modalId + "CloseBtn"} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <Input name={'Category Name'} defaultValue={categoryRes.data.filter(item => item.id === id)[0].name} />
                            </div>
                            <div className="modal-footer">
                                {updateCategoryRes.isError &&
                                    <div className='me-auto fw-bold text-danger'>
                                        {/* {getApiErrorMessage(updateCategoryRes).label}: {getApiErrorMessage(updateCategoryRes).message} */}
                                    </div>
                                }
                                <button type="button" className="btn btn-success text-white" data-bs-dismiss="modal">Cancel</button>
                                <button
                                    className="btn btn-primary text-white"
                                    type="submit"
                                    res={updateCategoryRes}
                                    loadingLabel={"Updating"}
                                >
                                    Update
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditProductCategoryModal