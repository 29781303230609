import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";


export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getStudents: builder.query({ query: () => `/student`, keepUnusedDataFor: 0 }),
        getStudent: builder.query({ query: (id) => `/student?id=${id}`, keepUnusedDataFor: 0 }),
        postStudent: builder.mutation({ query: (body) => ({ url: `/student/create`, method: 'POST', body }) }),
        updateStudent: builder.mutation({ query: ({ id, body }) => ({ url: `student/update?id=${id}`, method: 'POST', body }) }),

        updateStudentStatus: builder.mutation({ query: ({id,status,reason}) => ({ url: `/student/updateStatus?id=${id}&status=${status}&reason=${reason}`, method: 'POST' }) }),

        createStudentPaymentFromAdmin: builder.mutation({ query: ({id,amount}) => ({ url: `/student/paymentFromAdmin?id=${id}&amount=${amount}`, method: 'POST',  }) }),
    
        updateStudentPaymentMode: builder.mutation({ query: ({ id, body }) => ({ url: `student/updatePaymentMode?id=${id}`, method: 'POST', body }) }),


    }),
})

export const { useGetStudentQuery, useGetStudentsQuery, useUpdateStudentPaymentModeMutation, usePostStudentMutation,useCreateStudentPaymentFromAdminMutation, useUpdateStudentMutation , useUpdateStudentStatusMutation} = extendedApiSlice
