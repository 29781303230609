import React from 'react'
import { useCreatePaymentFromAdminMutation } from '../../services/associateSlice';
import { useGetPackageQuery } from '../../services/adminSlice';
import { useCreateStudentPaymentFromAdminMutation } from '../../services/studentSlice';


const PayModeModal = ({ id, isAssociate, updateStudent, updateStudentResponse, updateAssociate, updateAssociateResponse, response, studentResponse }) => {

    const modalId = "PayModeModal" + id

    const [createPayment, createPaymentRes] = useCreatePaymentFromAdminMutation()
    const [createStudentPayment, createStudentPaymentRes] = useCreateStudentPaymentFromAdminMutation()
   
    const getPackage = useGetPackageQuery(1)


    const handleMode = (e) => {


        console.log("-------check 1")
        e.preventDefault();

        if (isAssociate) {
            console.log("-------check ass 1")

            const body = new FormData()
            body.append("paymentMode", e.target['Mode of Payment'].value);
            body.append("refId", e.target['Ref. Id'].value);
            updateAssociate({ id, body }).then( async(res) => {
                if (!res.error) {
                    const paymentbody = {
                        id: id,
                        amount: getPackage.data.packagePrice
                    }
                    await createPayment(paymentbody);
                    response.refetch()
                }
                
            })

        } else {

            console.log("-------check stt   ")

            const body = {
                paymentMode: e.target['Mode of Payment'].value,
                refId: e.target['Ref. Id'].value,
            }

            updateStudent({ id, body })
                .then(async(res) => {
                    if (!res.error) {
                        const paymentbody = {
                            id: id,
                            amount: res.data.amount
                        }
                        await createStudentPayment(paymentbody);
                        studentResponse.refetch()
                    }
                    
                })
        }

    };



    return (
        <>
            <button type="button" className='btn btn-sm btn-primary rounded-pill px-3 me-3 text-white py-0' data-bs-toggle="modal" data-bs-target={"#" + modalId}>
                Pay Now
            </button>

            <div className="modal fade" id={modalId} tabindex="-1" aria-labelledby={modalId + "Label"} aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content">
                        <form style={{ padding: 20 }} onSubmit={handleMode}> {/* Add onSubmit event handler here */}
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id={modalId + "Label"}>Add Payment</h1>
                                <button id={modalId + "CloseBtn"} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>

                            <div className="modal-body p-0">

                                <div className="d-flex flex-column align-items-start col form-group mb-3">
                                    <label className="fw-bold mb-1 ms-1" htmlFor="Mode of Payment">Mode of Payment</label>
                                    <select className="form-select rounded-pill px-3 border-primary border-2" id="Mode of Payment" name="Mode of Payment">
                                        <option value="Cash">
                                            Cash
                                        </option>
                                        <option value="UPI">
                                            UPI
                                        </option>
                                        <option value="NEFT">
                                            NEFT
                                        </option>
                                        <option value="IMPS">
                                            IMPS
                                        </option>
                                        <option value="Cheque">
                                            Cheque
                                        </option>
                                        <option value="Card">
                                            Card
                                        </option>
                                    </select>
                                </div>
                                <div className="d-flex flex-column align-items-start col">
                                    <label className="fw-bold mb-1 ms-1" htmlFor="Ref. Id">Ref. Id</label>
                                    <input className="form-control rounded-pill px-3 border-primary border-2" data-ms-editor="true" id="Ref. Id" name="Ref. Id" placeholder="Ref. Id" spellCheck="false" type="number" />
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button className='btn btn-primary' type="submit">Submit</button> {/* Add type="submit" to trigger form submission */}
                            </div>


                </form>
                    </div>
            </div >
        </div >
        </>
    )
}

export default PayModeModal