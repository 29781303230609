
import React, { useEffect, useState } from 'react';
import { useDeleteDesignationMutation, useGetDesignationsQuery, useMakeDefaultMutation } from '../../services/designationSlice';
import { Link, useNavigate } from 'react-router-dom';
import Loading from '../../components/ui/Loading';
import SuccessModal from '../../components/modals/SuccessModal';
import ApiErrorModal from '../../components/modals/ApiErrorModal';
import InputSearch from '../../components/form/InputSearch';
import OfferTemplateV2 from './OfferTemplateV2';

const Designations = () => {
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState('');

    const designationData = useGetDesignationsQuery();
    const [deleteDesignation, deleteDesignationResponse] = useDeleteDesignationMutation();
    const [makeDefault, makeDefaultRes] = useMakeDefaultMutation();

    const handleDelete = (id) => {
        const shouldDelete = window.confirm('Are you sure you want to delete this Designation?');

        if (shouldDelete) {
            deleteDesignation(id).then(() => designationData.refetch());
        }
    };

    const handleDefault =(id)=>{
        const shouldDelete = window.confirm('Are you sure you want to make  this Designation Default');

        if (shouldDelete) {
        
        makeDefault(id).then(() => designationData.refetch());
        }
    }

    useEffect(() => {
        designationData.refetch();
    }, []);

    if (designationData.isLoading) return <Loading />;

    return (
        <div className='p-3 p-lg-5'>
            {deleteDesignationResponse.isSuccess && <SuccessModal message={"Designation Deleted Successfully."} />}
            {deleteDesignationResponse.isError && <ApiErrorModal response={deleteDesignationResponse} />}

            
            {makeDefaultRes.isSuccess && <SuccessModal message={"Designation Set As Default."} />}
            {makeDefaultRes.isError && <ApiErrorModal response={makeDefaultRes} />}

            <h4 className='text-primary fw-bold mb-3'>Designations</h4>

            <div className="container">
                <div className="row row-cols-1 row-cols-lg-3 g-3 mb-3 justify-content-end">
                    <div className="col"><InputSearch name={"Search Designations"} containerClass={"w-100"} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} /></div>
                    <div className="col"><Link className='btn btn-primary rounded-pill w-100' to={"/create/designation"}>Create Designation</Link></div>
                </div>
            </div>

            <div className='card rounded-4 overflow-hidden shadow'>
                <div className="table-responsive">
                    <table className="table table-borderless table-hover">
                        <thead>
                            <tr className='border-bottom'>
                                <th scope="col" className='text-muted'>Designation ID</th>
                                <th scope="col" className='text-muted'>Name</th>
                                <th scope="col" className='text-muted'>Leader</th>
                                <th scope="col" className='text-muted'>Color</th>
                                <th scope="col" className='text-muted'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {designationData.data
                                ?.filter((item) => {
                                    if (searchValue?.length === 0) return true;

                                    const isSearchMatch = searchValue?.length > 0 && (
                                        item?.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                                        item.id.toString().includes(searchValue.toLowerCase())
                                    );

                                    return isSearchMatch;
                                })
                                ?.reverse()?.map(item =>
                                    <tr key={item.id}>
                                        <th scope="row">{item.id}</th>
                                        <th>{item.name}</th>
                                        <th>#- {(item?.parentDesignationName && item.designationId) ? (designationData?.data?.find((itm)=>itm.id==item.designationId)?.name + "  " + item?.designationId) : 'No Leader'}</th>
                                        <th><div className='' style={{ width: '20px', height: '20px', backgroundColor: item.color }}></div></th>
                                        <th className='d-flex gap-2'>
                                            {/* <OfferTemplateV2
                                                isCreate={!item.offerTemplate}
                                                id={item.offerTemplate ? item.offerTemplate.id : 1}
                                                refetch={designationData.refetch}
                                                designationId={item.id}
                                                offer={item.offerTemplate}
                                            /> */}
                                            {
                                                console.log(item.id)
                                            }
                                            <div className='btn btn-sm btn-success rounded-pill px-3 me-3 text-white ms-2 py-0' onClick={() => navigate(`/edit/designation/${item.id}`)}>Edit</div>
                                            {item.isDefault == 0 ? <div className='btn btn-sm btn-danger rounded-pill px-3 text-white py-0' onClick={() => handleDelete(item.id)}>Delete</div> :"SET AS DEFAULT"}
                                            {item.isDefault == 0 && <div className='btn btn-sm btn-primary rounded-pill px-3 text-white py-0' 
                                            onClick={() => handleDefault(item.id)}
                                            
                                            
                                            
                                            >Make Default</div>}
                                        </th>
                                    </tr>
                                )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Designations;
