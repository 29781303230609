import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        signIn: builder.mutation({ query: (body) => ({ url: `/admin/login`, method: 'POST', body }) }),
        signOut: builder.mutation({ query: (body) => ({ url: `/admin/signOut`, method: 'POST', body }) }),
    })
})

export const { useSignInMutation, useSignOutMutation } = extendedApiSlice
