import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Input from '../../components/form/Input'
import Icons from '../../components/ui/Icons'
import SelectInput from '../../components/form/SelectInput'
import { useGetProductsQuery } from '../../services/productSlice'
import Loading from '../../components/ui/Loading'
import { useGetStudentsQuery } from '../../services/studentSlice'
import ReactToPrint from 'react-to-print';
import { useGetAssociatesQuery } from '../../services/associateSlice'
import InputSearch from '../../components/form/InputSearch'

const NetSalesReport = () => {

  const [selectValue, setSelectValue] = useState("")
  const [searchValue, setSearchValue] = useState("")
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [searchYear, setSearchYear] = useState("")


  const modalBodyRef = useRef();

  const response = useGetAssociatesQuery();
  const studentResponse = useGetStudentsQuery();

  const courseRes = useGetProductsQuery()

  function convertToFiveDigits(number) {

    return String(number).padStart(5, '0');
  }


  useEffect(() => {
    let filterStartDate;
    let filterEndDate;

    if (selectValue === 'last 7 days') {
      filterEndDate = new Date();
      filterStartDate = new Date(new Date().setDate(new Date().getDate() - 6));
    } else if (selectValue === 'last month') {
      filterEndDate = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
      filterStartDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
    }
    else if (selectValue === 'Previous year') {
      filterStartDate = new Date(new Date().getFullYear() - 1, 3, 1); // 1st April of previous year
      filterEndDate = new Date(new Date().getFullYear(), 2, 31); // 31st March of current year
    } else if (selectValue === 'Current year') {
      filterStartDate = new Date(new Date().getFullYear(), 3, 1); // 1st April of current year
      filterEndDate = new Date(new Date().getFullYear() + 1, 2, 31); // 31st March of next year
    }
    else if (selectValue === 'Current day') {
      filterEndDate = new Date();
      filterStartDate = new Date();
      filterStartDate.setHours(0, 0, 0, 0);
      filterEndDate.setHours(23, 59, 59, 999);
    } else if (selectValue === 'Yesterday') {
      filterEndDate = new Date(new Date().setDate(new Date().getDate() - 1));
      filterStartDate = new Date(new Date().setDate(new Date().getDate() - 1));
      filterStartDate.setHours(0, 0, 0, 0);
      filterEndDate.setHours(23, 59, 59, 999);
    } else if (selectValue === 'This Week') {
      const today = new Date();
      const dayOfWeek = today.getDay();
      filterStartDate = new Date(today.setDate(today.getDate() - dayOfWeek));
      filterEndDate = new Date();
      filterStartDate.setHours(0, 0, 0, 0);
      filterEndDate.setHours(23, 59, 59, 999);
    } else if (selectValue === 'Current Month') {
      const today = new Date();
      filterStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
      filterEndDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      filterStartDate.setHours(0, 0, 0, 0);
      filterEndDate.setHours(23, 59, 59, 999);
    }
    else if (selectValue === 'Custom' && startDate && endDate) {
      filterStartDate = new Date(startDate);
      filterEndDate = new Date(endDate);
    }
    setStartDate(filterStartDate)
    setEndDate(filterEndDate)
  }, [response.isSuccess, studentResponse.isSuccess, courseRes.isSuccess, selectValue]);





  if (courseRes.isLoading || response.isLoading || studentResponse.isLoading) return <Loading />

  const mergedData = [...response.data, ...studentResponse.data];
  mergedData.sort((a, b) => (a.orders[0]?.id || 0) - (b.orders[0]?.id || 0));



  const filteredData = mergedData
    .filter((item) => {
      if (searchValue?.length === 0) return true;
      const isSearchMatch = searchValue?.length > 0 && (
        `SU${convertToFiveDigits(item?.orders[0]?.id)}`.toLowerCase().includes(searchValue.toLowerCase()) ||
        `SU${convertToFiveDigits(item?.orders[0]?.id)}`.toLowerCase().includes(searchValue.toLowerCase())
      );

      return isSearchMatch;
    })
    .filter((item) => {
      return item?.associate?.paid == '1' || item?.student?.paid == '1'
    })
    .filter((item) => {
      if (startDate === null || endDate === null || startDate === '' || endDate === '' || selectValue == '') return true;
      const studentDate = new Date(item?.student?.createdAt);
      const associateDate = new Date(item?.associate?.createdAt);
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);
      return (studentDate >= startDateObj && studentDate <= endDateObj) ||
        (associateDate >= startDateObj && associateDate <= endDateObj);
    });


  const sortedData = [...filteredData].sort((a, b) => {
    const dateA = a?.associate?.createdAt || a?.student?.createdAt || '';
    const dateB = b?.associate?.createdAt || b?.student?.createdAt || '';
    return new Date(dateB) - new Date(dateA);
  });





  const { tmStudent, tmAssociate } = filteredData.reduce((totals, item) => {
    const studentAmount = parseFloat(item?.course?.amount) || 0;
    const associateAmount = parseFloat(item?.payment?.amount) || 0;
    return {
      tmStudent: totals.tmStudent + studentAmount,
      tmAssociate: totals.tmAssociate + associateAmount,
    };
  }, { tmStudent: 0, tmAssociate: 0 });

  // Calculate total records
  const totalRecords = filteredData.length;




  return (
    <div className='p-5'>

      <h4 className='text-primary fw-bold mb-3'>Report</h4>
      <div className='float-end'>


        <ReactToPrint
          trigger={() => (
            <div className='bg-primary  rounded text-center px-2 py-1 text-nowrap'>
              <span className="text-white me-1">Download</span> {Icons.download('')}
            </div>
          )}
          content={() => modalBodyRef.current}
          pageStyle={`@page { size: A5; margin: 0; }`}
        />

      </div>
      <div className="row mb-5">

        <div className={selectValue === 'Custom' ? "col-4" : "col-3 col-lg-4"}>
          <SelectInput
            name="Select Status"
            noLabel
            options={[
              { value: '', label: 'Select' },
              { value: 'Current day', label: 'Today' },
              { value: 'Yesterday', label: 'Yesterday' },
              { value: 'This Week', label: 'This Week' },
              { value: 'Current Month', label: 'Current Month' },
              { value: 'last month', label: 'Previous month' },
              { value: 'Current year', label: 'Current year' },
              { value: 'Previous year', label: 'Previous year' },
              { value: 'Custom', label: 'Custom' },
            ]}
            value={selectValue}
            onChange={(e) => setSelectValue(e.target.value)}
            containerClass="w-100"
            required
          />
        </div>

        {selectValue === 'Custom' && (
          <>
            <div className="col-3 col-lg-3">
              <input
                type="date"
                className="form-control mb-3"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="col-3 col-lg-3">
              <input
                type="date"
                className="form-control mb-3"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </>
        )}

      </div>

      <div className='d-flex justify-content-between mb-3'>

        <div className='bg-primary text-white rounded-3 p-3 me-3'>{Icons.report()}</div>
        <div>
          <p>Total Sales</p>
          <p className='text-primary'>₹{tmStudent + tmAssociate}</p>
        </div>

        <div className='ms-2'>
          <p>Total Invoice</p>
          <p className='text-primary'>{totalRecords}</p>
        </div>

        <div className='flex-grow-1'></div>

        <InputSearch name={"Search"} containerClass={"w-100"} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
      </div>

      <div className='card rounded-4 overflow-hidden shadow ' >

        <div className="table-responsive">
          <table className="table table-borderless table-hover" ref={modalBodyRef}>
            <thead>
              {/* Order ID, Date, Name, Mobile App ID, Product Amount */}
              <tr className='border-bottom'>
                <th scope="col" className='text-muted'>Order ID</th>
                <th scope="col" className='text-muted'>Date</th>
                <th scope="col" className='text-muted'>Name</th>
                <th scope="col" className='text-muted'>Designation</th>
                <th scope="col" className='text-muted'>Product</th>
                <th scope="col" className='text-muted'>Payment Mode</th>
                {/* <th scope="col" className='text-muted'>Payment</th> */}
                <th scope="col" className='text-muted'>Amount</th>
              </tr>
            </thead>
            <tbody>
              {sortedData
                ?.map((item, i) =>
                  <tr key={i}>
                    <th scope='row'>{item.orders[0]?.id && `SU${convertToFiveDigits(item.orders[0]?.id)}`}</th>
                    <th>
                      {item?.associate?.createdAt
                        ? new Date(item.associate.createdAt).toLocaleDateString('en-GB', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                        }).replace(/\//g, '-')
                        : new Date(item.student.createdAt).toLocaleDateString('en-GB', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                        }).replace(/\//g, '-')}
                    </th>

                    <th>{item?.associate?.name || item?.student?.studentName}</th>
                    <th>{item?.designation?.name || "Student"}</th>
                    <th>{item?.associate ? "Associate" : item?.category?.name + '-' + item?.course?.name}</th>
                    <th>{item?.associate?.paymentMode || item?.student?.modeOfPayment}</th>
                    <th className=''>₹{item?.associate ? item?.payment?.amount : item?.course?.amount}</th>

                  </tr>
                )}


            </tbody>
          </table>
        </div>

      </div>

    </div>
  )
}

export default NetSalesReport