import React from 'react'
import { Link ,useNavigate} from 'react-router-dom'
import Icons from '../../components/ui/Icons'

const Reports = () => {

  const navigate = useNavigate()

  return (
    <div className='p-5'>

      <h4 className='text-primary fw-bold mb-5'>Reports</h4>

      <div className="row g-5">
        <div className="col-md-6 col-lg-4 mb-4  d-flex flex-column">
          <div className='bg-primary rounded-3 p-2 mb-3 text-center' style={{width:50}}>{Icons.report('text-white')}</div>
          <Link to={`/report/user/net-sales`} className="d-block text-start">Net Sales Report</Link>
          <Link to={`/report/product`} className="d-block text-start">Product Sales Report</Link>
          <Link to={`/report/user/sales`} className="d-block text-start">Associate Sales Report</Link>
        </div>

        <div className="col-md-6 col-lg-4 mb-4">
          <div className='bg-primary rounded-3 p-2 mb-3 text-center' style={{width:50}}>{Icons.report('text-white')}</div>
          <Link to={`/report/designation`} className="d-block text-start">Associate Report by Designation</Link>
          <Link to={`/report/user`} className="d-block text-start">Team Report of an Associate</Link>
        </div>
      </div>

    </div>
  )
}

export default Reports
