import React, { useEffect, useState } from 'react'
import Input from '../../components/form/Input'
import { useGetAssociateIdQuery, useGetAssociatesQuery, usePostAssociateMutation, useUpdateAssociateMutation } from '../../services/associateSlice'
import { useGetDesignationsQuery } from '../../services/designationSlice'
import ApiErrorModal from '../../components/modals/ApiErrorModal'
import SuccessModal from '../../components/modals/SuccessModal'
import FileInput from '../../components/form/FileInput'
import SectionHeader from '../../components/form/SectionHeader'
import Section from '../../components/form/Section'
import SectionBody from '../../components/form/SectionBody'
import { useNavigate, useParams } from 'react-router-dom'
import Loading from '../../components/ui/Loading'
import SelectInput from '../../components/form/SelectInput'
import SearchSelectInput from '../../components/form/SearchSelectInput'

const GENDER = {
    MALE: "Male",
    FEMALE: "Female",
    OTHERS: "Others",
}

const QUALIFICATION = {
    PASSED_TEN: "10th Pass",
    PASSED_PLUS_TWO: "+2 Pass",
    PASSED_PLUS_THREE: "+3 Pass",
}

const RANK = {
    ZONAL_MANAGER: "Zonal Manager",
    GENERAL_MANAGER: "General Manager",
    COMMERCIALS_MANAGER: "Commercials Manager",
    SURVEY_MANAGER: "Survey Manager",
    SURVEY_EXECUTIVE: "Survey Executive",
    SURVEY_REPORTER: "Survey Reporter",
}

const stateOptions = [
    { label: "Andhra Pradesh", value: "Andhra Pradesh" },
    { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
    { label: "Assam", value: "Assam" },
    { label: "Bihar", value: "Bihar" },
    { label: "Chhattisgarh", value: "Chhattisgarh" },
    { label: "Goa", value: "Goa" },
    { label: "Gujarat", value: "Gujarat" },
    { label: "Haryana", value: "Haryana" },
    { label: "Himachal Pradesh", value: "Himachal Pradesh" },
    { label: "Jharkhand", value: "Jharkhand" },
    { label: "Karnataka", value: "Karnataka" },
    { label: "Kerala", value: "Kerala" },
    { label: "Madhya Pradesh", value: "Madhya Pradesh" },
    { label: "Maharashtra", value: "Maharashtra" },
    { label: "Manipur", value: "Manipur" },
    { label: "Meghalaya", value: "Meghalaya" },
    { label: "Mizoram", value: "Mizoram" },
    { label: "Nagaland", value: "Nagaland" },
    { label: "Odisha", value: "Odisha" },
    { label: "Punjab", value: "Punjab" },
    { label: "Rajasthan", value: "Rajasthan" },
    { label: "Sikkim", value: "Sikkim" },
    { label: "Tamil Nadu", value: "Tamil Nadu" },
    { label: "Telangana", value: "Telangana" },
    { label: "Tripura", value: "Tripura" },
    { label: "Uttar Pradesh", value: "Uttar Pradesh" },
    { label: "Uttarakhand", value: "Uttarakhand" },
    { label: "West Bengal", value: "West Bengal" },
    { label: "Andaman and Nicobar Islands", value: "Andaman and Nicobar Islands" },
    { label: "Chandigarh", value: "Chandigarh" },
    { label: "Dadra and Nagar Haveli and Daman and Diu", value: "Dadra and Nagar Haveli and Daman and Diu" },
    { label: "Lakshadweep", value: "Lakshadweep" },
    { label: "Delhi", value: "Delhi" },
    { label: "Puducherry", value: "Puducherry" },
]

const EditCreateAssociate = () => {

    const { id } = useParams();
    const response = useGetAssociateIdQuery(id)
    const navigate = useNavigate()

    const [updateAssociate, updateAssociateResponse] = useUpdateAssociateMutation();
    const getDesignations = useGetDesignationsQuery()
    const getAssociates = useGetAssociatesQuery()

    const [gender, setGender] = useState(GENDER.MALE);
    const [rank, setRank] = useState("");

    const [ranks, setRanks] = useState([]);

    const [sponsor, setSponsor] = useState("");
    const [qualification, setQualification] = useState(QUALIFICATION.PASSED_TEN);

    const activeClassName = 'btn btn-primary rounded-pill px-4 py-0 me-2 mb-2 lh-sm';
    const inactiveClassName = 'btn btn-outline-primary rounded-pill px-4 py-0 me-2 mb-2 lh-sm';

    const btnClassName = "btn btn-success rounded-pill btn-lg px-5 text-white"

    const renderGender = (selected, current) => <div className={selected === current ? activeClassName : inactiveClassName} onClick={() => setGender(current)}>{current}</div>
    const renderQualification = (selected, current) => <div className={selected === current ? activeClassName : inactiveClassName} onClick={() => setQualification(current)}>{current}</div>



    const renderRank = (selectedId, current) => (
        <div className="col">
            <div
                className={
                    selectedId === current?.id ? activeClassName : inactiveClassName
                }
                onClick={() => setRank(current?.id)}
            >
                {current?.name}
            </div>
        </div>
    );

    const options = [
        { value: '1', label: 'Active' },
        { value: '0', label: 'Inactive' },
    ]




    const handleSubmit = (e) => {

        const shouldUpdate = window.confirm('Are you sure you want to Upate this associate?');
        if (shouldUpdate) {

            e.preventDefault();
            const form = e.target
            const aadharFrontFileInput = form["Aadhar Front"];
            const aadharBackFileInput = form["Aadhar Back"];
            const passPhotoFileInput = form["Passphoto"];

            const imageCondition = aadharFrontFileInput?.files?.length > 0 && aadharBackFileInput?.files?.length > 0 && passPhotoFileInput?.files?.length > 0

            if (rank === undefined || rank === null || rank === '') {
                alert("Rank Is Required."); 
                return; 
            }

            
            if (form.checkValidity()) {

                const body = new FormData()
                body.append("name", form["Associate Name"].value)
                body.append("mobileNumber", form["Mobile Number"].value)
                body.append("fatherName", form["Father’s Name"].value)
                body.append("dob", form["Date of Birth"].value)
                body.append("alternateMobileNumber", form["Alternate Mobile Number"].value)
                body.append("aadharNumber", form["Aadhaar Number"].value)
                body.append("gender", gender)
                body.append("qualification", qualification)
                body.append("otherQualification", form["Other Qualification"].value)
                body.append("designationId", rank)
                body.append("associateId", sponsor)
                body.append("personalStudentId", form["Personal Student ID"].value)
                body.append("registrationDate", form["Registration Date"].value)
                // body.append("status", form["Status"].value)
                body.append("isActive", form["Status"].value)

                body.append("address[flatNumber]", form["Plot/Flat Number or Budling Name"].value);
                body.append("address[addressLine1]", form["Address Line 1"].value);
                body.append("address[landmark]", form["Landmark"].value);
                body.append("address[state]", form["State"].value);
                body.append("address[district]", form["Dist"].value);
                body.append("address[cityVillage]", form["City/Village"].value);
                body.append("address[pin]", form["Pin"].value);

                // body.append("aadharFront", aadharFrontFileInput.files[0]);
                // body.append("aadharBack", aadharBackFileInput.files[0]);
                // body.append("passPhoto", passPhotoFileInput.files[0]);

                updateAssociate({ id, body }).then((res) => {
                    if (!res.error) {
                        getAssociates.refetch()
                        response.refetch()
                        getDesignations.refetch()
                        navigate(-1)
                    }
                })
            }
            else {
                form.classList.add('was-validated')
            }


        }


    }

    // const computeRanks = (designationId) => {
    //     let arr = [];
    //     const children = getDesignations?.data?.filter((item) => item.designationId == designationId);
    //     if (children.length === 0) {
    //         return arr; // Return an empty array if there are no children
    //     }
    //     arr = [...arr, ...children];
    //     for (let index = 0; index < children.length; index++) {
    //         const child = children[index];
    //         arr = [...arr, ...computeRanks(child.id)]; // Recursively call computeRanks for each child
    //     }
    //     return arr;
    // };


    // const computeRanks = (designationId) => {
    //     let arr = [];
    //     const children = getDesignations?.data?.filter((item) => item.parentId === designationId); // Assuming you have a parentId field to represent the hierarchy
    //     for (let i = 0; i < children.length; i++) {
    //         arr.push(children[i]);
    //         arr = [...arr, ...computeRanks(children[i].id)]; // Recursively call computeRanks for each child
    //     }
    //     return arr;
    // };


    useEffect(() => {
        const computeRanks = (designationId, processedDesignations = new Set()) => {
            let arr = [];
            const designation = getDesignations?.data?.find((item) => item.id == designationId);
            const parentDesignationId = designation?.designationId;

            if (!processedDesignations.has(designationId)) {
                arr.push(designation);
                processedDesignations.add(designationId);
            }

            // Add siblings of the current designation
            const siblings = getDesignations?.data?.filter((item) => item.designationId == parentDesignationId && item.id != designationId && !processedDesignations.has(item.id));
            siblings.forEach((sibling) => {
                if (!processedDesignations.has(sibling.id)) {
                    arr.push(sibling);
                    processedDesignations.add(sibling.id);
                }
            });

            // Add children of the current designation
            const children = getDesignations?.data?.filter((item) => item.designationId == designationId && !processedDesignations.has(item.id));
            children.forEach((child) => {
                if (!processedDesignations.has(child.id)) {
                    arr.push(child);
                    processedDesignations.add(child.id);
                    arr = [...arr, ...computeRanks(child.id, processedDesignations)];
                }
            });

            return arr;
        };

        if (sponsor.length > 0 && getDesignations.isSuccess) {
            setRanks(computeRanks(getAssociates?.data?.find((item) => item.associate.id === sponsor)?.designation?.id));
        } else {
            if (getDesignations.isSuccess) setRanks(getDesignations.data);
        }
    }, [sponsor, getDesignations.isSuccess]);




    useEffect(() => {
        if (response.data) {
            setGender(response?.data?.associate?.gender)
            setRank(response?.data?.designation?.id)
            if (response?.data?.sponsor?.id) setSponsor(response?.data?.sponsor?.id)
        }
    }, [response.data])

    // useEffect(() => {
    //     if (sponsor.length > 0 && getDesignations.isSuccess) {
    //         setRanks(computeRanks(getAssociates?.data?.find((item) => item.associate.id === sponsor)?.designation?.id))
    //     }
    //     else {
    //         if (getDesignations.isSuccess) setRanks(getDesignations.data)
    //     }
    // }, [sponsor, getDesignations.isSuccess])

    if (response.isLoading) return <Loading />
    if (response.isError) return <ApiErrorModal response={response} />

    if (getAssociates.isLoading || getDesignations.isLoading) return <Loading />
    if (getAssociates.isError || getDesignations.isError) return <ApiErrorModal />



    const currentassoc = getAssociates?.data?.find((item) => item?.associate?.id == id);

    const sponsorOptions = [
        ...[{ label: "No Sponsor", value: "" }],
        ...getAssociates.data?.filter(item => item?.associate?.status == 'Approved')?.map((item) => ({
            label: item?.associate?.serialNo + " " + item?.associate?.name + " - " + item?.designation?.name, value: item?.associate?.id,
            serialNo: item?.associate?.serialNo,
        }))
    ]

    return (
        <div className='container p-3 p-lg-5'>

            {updateAssociateResponse.isSuccess && <SuccessModal message={"Associate has been updated."} />}
            {updateAssociateResponse.isError && <ApiErrorModal response={updateAssociateResponse} />}

            <h4 className='fw-bold mb-3'>Edit Associate</h4>


            <form onSubmit={handleSubmit} noValidate>

                <Section title="Personal Details" className="row-cols-1 row-cols-lg-3 g-3 mb-3" >
                    <Input name={"Associate Name"} defaultValue={response?.data?.associate?.name} required />
                    <Input name={"Mobile Number"} type="tel" minLength={10} maxLength={10} defaultValue={response?.data?.associate?.mobileNumber} required />
                    <Input name={"Father’s Name"} defaultValue={response?.data?.associate?.fatherName} required />
                    <Input name={"Date of Birth"} defaultValue={response?.data?.associate?.dob} required />
                    <Input name={"Alternate Mobile Number"} type="tel" minLength={10} maxLength={10} defaultValue={response?.data?.associate?.alternateMobileNumber} />
                    <Input name={"Aadhaar Number"} type="tel" minLength={12} maxLength={12} defaultValue={response?.data?.associate?.aadharNumber} required />
                    <Input containerClass="me-3" name={"Other Qualification"} defaultValue={response?.data?.associate?.otherQualification} />
                </Section>

                <SectionBody className="row-cols-1 row-cols-lg-2 g-3 mb-5">
                    <div className='col'>
                        <div className="d-flex">
                            <div className='d-flex'>
                                {renderGender(gender, GENDER.MALE)}
                                {renderGender(gender, GENDER.FEMALE)}
                                {renderGender(gender, GENDER.OTHERS)}
                            </div>
                        </div>
                    </div>
                    <div className='col'>
                        <div className="d-flex">
                            <h6 className='text-primary fw-bold me-3'>Qualification:</h6>
                            <div className='d-flex'>
                                {renderQualification(qualification, QUALIFICATION.PASSED_TEN)}
                                {renderQualification(qualification, QUALIFICATION.PASSED_PLUS_TWO)}
                                {renderQualification(qualification, QUALIFICATION.PASSED_PLUS_THREE)}
                            </div>
                        </div>
                    </div>
                </SectionBody>

                <Section title="Address" className="row-cols-1 row-cols-lg-3 g-3 mb-5" >
                    <Input name={"Plot/Flat Number or Budling Name"} defaultValue={response?.data?.address?.flatNumber} required />
                    <Input name={"Address Line 1"} defaultValue={response?.data?.address?.addressLine1} />
                    <Input name={"Landmark"} defaultValue={response?.data?.address?.landmark} />

                    <SelectInput name={"State"} options={stateOptions} defaultValue={response?.data?.address?.state} required />
                    <Input name={"Dist"} defaultValue={response?.data?.address?.district} required />
                    <Input name={"City/Village"} defaultValue={response?.data?.address?.cityVillage} required />
                    <Input containerClass="col" name={"Pin"} type="tel" minLength={6} maxLength={6} defaultValue={response?.data?.address?.pin} required />
                    {/* <FileInput name={"Aadhar Front"} required />
                    <FileInput name={"Aadhar Back"} required /> */}
                </Section>

                <Section title="Rank & Sponsor" className="row-cols-2 row-cols-lg-6 g-3 mb-3" >
                    {getDesignations?.data?.map((item) => renderRank(rank, item))}
                </Section>
                {/* <Section title="Rank & Sponsor" className="row-cols-2 row-cols-lg-6 g-3 mb-3" >
                    {ranks.map((item) => renderRank(rank, item))}
                </Section> */}
                <SectionBody className="row-cols-1 row-cols-lg-3 g-3 mb-5">
                    {/* <Input name={"Sponsor’s Associate ID"} defaultValue={response?.data?.sponsor?.id} required /> */}
                    {/* <SelectInput name={"Sponsor’s Associate ID"} options={sponsorOptions} value={sponsor} onChange={(e) => setSponsor(e.target.value)} /> */}

                    <SearchSelectInput
                        name={"Sponsor’s Associate ID"}
                        options={sponsorOptions}
                        value={sponsor} defaultValue={sponsor} onChange={(val) => setSponsor(val)}
                        containerClass={''}
                        width={400}

                    />


                    <Input name={"Personal Student ID"} defaultValue={response?.data?.associate?.personalStudentId} />
                    <Input name={"Registration Date"} type={'date'} defaultValue={response?.data?.associate?.registrationDate} />

                    <SelectInput
                        name={"Status"}
                        options={options}
                        defaultValue={response?.data?.associate?.isActive}

                    />
                    {
                        console.log("eeeeeeew", response?.data?.associate?.isActive)
                    }
                </SectionBody>


                {
                    updateAssociateResponse.isLoading ?
                        <button class={btnClassName} type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Updating...
                        </button>
                        :
                        <button type='submit' className={btnClassName}>Update</button>
                }




            </form>


        </div>
    )
}

export default EditCreateAssociate