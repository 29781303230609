

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useGetStudentsQuery } from '../../services/studentSlice';
import ApiErrorModal from '../../components/modals/ApiErrorModal';
import Loading from '../../components/ui/Loading';
import InputSearch from '../../components/form/InputSearch';
import { convertToFiveDigits } from '../../utils/ConvertToFive';
import SelectInput from '../../components/form/SelectInput';

const Students = () => {
  const [searchValue, setSearchValue] = useState('');
  const [selectValue, setSelectValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 100; 

  const response = useGetStudentsQuery();

  if (response.isLoading) return <Loading />;
  if (response.isError) return <ApiErrorModal response={response} />;

  const filteredData = response.data
    .filter((item) => (item.student.status == 'Approved' || item.student.status == 'Rejected'))
    .filter((item) => {
      let isMatch = true;

      if (selectValue.length > 0) {
        switch (selectValue) {
          case 'Active':
            isMatch = item.student.status == 'Approved';
            break;
          case 'InActive':
            isMatch = item.student.status == 'Rejected' || item.student.status == 'Pending';
            break;
          default:
            isMatch = true;
        }
      }

      if (isMatch && searchValue.length > 0) {
        isMatch =
          item?.student?.studentName.toLowerCase().includes(searchValue.toLowerCase()) ||
          `S2S${convertToFiveDigits(item?.student?.serialNo)}`.toLowerCase().includes(searchValue.toLowerCase()) ||
          item?.course?.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          item?.student?.mobileNumber.toLowerCase().includes(searchValue.toLowerCase()) ||
          item?.student?.appStudentId.toLowerCase().includes(searchValue.toLowerCase()) ||
          item?.student?.status.toLowerCase().includes(searchValue.toLowerCase()) ||
          item?.sponsor?.name.toLowerCase().includes(searchValue.toLowerCase());
      }

      return isMatch;
    });


    const sortedData = [...filteredData].sort((a, b) => filteredData.indexOf(b) - filteredData.indexOf(a));


  // Calculate pagination values
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;    
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);


  return (
    <div className='p-3 p-lg-5'>
      <h4 className='text-primary fw-bold mb-3'>Student Management</h4>
      <div className="container">
        <div className="row row-cols-1 row-cols-lg-3 g-3 mb-3 justify-content-end">
          <div className="col">
            <SelectInput
              name={"Select Status"}
              noLabel
              options={[
                { value: '', label: 'Select' },
                { value: 'Active', label: 'Active' },
                { value: 'InActive', label: 'InActive' },
              ]}
              value={selectValue}
              onChange={(e) => setSelectValue(e.target.value)}
              containerClass={'w-75'}
              required
            />
          </div>
          <div className="col">
            <InputSearch
              name={"Search Students"}
              containerClass={"w-100"}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
          <div className="col">
            <Link className='btn btn-primary rounded-pill w-100' to={"/create/student"}>Create Students</Link>
          </div>
        </div>
      </div>
      <div className='card rounded-4 overflow-hidden shadow mb-3 '>
        <div className="table-responsive">
          <table className="table table-borderless table-hover">
            <thead>
              <tr className='border-bottom'>
                <th scope="col" className='text-muted'>Student ID</th>
                <th scope="col" className='text-muted'>Name</th>
                <th scope="col" className='text-muted'>Product</th>
                <th scope="col" className='text-muted'>Mobile No.</th>
                <th scope="col" className='text-muted'>Sponsor</th>
                <th scope="col" className='text-muted'>App Stu. Id</th>
                <th scope="col" className='text-muted'>Status</th>
                <th scope="col" className='text-muted'>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map(item => (
                <tr key={item?.student?.id}>
                  <th scope="row">{item?.student?.serialNo && `S2S${convertToFiveDigits(item?.student?.serialNo)}`}</th>
                  <th>{item?.student?.studentName}</th>
                  <th>{item?.course?.name}</th>
                  <th>{item?.student?.mobileNumber}</th>
                  <th><u className='text-primary'>{item.sponsor?.name}</u></th>
                  <th className='text-success'>{item?.student?.appStudentId}</th>
                  <th className={item?.student?.isActive == '1' ? 'text-success' : 'text-danger'}>
                    {item?.student?.isActive == '1' ? 'ACTIVE' : 'InActive'}
                  </th>
                  <th className='d-flex'>
                    <Link className='btn btn-sm btn-primary rounded-pill px-3 me-3 py-0' to={`/student/${item?.student?.id}`}>Details</Link>
                    <Link className='btn btn-sm btn-success rounded-pill px-3 text-white py-0' to={`/edit/student/${item?.student?.id}`}>Edit</Link>
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-center">
          {Array.from({ length: Math.ceil(filteredData.length / itemsPerPage) }, (_, index) => (
            <li key={index} className={`page-item ${index + 1 === currentPage ? 'active' : ''}`}>
              <button className="page-link" onClick={() => setCurrentPage(index + 1)}>
                {index + 1}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Students;
