import React from 'react'
import Input from '../../components/form/Input'
import { useGetStaffProductQuery, useSetProductStaffMutation } from '../../services/productSlice'
import Loading from '../../components/ui/Loading'
import ApiErrorModal from '../../components/modals/ApiErrorModal'
import SuccessModal from '../../components/modals/SuccessModal'

const ProductStaffs = () => {

  const response = useGetStaffProductQuery()
  const [set, setResponse] = useSetProductStaffMutation()

  const onSubmit = (e) => {
    const shouldUpdate = window.confirm('Are you sure you want to Update this information?');
    if (shouldUpdate) {
    e.preventDefault();
    const form = e.target;

    if (form.checkValidity()) {
      const body = {
        packageName: e.target['Package Name'].value,
        packagePrice: e.target["Package Price"].value,
      }

      set(body)
        .then(() => {
          response.refetch()
          form.classList.remove('was-validated')
        })
    }
    else {
      form.classList.add('was-validated')
    }
  }
  }

  if (response.isLoading) return <Loading />
  if (response.isError) return <ApiErrorModal response={response} />

  return (
    <div className='container p-3 p-lg-5'>

      {setResponse.isSuccess && <SuccessModal message={"Associate Package has been updated."} />}
      {setResponse.isError && <ApiErrorModal response={setResponse} />}

      <h4 className='text-primary fw-bold mb-5'>{'Product > Courses'}</h4>

      <form onSubmit={onSubmit} className='row row-cols-1 row-cols-lg-3 align-items-end g-3'>

        <div className="col">
          <p className='fw-bold text-muted'>Associate Package Name</p>
          <Input name={'Package Name'} defaultValue={response?.data?.packageName} containerClass={'w-100'} />
        </div>

        <div className="col">
          <p className='fw-bold text-muted'>Associate Package Price</p>
          <Input name={'Package Price'} defaultValue={response?.data?.packagePrice} containerClass={'w-100'} />
        </div>

        <div className="col">
          {
            setResponse.isLoading ?
              <button class="btn btn-primary text-white w-100 rounded-pill" type="button" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Saving...
              </button>
              :
              <button className="btn btn-primary text-white w-100 rounded-pill">Save Changes</button>
          }
        </div>

      </form>

    </div>
  )
}

export default ProductStaffs