import React, { useEffect, useState } from 'react';
import { useAdminOtpSendMutation, useChangePasswordMutation, useGetSiteInfoQuery, useSetSiteInfoMutation } from '../services/adminSlice';
import ApiErrorModal from '../components/modals/ApiErrorModal';
import SuccessModal from '../components/modals/SuccessModal';
import Loading from '../components/ui/Loading';
import { useGetGatewayQuery, usePostGatewayMutation, useUpdateGatewayMutation } from '../services/selectedGatewaySlice';

const Settings = () => {

  const btnClassName = "btn btn-primary rounded-pill px-5 text-white my-3";


  const gatewayData = useGetGatewayQuery();
  const [postGateway, postGatewayRes] = usePostGatewayMutation();
  const [updateGateway, updateGatewayRes] = useUpdateGatewayMutation();



  const [gatewayName, setGatewayName] = useState('PhonePe');



  const handleGatewaySubmit = (e) => {
    e.preventDefault();
    if (gatewayData?.data?.length === 0) {
      postGateway({ gateway: gatewayName });
    } else {
      const body = { gateway: gatewayName };
      updateGateway({ id: 1, body });
    }
  }


  useEffect(() => {
    // Set default value for gatewayName if data is available
    if (gatewayData.data && gatewayData.data.length > 0) {
      setGatewayName(gatewayData.data[0].gateway);
    }
  }, [gatewayData.data]);



  const getinfo = useGetSiteInfoQuery(1)
  const [adminOtpSend, adminOtpSendRes] = useAdminOtpSendMutation()
  const [changePassword, changePasswordRes] = useChangePasswordMutation()


  const [mobileNumber, setMobileNumber] = useState('');
  const [siteName, setSiteName] = useState('');
  const [tagLine, setTagLine] = useState('');
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [logo, setLogo] = useState(null);
  const [userLogo, setUserLogo] = useState(null);
  const [favicon, setFavicon] = useState(null);
  const [setSiteInfo, setSiteInfoRes] = useSetSiteInfoMutation(); // Assuming the mutation hook returns a function for 

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('siteName', siteName);
    formData.append('tagLine', tagLine);
    formData.append('logo', logo);
    formData.append('userLogo', userLogo);
    formData.append('favIcon', favicon);

    try {
      await setSiteInfo({ id: 1, body: formData });
      // Handle success
    } catch (error) {
      // Handle error
    }
  };


  const handleSendOtp = (e) => {

    e.preventDefault();
    // const mobileNumber = e.target['mobileNumber'].value
    adminOtpSend({ mobileNumber: mobileNumber, id: 1 })

    setShowOtpInput(true);
  }


  const handleChangePassword = (e) => {
    e.preventDefault()
    const otp = e.target['otp'].value;
    const password = e.target['password'].value;
    const confirmPassord = e.target['confirmPassword'].value;

    if (password != confirmPassord) {
      alert("Password Not Same")
    }
    else {
      changePassword({ mobileNumber, otp, password })
        .then(() => {
          setMobileNumber('')
          e.target['otp'].value = ''
          e.target['password'].value = ''
          e.target['confirmPassword'].value = ''
        })
    }
  }

  useEffect(() => {
    setSiteName(getinfo?.data?.siteName)
    setTagLine(getinfo?.data?.tagline)
  }, [getinfo.data])



  if (gatewayData.isLoading || postGateway.isLoading || updateGateway.isLoading || setSiteInfo.isLoading) return <Loading />;









  return (
    <div className="p-3 p-lg-5">

      {changePasswordRes.isSuccess && <SuccessModal message={"Password has been Changes."} />}
      {adminOtpSendRes.isSuccess && <SuccessModal message={"Otp has been send."} />}
      {setSiteInfoRes.isSuccess && <SuccessModal message={"Information Updated Successfully."} />}
      {postGatewayRes.isSuccess && <SuccessModal message={" Gateway Information Updated Successfully."} />}
      {updateGatewayRes.isSuccess && <SuccessModal message={" Gateway Information Updated Successfully."} />}


      {(changePasswordRes.isError) && <ApiErrorModal response={changePasswordRes} />}
      {(adminOtpSendRes.isError) && <ApiErrorModal response={adminOtpSendRes} />}
      {(setSiteInfoRes.isError) && <ApiErrorModal response={setSiteInfoRes} />}
      {(postGatewayRes.isError) && <ApiErrorModal response={postGatewayRes} />}
      {(updateGatewayRes.isError) && <ApiErrorModal response={updateGatewayRes} />}




      <style>
        {`
        ul.setingnavpill li button.active {
            background: #f4f4f4 !important;
            border-radius: 0 !important;
            border-bottom: 2px solid #70018b !important;
        }
        ul.setingnavpill li button {
            display: block;
            padding: 14px !important;
            transition: all 0.5s ease;
        }
        ul.setingnavpill {
            background: #ffffff;
            border-radius: 12px;
            border: 1px solid #00000010;
            overflow: hidden;
        }
        ul.setingnavpill li.nav-item button.nav-link {
            border-radius: 0 !important;
            border-bottom: 2px solid transparent;
        }
        ul.setingnavpill li.nav-item button.nav-link:hover {
            background: #fff;
        }
        .form-control {
            border-radius: 30px;
        }
        `}
      </style>
      <h1 className="mb-1 text-primary fw-bold">Settings</h1>
      <p className="mb-3">Manage your account settings and preferences</p>
      <ul className="nav nav-pills mb-4 setingnavpill" id="pills-tab" role="tablist">
        <li className="nav-item" role="presentation"><button aria-controls="pills-general" aria-selected="false" className="nav-link active" data-bs-target="#pills-general" data-bs-toggle="pill" id="pills-general-tab" role="tab" tabindex="-1" type="button">General</button></li>
        <li className="nav-item" role="presentation"><button aria-controls="pills-security" aria-selected="true" className="nav-link" data-bs-target="#pills-security" data-bs-toggle="pill" id="pills-security-tab" role="tab" type="button">Credentials</button></li>
        <li className="nav-item" role="presentation"><button aria-controls="pills-security" aria-selected="true" className="nav-link" data-bs-target="#pills-gateway" data-bs-toggle="pill" id="pills-security-tab" role="tab" type="button">Payment</button></li>
      </ul>
      <div className="tab-content" id="pills-tabContent">
        <div aria-labelledby="pills-general-tab" className="tab-pane fade active show" id="pills-general" role="tabpanel">
          <h2>General Settings</h2>
          <p>Customize Your Experience: General Settings.</p>

          <form className="mt-4" onSubmit={handleSubmit}>
            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label" htmlFor="siteName">Site Name</label>
                <input className="form-control" id="siteName" value={siteName} onChange={(e) => setSiteName(e.target.value)} placeholder="Enter site name" type="text" required />
              </div>
              <div className="col-md-6">
                <label className="form-label" htmlFor="tagline">Tagline</label>
                <input className="form-control" id="tagline" value={tagLine} onChange={(e) => setTagLine(e.target.value)} placeholder="Enter tagline" type="text" required />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label" htmlFor="logo">Logo</label>
                <input className="form-control" id="logo" onChange={(e) => setLogo(e.target.files[0])} type="file" required />
                <div className="form-text" id="logoHelp">
                  Upload your site's logo.
                </div>
              </div>
              <div className="col-md-6">
                <label className="form-label" htmlFor="favicon">Favicon</label>
                <input className="form-control" id="favicon" onChange={(e) => setFavicon(e.target.files[0])} type="file" required />
                <div className="form-text" id="faviconHelp">
                  Upload your site's favicon.
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label" htmlFor="logo"> User Logo</label>
                <input className="form-control" id="logo" onChange={(e) => setUserLogo(e.target.files[0])} type="file" required />
                <div className="form-text" id="logoHelp">
                  Upload your user app's logo.
                </div>
              </div>

            </div>

            <button className="btn btn-primary" type="submit">Save Settings</button>
          </form>

        </div>
        <div aria-labelledby="pills-security-tab" className="tab-pane fade" id="pills-security" role="tabpanel">
          <h2>Credentials Settings</h2>
          <p>User Authentication and Access Control</p>
          <form className="mt-4" id="credentialsForm" name="credentialsForm" onSubmit={handleChangePassword}>
            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label" for="mobileNumber">Mobile Number</label>


                <div className="input-group">

                  <input className="form-control" data-ms-editor="true" name='mobileNumber' value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} id="mobileNumber" placeholder="Enter mobile number" spellcheck="false" type="text" />
                  <button className="btn btn-primary" id="sendOtpButton" onClick={handleSendOtp}>Send OTP</button>


                </div>

              </div>

              {showOtpInput &&
                <div className="col-md-6">
                  <label className="form-label" for="otp">OTP</label> <input className="form-control" name='otp' id="otp" placeholder="Enter OTP" type="text" />
                </div>}


            </div>


            {
              showOtpInput &&
              <>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label className="form-label" for="currentPassword">New Password</label> <input className="form-control" id="currentPassword" name='password' placeholder="Enter New password" type="password" />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label" for="newPassword">Confirm Password</label> <input className="form-control" name='confirmPassword' id="newPassword" placeholder="Enter new password" type="password" />
                  </div>
                </div><button className="btn btn-primary" type="submit">Change Password</button>
              </>
            }





          </form>
        </div>
        <div aria-labelledby="pills-security-tab" className="tab-pane fade" id="pills-gateway" role="tabpanel">
          <h2>Payment Gateway Settings</h2>
          <p>Configure Payment Integration Options</p>

          <form onSubmit={handleGatewaySubmit}>
            <div className="mb-5 ms-3 mt-5 d-flex flex-column align-items-end w-50">
              <div className="me-3 w-100">
                <label className="form-label" for="paymentGateway">Select Payment Gateway</label>
                <select className="form-select" name={"Gateway Name"} id="paymentGateway" onchange="showFields()" defaultValue={gatewayName} value={gatewayName} onChange={(e) => setGatewayName(e.target.value)}
                  required    >
                  <option disabled selected>Select Payment Gateway</option>
                  <option value="phonepe">PhonePe</option>
                  <option value="ccavenue">CC Avenue</option>
                </select>
              </div>
              <div>

                {postGateway.isLoading || updateGateway.isLoading ? (
                  <button className={btnClassName} type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> {postGateway.isLoading ? 'Creating...' : 'Updating...'}
                  </button>
                ) : (
                  <button type='submit' className={btnClassName}>{gatewayData?.data?.length === 0 ? 'Create' : 'Update'}</button>
                )}
              </div>
            </div>
          </form>

        </div>
      </div>
    </div>

  );
}

export default Settings;
