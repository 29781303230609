import React, { useState } from 'react'
import Input from '../../components/form/Input'
import SelectInput from '../../components/form/SelectInput'
import { useGetDesignationsQuery, usePostDesignationMutation } from '../../services/designationSlice'
import { useNavigate } from 'react-router-dom'
import ApiErrorModal from '../../components/modals/ApiErrorModal'
import SuccessModal from '../../components/modals/SuccessModal'
import SectionHeader from '../../components/form/SectionHeader'
import Section from '../../components/form/Section'
import SectionBody from '../../components/form/SectionBody'
import FormattedInput from '../../components/form/FormattedInput'

const CreateDesignation = () => {

    const navigate = useNavigate();
    const [postDesignation, postDesignationResponse] = usePostDesignationMutation()
    const designationData = useGetDesignationsQuery()
    const [isChecked, setIsChecked] = useState(false);

    const btnClassName = "btn btn-primary rounded-pill px-5 text-white my-3"


    const handleSubmit = (e) => {

        e.preventDefault();
        const form = e.target;
        if (form.checkValidity()) {

            let body = {
                name: form['Designation Name'].value,
                // desigId: form['desigId'].value,
                color: form['Color'].value,
                offerLetter: form['tinymceId'].value,

            }

            if (isChecked) {
                body = {
                    ...body,
                    ...{ designationId: form['Designation Id'].value }
                }
            }

            postDesignation(body)
                .then(() => {
                    form.classList.remove('was-validated')
                    form.reset()
                })
                .then(() => {
                    designationData.refetch()
                    navigate('/designations')
                })
        }
        else {
            form.classList.add('was-validated')
        }

    }


    return (
        <div className='container p-3 p-lg-5'>

            {postDesignationResponse.isSuccess && <SuccessModal message={"Designation has been created."} />}
            {postDesignationResponse.isError && <ApiErrorModal response={postDesignationResponse} />}

            <h4 className='fw-bold mb-3'>Create Designation</h4>

            <form onSubmit={handleSubmit} noValidate >

                <Section title={'Personal Details'} className='row-cols-1 row-cols-lg-3 g-3 mb-3'>
                    <Input containerClass="" name={"Designation Name"} required />
                    {/* <Input containerClass="" name={"desigId"} placeholder={'Designation ID'} required /> */}
                    <Input containerClass={''} inputClassName={''} type={'color'} name={"Color"} placeholder={'Color'} required style={{ height: 38 }} />

                    {isChecked &&
                        <SelectInput
                            name={"Designation Id"} placeholder={'Parent Designation'}
                            options={[
                                ...(designationData?.data?.map((item) => ({
                                    value: item.id,
                                    label: item.name,
                                })) || [])
                            ]}
                            required
                        />

                    }

                </Section>

                <SectionBody className=''>
                    <div className="d-flex gap-2 flex-column w-100">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value={isChecked} id="flexCheckDefault" onChange={(e) => setIsChecked(e.target.checked)} />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                Leader
                            </label>
                        </div>

                        <div className="form-check ">
                            <div className="d-flex gap-3 mb-3">
                            <label class="fw-bold mb-1 ms-1" for="designationOfferLetter">Designation Offer Letter</label>
                            <button class="btn btn-outline-primary ms-2" data-bs-target="#variablesModal" data-bs-toggle="modal" type="button">Available Variables</button>
                            </div>
                            
                            
                            {/* <textarea class="form-control rounded px-3 border-primary border-2 mt-3" id="Offer Letter" name={"Offer Letter"} placeholder="Designation Offer Letter" required="" spellcheck="false"></textarea> */}
                        
                            <FormattedInput name={'tinymceId'} label={' '}  />
                        
                        </div>



                    </div>
                </SectionBody>

                {
                    postDesignationResponse.isLoading
                        ?
                        <button class={btnClassName} type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Creating...
                        </button>
                        :
                        <button type='submit' className={btnClassName}>Create</button>
                }
            </form>

        <div class="modal fade" id="variablesModal" tabindex="-1" aria-labelledby="variablesModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="variablesModalLabel">Available Variables</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <ul>
                            <li>[REF_NO]</li>
                            <li>[ISSUE_DATE]</li>
                            <li>[DESIGNATION]</li>
                            <li>[ASSOCIATE_NAME]</li>
                            <li>[ASSOCIATE_ID]</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        </div>
    )
}

export default CreateDesignation