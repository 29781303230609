import React from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import { useGetAssociateIdQuery } from '../../services/associateSlice';
import Api from '../../constants/Api';
import Loading from '../../components/ui/Loading';
import ProfileModal from '../document/ProfileModal';
import OfferLetterModal from '../document/OfferLetterModal';
import IdCardModal from '../document/IdCardModal';
import AssociateInvoiceModal from './AssociateInvoiceModal';
import { convertToFiveDigits } from '../../utils/ConvertToFive';


const Associate = () => {
    const { id } = useParams();
    const response = useGetAssociateIdQuery(id)
    if (response.isLoading) return <Loading />
    return (
        <div className='p-5'>


            <h4 className='text-primary fw-bold mb-3'>Associate Details</h4>
            <div className='d-flex'>

                <div className='card p-3 rounded-4 border-primary me-5 flex-grow-1'>

                    <h5>Personal Details</h5>

                    <div className='row mb-3'>
                        <div className='col'>
                            <div className='text-muted'>Associate Name:</div>
                            <div className='fw-bold text-primary'>{response?.data?.associate?.name}</div>
                        </div>
                        <div className='col'>
                            <div className='text-muted'>Mobile Number:</div>
                            <div className='fw-bold text-primary'>{response?.data?.associate?.mobileNumber}</div>
                        </div>
                        <div className='col'>
                            <div className='text-muted'>Father’s Name:</div>
                            <div className='fw-bold text-primary'>{response?.data?.associate?.fatherName}</div>
                        </div>
                    </div>

                    <div className='row mb-3'>
                        <div className='col'>
                            <div className='text-muted'>Date of Birth:</div>
                            <div className='fw-bold text-primary'>{
                             new Date( response?.data?.associate?.dob).toLocaleDateString('en-GB', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                            }).replace(/\//g, '-')

                           }</div>
                        </div>
                        <div className='col'>
                            <div className='text-muted'>Alternate Mobile Number:</div>
                            <div className='fw-bold text-primary'>{response?.data?.associate?.alternateMobileNumber}</div>
                        </div>
                        <div className='col'>
                            <div className='text-muted'>Aadhar Number:</div>
                            <div className='fw-bold text-primary'>{response?.data?.associate?.aadharNumber}</div>
                        </div>
                    </div>

                    <div className='row mb-3'>
                        <div className='col'>
                            <div className='text-muted'>Gender:</div>
                            <div className='fw-bold text-primary'>{response?.data?.associate?.gender}</div>
                        </div>
                        <div className='col'>
                            <div className='text-muted'>Qualification:</div>
                            <div className='fw-bold text-primary'>{response?.data?.associate?.qualification}</div>
                        </div>
                        <div className='col'>
                            <div className='text-muted'>Other Qualification:</div>
                            <div className='fw-bold text-primary'>{response?.data?.associate?.otherQualification}</div>
                        </div>
                    </div>

                    <h5 className='mt-5'>Address</h5>

                    <div className='row mb-3'>
                        <div className='col'>
                            <div className='text-muted'>Plot/Flat Number or Building Name:</div>
                            <div className='fw-bold text-primary'>{response?.data?.address?.flatNumber}</div>
                        </div>
                        <div className='col'>
                            <div className='text-muted'>Address Line 1:</div>
                            <div className='fw-bold text-primary'>{response?.data?.address?.addressLine1}</div>
                        </div>
                        <div className='col'>
                            <div className='text-muted'>Landmark:</div>
                            <div className='fw-bold text-primary'>{response?.data?.address?.landmark}</div>
                        </div>
                    </div>

                    <div className='row mb-3'>
                        <div className='col'>
                            <div className='text-muted'>State:</div>
                            <div className='fw-bold text-primary'>{response?.data?.address?.state}</div>
                        </div>
                        <div className='col'>
                            <div className='text-muted'>Dist:</div>
                            <div className='fw-bold text-primary'>{response?.data?.address?.district}</div>
                        </div>
                        <div className='col'>
                            <div className='text-muted'>City/Village:</div>
                            <div className='fw-bold text-primary'>{response?.data?.address?.cityVillage}</div>
                        </div>
                    </div>

                    <div className='row mb-3'>
                        <div className='col'>
                            <div className='text-muted'>Pin:</div>
                            <div className='fw-bold text-primary'>{response?.data?.address?.pin}</div>
                        </div>
                        <div className='col'>
                            <Link className='fw-bold text-primary' to={Api.BASE_URL + `${response?.data?.associate?.aadharFront}`} target="_blank" rel="noopener noreferrer">View Aadhar Front</Link>
                        </div>
                        <div className='col'>
                            <Link className='fw-bold text-primary' to={Api.BASE_URL + `${response?.data?.associate?.aadharBack}`} target="_blank" rel="noopener noreferrer">View Aadhar Back</Link>

                        </div>
                    </div>

                    <h5 className='mt-5'>Rank & Sponsor</h5>

                    <div className='row mb-3'>
                        <div className='col'>
                            <div className='text-muted'>Rank:</div>
                            <div className='fw-bold text-primary'>{response?.data?.designation?.name}</div>
                        </div>
                        <div className='col'>
                            <div className='text-muted'>Sponsor’s Associate ID:</div>
                            <div className='fw-bold text-primary'>{response?.data?.sponsor?.id && `S3A${convertToFiveDigits(response?.data?.sponsor?.id)}`}</div>
                        </div>
                        <div className='col'>
                            <div className='text-muted'>Sponsor Name:</div>
                            <div className='fw-bold text-primary'>{response?.data?.sponsor?.name}</div>
                        </div>
                    </div>

                    <div className='row mb-3'>
                        <div className='col'>
                            <div className='text-muted'>Personal Student ID:</div>
                            <div className='fw-bold text-primary'>{response?.data?.associate?.personalStudentId}</div>
                        </div>
                        <div className='col'>
                            <div className='text-muted'>Registration Date:</div>
                            <div className='fw-bold text-primary'>
                           { new Date(response?.data?.associate?.registrationDate).toLocaleDateString('en-GB', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                            }).replace(/\//g, '-')

                                }</div>
                        </div>
                        <div className='col'>
                            <Link className='fw-bold text-primary' to={Api.BASE_URL + `${response?.data?.associate?.passPhoto}`} target="_blank" rel="noopener noreferrer">View Pass Photo</Link>
                        </div>
                    </div>

                </div>

            {
                response?.data?.associate?.status =='Approved' && 
                <div>

                <div className='card p-5 rounded-4 border-primary mb-5'>

                    <h5>View Documents</h5>
                    <ol className='text-primary p-3'>
                        <li className='mb-3 d-flex '><h6 className='me-3'>View Profile</h6> <ProfileModal item={response?.data} /> </li>

                        {(response.data.associate.offerLetterStatus != 'Pending') && <li className='mb-3 d-flex '><h6 className='me-3'>Associate Letter</h6> <OfferLetterModal item={response?.data} /> </li>}



                        {
                            (response.data.associate.idCardStatus != 'Pending') && <li className='mb-3 d-flex '><h6 className='me-3'>ID Card</h6> <IdCardModal item={response?.data} /> </li>

                        }

                    </ol>

                </div>

                <div className='card p-5 rounded-4 border-primary'>

                    <h5>Quick Links</h5>
                    <ol className='text-primary p-3'>
                        <li className='mb-3'><Link to={`/create/student/${response?.data?.associate?.id}`}>Add Student</Link></li>
                        <li className='mb-3'><Link to={`/create/associate/${response?.data?.associate?.id}`}>Add Associate</Link></li>
                        <li className='mb-3'><AssociateInvoiceModal item={response?.data} linkk={true} /></li>
                        <li className='mb-3'><Link to={`../../report/user/sales/${response?.data?.associate?.id}`} >Download Sales Report</Link></li>
                        <li className='mb-3'><Link to={`../../report/user/${response?.data?.associate?.id}`} >Download Team Report</Link></li>
                        <li className='mb-3'><Link to={`../../report/student/${response?.data?.associate?.id}`}>Download Student Report</Link></li>
                    </ol>

                </div>

            </div>
            }

            </div>

        </div >
    )
}

export default Associate