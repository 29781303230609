import React, { useEffect, useState } from 'react'
import Input from '../../components/form/Input'
import { useGetStudentQuery, usePostStudentMutation, useUpdateStudentMutation } from '../../services/studentSlice'
import SelectInput from '../../components/form/SelectInput'
import { useGetAssociatesQuery } from '../../services/associateSlice'
import { useGetProductsQuery } from '../../services/productSlice'
import { useGetCategoriesQuery } from '../../services/categorySlice'
import SuccessModal from '../../components/modals/SuccessModal'
import ApiErrorModal from '../../components/modals/ApiErrorModal'
import { useParams } from 'react-router-dom'
import Loading from '../../components/ui/Loading'
import SearchSelectInput from '../../components/form/SearchSelectInput'

const statusOptions = [
    { value: '1', label: 'Active' },
    { value: '0', label: 'InActive' },
    // { value: 'SIGN_UP_PENDING', label: 'SIGN_UP_PENDING' },
]

const EditCreateStudent = () => {

    const { id } = useParams();
    const response = useGetStudentQuery(id)

    const [updateStudent, updateStudentResponse] = useUpdateStudentMutation()

    const sponserData = useGetAssociatesQuery()
    const productData = useGetProductsQuery()
    const categoryData = useGetCategoriesQuery()
    const [sponsor, setSponsor] = useState("1");


    const handleSubmit = (e) => {

        const shouldUpdate = window.confirm('Are you sure you want to Update this student?');
        if (shouldUpdate) {

            e.preventDefault();

            const form = e.target;

            if (form.checkValidity()) {

                const body = {
                    studentName: form['Student Name'].value,
                    mobileNumber: form["Mobile Number"].value,
                    associateId: sponsor,
                    adminId: 0,//current admin id from login
                    appStudentId: form["App Student ID"].value,
                    courseId: form["Product"].value,
                    modeOfPayment: form["Mode of Payment"].value,
                    refId: form["Ref. Id"].value,
                    isActive: form["Status"].value,
                    parentName: form["Parent Name"].value,
                    registrationDate: form["Registration Date"].value,
                }

                updateStudent({ id, body })
                    .then(() => {
                        form.classList.remove('was-validated')
                        response.refetch()
                    })
            }
            else {
                form.classList.add('was-validated')
            }

        }
    }

    useEffect(() => {
        if (response.data) {
            if (response?.data?.sponsor?.id) setSponsor(response?.data?.sponsor?.id)
        }
    }, [response.data])

    if (response.isLoading || sponserData.isLoading || productData.isLoading || categoryData.isLoading) return <Loading />
    if (response.isError) return <ApiErrorModal />


    return (
        <div className='container p-3 p-lg-5'>

            {updateStudentResponse.isSuccess && <SuccessModal message={"Student has been updated."} />}
            {updateStudentResponse.isError && <ApiErrorModal response={updateStudentResponse} />}

            <h4 className='fw-bold mb-3'>Edit Student</h4>

            <h5 className='text-muted mb-3'>Personal Details</h5>
            <form onSubmit={handleSubmit} noValidate>
                <div className='row row-cols-1 row-cols-lg-3 g-3'>
                    <Input containerClass="" name={"Student Name"} defaultValue={response.data.student.studentName} required />
                    <Input containerClass="" name={"Mobile Number"} defaultValue={response.data.student.mobileNumber} required />
                    {/* <Input containerClass="" name={"Sponser Name"} /> */}



                    <SearchSelectInput
                        name={"Sponsor’s Name"}
                        options={
                            sponserData?.data
                            ?.filter(item => item?.associate?.status == 'Approved')
                            ?.map((sponsor) => ({
                                value: sponsor?.associate?.id,
                                label: sponsor?.associate?.name,
                                serialNo: sponsor?.associate?.serialNo,

                            })) || []
                        }
                        value={sponsor} defaultValue={sponsor} onChange={(val) => setSponsor(val)}
                        containerClass={''}
                        width={400}

                    />

                    <Input containerClass="" name={"App Student ID"} defaultValue={response.data.student.appStudentId}  />
                    <Input containerClass="" name={"Parent Name"} defaultValue={response.data.student.parentName} required />
                </div>


                <h5 className='text-muted mt-5 mb-3'>Product Details</h5>
                <div className='row row-cols-1 row-cols-lg-3 g-3'>

                    <SelectInput
                        name="Category"
                        options={
                            categoryData?.data?.map((item) => ({
                                value: item?.id,
                                label: item?.name,
                            })) || []
                        }
                        defaultValue={response?.data?.course?.categoryId}
                    />

                    <SelectInput
                        name="Product"
                        options={
                            productData?.data?.map((item) => ({
                                value: item?.course?.id,
                                label: item?.course?.name,
                            })) || []
                        }
                        defaultValue={response?.data?.course?.id}
                    />
                </div>

                <h5 className='text-muted mb-3 mt-5'>Invoice Details</h5>
                <div className='row row-cols-1 row-cols-lg-3 g-3'>
                    <SelectInput
                        name={"Mode of Payment"}
                        options={
                            [
                                { value: 'Cash', label: 'Cash' },
                                { value: 'UPI', label: 'UPI' },
                                { value: 'NEFT', label: 'NEFT' },
                                { value: 'IMPS', label: 'IMPS' },
                                { value: 'Cheque', label: 'Cheque' },
                                { value: 'Card', label: 'Card' },
                            ]
                        }
                        defaultValue={response?.data?.student?.modeOfPayment}
                        required
                    />
                    <Input containerClass="" name={"Ref. Id"} defaultValue={response?.data?.student?.refId} required />
                    <Input type={'date'} containerClass="" defaultValue={response?.data?.student?.registrationDate} name={"Registration Date"} />

                </div>

                <h5 className='text-muted mb-3 mt-5'>Status</h5>
                <div className='row row-cols-1 row-cols-lg-3 g-3'>
                    {/* <Input containerClass="" name={"Status"} defaultValue={response?.data?.student?.status} required /> */}
                    <SelectInput
                        name={"Status"}
                        options={statusOptions}
                        defaultValue={response?.data?.student?.isActive}
                        required
                    />
                </div>

                {
                    updateStudentResponse.isLoading ?
                        <button class='btn btn-primary mt-5 float-end' type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Updating...
                        </button>
                        :
                        <button className='btn btn-primary mt-5 float-end'>Update</button>

                }

            </form>

        </div>
    )
}

export default EditCreateStudent