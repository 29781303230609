const getApiErrorMessage = (res) => {

    const error = res.error

    switch (error.status) {
        case "FETCH_ERROR": return { label: "No Internet", message: "Kindly check your internet connection." }
        case 401: return { label: "Unauthorized", message: "You are not authorized." }
        default: return { label: "Bad Request", message: error.data?.message }
    }
}

module.exports = { getApiErrorMessage }