
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useGetAssociatesQuery } from '../../services/associateSlice';
import Loading from '../../components/ui/Loading';
import ApiErrorModal from '../../components/modals/ApiErrorModal';
import InputSearch from '../../components/form/InputSearch';
import { convertToFiveDigits } from '../../utils/ConvertToFive';
import SelectInput from '../../components/form/SelectInput';

const Teams = () => {
  const [searchValue, setSearchValue] = useState('');
  const [selectValue, setSelectValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 100; // Number of items per page

  const response = useGetAssociatesQuery();
  useEffect(()=>{

  },[])

  
  if (response.isLoading) return <Loading />;
  if (response.isError) return <ApiErrorModal response={response} />;




  // Calculate pagination values
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredData = response.data
    .filter((item) => item.associate.status === 'Approved')
    .filter((item) => {
      if (searchValue?.length === 0 && selectValue?.length === 0) return true;

      const isSearchMatch =
        searchValue?.length > 0 &&
        (item?.associate?.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          `S3A${convertToFiveDigits(item?.associate?.serialNo)}`.toLowerCase().includes(searchValue.toLowerCase()) ||
          item?.associate?.mobileNumber.toLowerCase().includes(searchValue.toLowerCase()) ||
          item.associate.status.toLowerCase().includes(searchValue.toLowerCase()));

      let isProductMatch = selectValue?.length > 0;

      if (isProductMatch) {
        switch (selectValue) {
          case 'Active':
            isProductMatch = (item.associate.isActive == true);
            break;
          case 'InActive':
            isProductMatch = (item.associate.isActive == false);
            break;
          default:
            isProductMatch = true;
        }
      }

      if (searchValue?.length === 0) return isProductMatch;
      if (selectValue?.length === 0) return isSearchMatch;

      return isProductMatch && isSearchMatch;
    });

  // const sortedData = [...filteredData].sort((a, b) => b.serialNo - a.serialNo);
  const sortedData = filteredData


  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);


  return (
    <div className='p-3 p-lg-5'>
      <h4 className='text-primary fw-bold mb-3'>Team Management</h4>
      <div className="container">
        <div className="row row-cols-1 row-cols-lg-3 g-3 mb-3">
          <div className="col">
            <SelectInput
              name={"Select Status"}
              noLabel
              options={[
                { value: '', label: 'Select' },
                { value: 'Active', label: 'Active' },
                { value: 'InActive', label: 'InActive' },
              ]}
              value={selectValue}
              onChange={(e) => setSelectValue(e.target.value)}
              containerClass={'w-75'}
              required
            />
          </div>
          <div className="col">
            <InputSearch
              name={"Search Associates"}
              containerClass={"w-100"}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
          <div className="col">
            <Link className='btn btn-primary rounded-pill w-100' to={"/create/associate"}>Create Associates</Link>
          </div>
        </div>
      </div>
      <div className='card rounded-4 overflow-hidden shadow mb-3 '>
        <div className="table-responsive">
          <table className="table table-borderless table-hover">
            <thead>
              <tr className='border-bottom'>
                <th scope="col" className='text-muted'>Associate ID</th>
                <th scope="col" className='text-muted'>Name</th>
                <th scope="col" className='text-muted'>Designation</th>
                <th scope="col" className='text-muted'>Mobile No.</th>
                <th scope="col" className='text-muted'>Sponsor</th>
                <th scope="col" className='text-muted'>Status</th>
                <th scope="col" className='text-muted'>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.slice() // Create a shallow copy to avoid mutating the original array
                .sort((a, b) => b?.associate?.serialNo - a?.associate?.serialNo) 
                .map(item => (
                  <tr key={item?.associate?.id}>

                    <th>{item?.associate?.serialNo && `S3A${convertToFiveDigits(item?.associate?.serialNo)}`}</th>
                    <th>{item?.associate?.name}</th>
                    <th>{item?.designation?.name}</th>
                    <th>{item?.associate?.mobileNumber}</th>
                    <th><Link to={`/team/${item?.sponsor?.id}`} className='text-primary'>{item?.sponsor?.name}</Link></th>
                    <th className={(item?.associate?.isActive == true ? 'text-success' : 'text-danger')}>
                      {(item?.associate?.isActive == true) ? 'Active' : 'Inactive'}
                    </th>
                    <th className='d-flex'>
                      <Link className='btn btn-sm btn-primary rounded-pill px-3 me-3 py-0' to={`/team/${item?.associate?.id}`}>Details</Link>
                      <Link className='btn btn-sm btn-success rounded-pill px-3 text-white py-0' to={`/edit/associate/${item?.associate?.id}`}>Edit</Link>
                    </th>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-center">
          {Array.from({ length: Math.ceil(filteredData.length / itemsPerPage) }, (_, index) => (
            <li key={index} className={`page-item ${index + 1 === currentPage ? 'active' : ''}`}>
              <button className="page-link" onClick={() => setCurrentPage(index + 1)}>
                {index + 1}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Teams;
