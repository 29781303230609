import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";


export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCategories: builder.query({ query: () => `/category`, keepUnusedDataFor: 0 }),
        // getCategoryById: builder.query({ query: (id) => `/category?id=${id}`, keepUnusedDataFor: 0 }),
        postCategory: builder.mutation({ query: (body) => ({ url: `/category/create`, method: 'POST', body }) }),
        deleteCategory: builder.mutation({ query: (id) => ({ url: `/category/delete?id=${id}`, method: 'DELETE', }) }),
        updateCategory: builder.mutation({ query: ({ id, body }) => ({ url: `/category/edit?id=${id}`, method: 'POST', body }) }),
    }),
})

export const {
    useGetCategoriesQuery,
    // useGetCategoryByIdQuery,
    usePostCategoryMutation,
    useDeleteCategoryMutation,
    useUpdateCategoryMutation
} = extendedApiSlice
