// import React from 'react'
// import { NavLink } from 'react-router-dom'
// import Icons from '../ui/Icons';
// import logo from '../../assets/images/logo_sudikshya.png'

// export const sideBarLinks = [
//     { to: "/", text: "Dashboard", icon: Icons.dashboard },
//     { to: "/order", text: "Approval Management", icon: Icons.order },
//     { to: "/team", text: "Team Management", icon: Icons.team },
//     { to: "/student", text: "Student Management", icon: Icons.student },
//     { to: "/product", text: "Product", icon: Icons.product, items: [{ text: "Staffs", to: "/product/staffs" }, { text: "Courses", to: "/product/courses" }] },
//     { to: "/document", text: "Document Management", icon: Icons.document },
//     { to: "/lead", text: "Leads", icon: Icons.leads },
//     { to: "/report", text: "Report", icon: Icons.report },
//     { to: "/settings", text: "Settings", icon: Icons.settings },
// ];

// const Navbar = () => {

//     const navWrapItem = (link) => {
//         return (
//             <li className="nav-item py-1">
//                 <button className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed nav-link" data-bs-toggle="collapse" data-bs-target="#home-collapse" aria-expanded="true">
//                     {link.icon("me-2", { width: 20, height: 20 })}
//                     {link.text}
//                 </button>
//                 <div className="collapse show" id="home-collapse" bis_skin_checked="1">
//                     <ul className="list-unstyled bg-light rounded-3 p-3">
//                         {
//                             link.items.map((item) => <li><NavLink to={item.to} className="link-dark d-inline-flex text-decoration-none rounded">{item.text}</NavLink></li>)
//                         }

//                     </ul>
//                 </div>
//             </li>
//         )
//     }

//     const navItem = (link) => {
//         return (
//             <li key={link.text} className="nav-item py-1">
//                 <NavLink to={link.to} className="nav-link d-flex align-items-center" >
//                     {link.icon("me-2", { width: 20, height: 20 })}
//                     {link.text}
//                 </NavLink>
//             </li>
//         )
//     }

//     const renderLinks = (link) => link.items ? navWrapItem(link) : navItem(link)

//     return (
//         <nav class="navbar navbar-expand-lg bg-primary d-lg-none px-3">

//             <h3 className='fw-bold text-light'>Sudikshya</h3>

//             <button class="navbar-toggler bg-light" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
//                 <span class="navbar-toggler-icon"></span>
//             </button>



//             <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
//                 <div class="offcanvas-header">
//                     <img src={logo} alt="" style={{ width: 200 }} />
//                     <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
//                 </div>
//                 <div class="offcanvas-body">
//                     <ul className="nav nav-pills flex-column mb-auto px-3">
//                         {sideBarLinks.map(renderLinks)}
//                     </ul>
//                 </div>
//             </div>
//         </nav>
//     )
// }

// export default Navbar


import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Icons from '../ui/Icons';
import logo from '../../assets/images/logo_sudikshya.png';
import SignOut from './SignOut';

export const sideBarLinks = [
    { to: "/", text: "Dashboard", icon: Icons.dashboard },
    { to: "/order", text: "Order Management", icon: Icons.order },
    { to: "/team", text: "Team Management", icon: Icons.team },
    { to: "/student", text: "Student Management", icon: Icons.student },
    { to: "/product", text: "Product", icon: Icons.product, items: [{ text: "Staffs", to: "/product/staffs" }, { text: "Courses", to: "/product/courses" }, { text: "Category", to: "/product/category" }] },
    { to: "/document", text: "Document Management", icon: Icons.document },
    { to: "/lead", text: "Leads", icon: Icons.leads },
    { to: "/report", text: "Report", icon: Icons.report },
    { to: "/settings", text: "Settings", icon: Icons.settings },
];

const Navbar = () => {
    const location = useLocation();

    useEffect(() => {
      document.getElementById('bgbg').click()
    }, [location.pathname]);

    const navWrapItem = (link) => {
        return (
            <li className="nav-item py-1" key={link.text}>
                <button className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed nav-link" data-bs-toggle="collapse" data-bs-target={`#${link.text}-collapse`} aria-expanded="true">
                    {link.icon("me-2", { width: 20, height: 20 })}
                    {link.text}
                </button>
                <div className="collapse show" id={`${link.text}-collapse`} bis_skin_checked="1">
                    <ul className="list-unstyled bg-light rounded-3 p-3">
                        {link.items.map((item) => <li key={item.text}><NavLink to={item.to} className="link-dark d-inline-flex text-decoration-none rounded">{item.text}</NavLink></li>)}
                    </ul>
                </div>
            </li>
        );
    };

    const navItem = (link) => {
        return (
            <li key={link.text} className="nav-item py-1">
                <NavLink to={link.to} className="nav-link d-flex align-items-center" >
                    {link.icon("me-2", { width: 20, height: 20 })}
                    {link.text}
                </NavLink>
            </li>
        );
    };

    const renderLinks = (link) => (link.items ? navWrapItem(link) : navItem(link));

    return (
        <nav className="navbar navbar-expand-lg bg-primary d-lg-none px-3">
            <h3 className="fw-bold text-light">Sudikshya</h3>
            <button className="navbar-toggler bg-light" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <div className="offcanvas-header">
                    <img src={logo} alt="" style={{ width: 200 }} />
                    <button type="button"  id='bgbg' className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <ul className="nav nav-pills flex-column mb-auto px-3">
                        {sideBarLinks.map(renderLinks)}
                        <SignOut />
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
