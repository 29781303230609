import React, { useEffect, useState } from 'react'
import Input from '../../components/form/Input'
import { useCreatePaymentFromAdminMutation, useGetAssociatesQuery, usePostAssociateMutation } from '../../services/associateSlice'
import { useGetDesignationsQuery } from '../../services/designationSlice'
import ApiErrorModal from '../../components/modals/ApiErrorModal'
import SuccessModal from '../../components/modals/SuccessModal'
import FileInput from '../../components/form/FileInput'
import SectionHeader from '../../components/form/SectionHeader'
import Section from '../../components/form/Section'
import SectionBody from '../../components/form/SectionBody'
import SelectInput from '../../components/form/SelectInput'
import Loading from '../../components/ui/Loading'
import { useNavigate, useParams } from 'react-router-dom'
import AddharInput from '../../components/form/AddharInput'
import SearchSelectInput from '../../components/form/SearchSelectInput'
import { useGetPackageQuery } from '../../services/adminSlice'

const GENDER = {
    MALE: "Male",
    FEMALE: "Female",
    OTHERS: "Others",
}

const QUALIFICATION = {
    PASSED_TEN: "10th Pass",
    PASSED_PLUS_TWO: "+2 Pass",
    PASSED_PLUS_THREE: "+3 Pass",
}

const RANK = {
    ZONAL_MANAGER: "Zonal Manager",
    GENERAL_MANAGER: "General Manager",
    COMMERCIALS_MANAGER: "Commercials Manager",
    SURVEY_MANAGER: "Survey Manager",
    SURVEY_EXECUTIVE: "Survey Executive",
    SURVEY_REPORTER: "Survey Reporter",
}

const stateOptions = [
    { label: "Andhra Pradesh", value: "Andhra Pradesh" },
    { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
    { label: "Assam", value: "Assam" },
    { label: "Bihar", value: "Bihar" },
    { label: "Chhattisgarh", value: "Chhattisgarh" },
    { label: "Goa", value: "Goa" },
    { label: "Gujarat", value: "Gujarat" },
    { label: "Haryana", value: "Haryana" },
    { label: "Himachal Pradesh", value: "Himachal Pradesh" },
    { label: "Jharkhand", value: "Jharkhand" },
    { label: "Karnataka", value: "Karnataka" },
    { label: "Kerala", value: "Kerala" },
    { label: "Madhya Pradesh", value: "Madhya Pradesh" },
    { label: "Maharashtra", value: "Maharashtra" },
    { label: "Manipur", value: "Manipur" },
    { label: "Meghalaya", value: "Meghalaya" },
    { label: "Mizoram", value: "Mizoram" },
    { label: "Nagaland", value: "Nagaland" },
    { label: "Odisha", value: "Odisha" },
    { label: "Punjab", value: "Punjab" },
    { label: "Rajasthan", value: "Rajasthan" },
    { label: "Sikkim", value: "Sikkim" },
    { label: "Tamil Nadu", value: "Tamil Nadu" },
    { label: "Telangana", value: "Telangana" },
    { label: "Tripura", value: "Tripura" },
    { label: "Uttar Pradesh", value: "Uttar Pradesh" },
    { label: "Uttarakhand", value: "Uttarakhand" },
    { label: "West Bengal", value: "West Bengal" },
    { label: "Andaman and Nicobar Islands", value: "Andaman and Nicobar Islands" },
    { label: "Chandigarh", value: "Chandigarh" },
    { label: "Dadra and Nagar Haveli and Daman and Diu", value: "Dadra and Nagar Haveli and Daman and Diu" },
    { label: "Lakshadweep", value: "Lakshadweep" },
    { label: "Delhi", value: "Delhi" },
    { label: "Puducherry", value: "Puducherry" },
]

const CreateAssociate = () => {

    const { id } = useParams();



    const [postAssociate, postAssociateResponse] = usePostAssociateMutation()
    const [createPayment, createPaymentRes] = useCreatePaymentFromAdminMutation()

    const getPackage = useGetPackageQuery(1)
    const getDesignations = useGetDesignationsQuery()
    const getAssociates = useGetAssociatesQuery()
    const navigate = useNavigate()

    const [gender, setGender] = useState(GENDER.MALE);
    const [rank, setRank] = useState("");
    const [ranks, setRanks] = useState([]);
    const [sponsor, setSponsor] = useState("1");
    const [qualification, setQualification] = useState(QUALIFICATION.PASSED_TEN);

    const activeClassName = 'btn btn-primary rounded-pill px-4 py-0 me-2 mb-2 lh-sm';
    const inactiveClassName = 'btn btn-outline-primary rounded-pill px-4 py-0 me-2 mb-2 lh-sm';

    const btnClassName = "btn btn-success rounded-pill btn-lg px-5 text-white"

    const renderGender = (selected, current) => <div className={selected === current ? activeClassName : inactiveClassName} onClick={() => setGender(current)}>{current}</div>
    const renderQualification = (selected, current) => <div className={selected === current ? activeClassName : inactiveClassName} onClick={() => setQualification(current)}>{current}</div>

    const renderRank = (selectedId, current) => (
        <div className="col">
            <div
                className={selectedId === current?.id ? activeClassName : inactiveClassName}
                onClick={() => setRank(current.id)}
            >
                {current?.name}
            </div>
        </div>
    );

    useEffect(() => {
        if (getAssociates.data && id) {
            setSponsor(id);
            console.log(id);
        }
    }, [getAssociates.data, id]);

    useEffect(() => {
        if (getDesignations.data) {
            const defaultDesignation = getDesignations?.data?.find(item => item.isDefault === '1');
            if (defaultDesignation?.id) setRank(defaultDesignation?.id);
        }
    }, [getDesignations.data]);

    useEffect(() => {
        const computeRanks = (designationId, processedDesignations = new Set()) => {
            let arr = [];
            const designation = getDesignations?.data?.find((item) => item.id === designationId);
            const parentDesignationId = designation?.designationId;

            if (!processedDesignations.has(designationId)) {
                arr.push(designation);
                processedDesignations.add(designationId);
            }

            // Add siblings of the current designation
            const siblings = getDesignations?.data?.filter((item) => item.designationId === parentDesignationId && item.id !== designationId && !processedDesignations.has(item.id));
            siblings.forEach((sibling) => {
                if (!processedDesignations.has(sibling.id)) {
                    arr.push(sibling);
                    processedDesignations.add(sibling.id);
                }
            });

            // Add children of the current designation
            const children = getDesignations?.data?.filter((item) => item.designationId === designationId && !processedDesignations.has(item.id));
            children.forEach((child) => {
                if (!processedDesignations.has(child.id)) {
                    arr.push(child);
                    processedDesignations.add(child.id);
                    arr = [...arr, ...computeRanks(child.id, processedDesignations)];
                }
            });

            return arr;
        };

        if (sponsor.length > 0 && getDesignations.data && getAssociates.data) {
            const sponsorData = getAssociates.data.find((item) => item.associate.id === sponsor);
            if (sponsorData) {
                const sponsorDesignationId = sponsorData.designation.id;
                setRanks(computeRanks(sponsorDesignationId));
            } else {
                setRanks([]);
            }
        } else if (getDesignations.data) {
            setRanks(getDesignations.data);
        }
    }, [sponsor, getDesignations.data, getAssociates.data]);



    const handleSubmit = (e) => {

        e.preventDefault();
        const form = e.target

        const aadharFrontFileInput = form["Aadhar Front"];
        const aadharBackFileInput = form["Aadhar Back"];
        const passPhotoFileInput = form["Passphoto"];

        const imageCondition = aadharFrontFileInput?.files?.length > 0 && aadharBackFileInput?.files?.length > 0 && passPhotoFileInput?.files?.length > 0
        if (rank === undefined || rank === null || rank === '') {
            alert("Please select a rank."); // Display an error message or handle as needed
            return; // Exit the function to prevent form submission
        }

        if (form.checkValidity()) {

            const body = new FormData()
            body.append("name", form["Associate Name"].value)
            body.append("mobileNumber", form["Mobile Number"].value)
            body.append("fatherName", form["Father’s Name"].value)
            body.append("dob", form["Date of Birth"].value)
            body.append("alternateMobileNumber", form["Alternate Mobile Number"].value)
            body.append("aadharNumber", form["Aadhar Number"].value)
            body.append("gender", gender)
            body.append("qualification", qualification)
            body.append("otherQualification", form["Other Qualification"].value)
            body.append("designationId", rank)
            body.append("associateId", sponsor)
            body.append("personalStudentId", form["Personal Student ID"].value)

            body.append("address[flatNumber]", form["Address One"].value);
            body.append("address[addressLine1]", form["Address Two"].value);
            body.append("address[landmark]", form["Landmark"].value);
            body.append("address[state]", form["State"].value);
            body.append("address[district]", form["Dist"].value);
            body.append("address[cityVillage]", form["City/Village"].value);
            body.append("address[pin]", form["Zip Code"].value);

            body.append("paymentMode", form["Mode of Payment"].value)
            body.append("refId", form["Ref. Id"].value || ' ')
            body.append("registrationDate", form["Registration Date"].value)


            body.append("aadharFront", aadharFrontFileInput.files[0]);
            body.append("aadharBack", aadharBackFileInput.files[0]);
            body.append("passPhoto", passPhotoFileInput.files[0]);

            postAssociate(body).then(async (res) => {
                if (!res.error) {
                    const paymentbody = {
                        id: res.data.associateId,
                        amount: getPackage.data.packagePrice
                    }
                    await createPayment(paymentbody);
                    getAssociates.refetch()
                    getPackage.refetch()
                    getDesignations.refetch()
                    navigate(-1)
                }
            })
        }
        else {
            form.classList.add('was-validated')
        }


    }

    const calculateEighteenYearsAgo = () => {
        const today = new Date();
        const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
        return eighteenYearsAgo.toISOString().split('T')[0];
    };


    if (getAssociates.isLoading || getDesignations.isLoading || getPackage.isLoading) return <Loading />
    if (getAssociates.isError) return <ApiErrorModal response={getAssociates} />
    if (getDesignations.isError) return <ApiErrorModal response={getDesignations} />


    const sponsorOptions = [
        ...[{ label: "No Sponsor", value: "" }],
        ...getAssociates.data.filter((item) => item.associate.isActive == '1')?.filter(item => item?.associate?.status == 'Approved').map((item) => ({
            label: item?.associate?.serialNo + " " + item?.associate?.name + " - " + item?.designation?.name, value: item?.associate?.id,
            serialNo: item?.associate?.serialNo,
        }))
    ]


    console.log('ranks', ranks)

    return (
        <div className='container p-3 p-lg-5'>

            {/* {postAssociateResponse.isSuccess && <SuccessModal message={"Associate has been created."} />} */}
            {postAssociateResponse.isError && <ApiErrorModal response={postAssociateResponse} />}
            {createPaymentRes.isError && <ApiErrorModal response={createPaymentRes} />}
            <h4 className='fw-bold mb-3'>Create Associate</h4>

            <form onSubmit={handleSubmit} noValidate>

                <Section title="Personal Details" className="row-cols-1 row-cols-lg-3 g-3 mb-3" >
                    <Input name={"Associate Name"} required />
                    <Input name={"Mobile Number"} type="tel" minLength={10} maxLength={10} required />
                    <Input name={"Father’s Name"} required />
                    <Input type={"date"} name={"Date of Birth"} max={calculateEighteenYearsAgo()} required />
                    <Input name={"Alternate Mobile Number"} type="tel" minLength={10} maxLength={10} />
                    <AddharInput name={"Aadhar Number"} type="tel" minLength={14} maxLength={14} required />
                    <Input containerClass="me-3" name={"Other Qualification"} />
                </Section>

                <SectionBody className="row-cols-1 row-cols-lg-2 g-3 mb-5">
                    <div className='col'>
                        <div className="d-flex">
                            <h6 className='text-primary fw-bold me-3'>Gender:</h6>
                            <div className='d-flex'>
                                {renderGender(gender, GENDER.MALE)}
                                {renderGender(gender, GENDER.FEMALE)}
                                {renderGender(gender, GENDER.OTHERS)}
                            </div>
                        </div>
                    </div>
                    <div className='col'>
                        <div className="d-flex">
                            <h6 className='text-primary fw-bold me-3'>Qualification:</h6>
                            <div className='d-flex'>
                                {renderQualification(qualification, QUALIFICATION.PASSED_TEN)}
                                {renderQualification(qualification, QUALIFICATION.PASSED_PLUS_TWO)}
                                {renderQualification(qualification, QUALIFICATION.PASSED_PLUS_THREE)}
                            </div>
                        </div>
                    </div>
                </SectionBody>

                <Section title="Address" className="row-cols-1 row-cols-lg-3 g-3 mb-5" >
                    <Input name={"Address One"} required />
                    <Input name={"Address Two"} />
                    <Input name={"Landmark"} />
                    {/* <Input name={"State"} /> */}
                    <SelectInput name={"State"} options={stateOptions} defaultValue='Odisha' />
                    <Input name={"Dist"} required />
                    <Input name={"City/Village"} required />
                    <Input containerClass="col" name={"Zip Code"} type="tel" minLength={6} maxLength={6} required />
                    <FileInput name={"Aadhar Front"} required />
                    <FileInput name={"Aadhar Back"} required />
                </Section>

                <Section title="Rank & Sponsor" className="row-cols-2 row-cols-lg-6 g-3 mb-3" >
                    {ranks.map((item) => renderRank(rank, item))}
                </Section>
                <SectionBody className="row-cols-1 row-cols-lg-3 g-3 mb-5">
                    {/* <Input name={"Sponsor’s Associate ID"} required /> */}
                    {/* <SelectInput name={"Sponsor’s Associate ID"} options={sponsorOptions} value={sponsor} onChange={(e) => setSponsor(e.target.value)} /> */}


                    <SearchSelectInput
                        name={"Sponsor’s Associate ID"}
                        options={sponsorOptions}
                        value={sponsor} defaultValue={sponsor} onChange={(val) => setSponsor(val)}
                        containerClass={''}
                        width={380}
                    />


                    <Input name={"Personal Student ID"} />
                    <FileInput name={"Passphoto"} required />


                    <SelectInput
                        name={"Mode of Payment"}
                        options={
                            [
                                { value: 'Cash', label: 'Cash' },
                                { value: 'UPI', label: 'UPI' },
                                { value: 'NEFT', label: 'NEFT' },
                                { value: 'IMPS', label: 'IMPS' },
                                { value: 'Cheque', label: 'Cheque' },
                                { value: 'Card', label: 'Card' },
                            ]
                        }
                    />
                    <Input containerClass="" name={"Ref. Id"} />
                    <Input type={'date'} containerClass="" name={"Registration Date"} />

                </SectionBody>


                {
                    postAssociateResponse.isLoading ?
                        <button class={btnClassName} type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Creating...
                        </button>
                        :
                        <button type='submit' className={btnClassName}>Create</button>
                }



            </form>


        </div>
    )
}

export default CreateAssociate