import React, { useEffect, useState } from 'react'
import Input from '../../components/form/Input'
import { useCreateStudentPaymentFromAdminMutation, usePostStudentMutation } from '../../services/studentSlice'
import SelectInput from '../../components/form/SelectInput'
import { useGetAssociatesQuery } from '../../services/associateSlice'
import { useGetProductsQuery } from '../../services/productSlice'
import { useGetCategoriesQuery } from '../../services/categorySlice'
import SuccessModal from '../../components/modals/SuccessModal'
import ApiErrorModal from '../../components/modals/ApiErrorModal'
import Loading from '../../components/ui/Loading'
import SearchSelectInput from '../../components/form/SearchSelectInput'
import { useParams } from 'react-router-dom'

const CreateStudent = () => {

    const { id } = useParams();

    const [postStudent, postStudentResponse] = usePostStudentMutation()
    const [createPayment, createPaymentRes] = useCreateStudentPaymentFromAdminMutation()

    const sponserData = useGetAssociatesQuery()
    const productData = useGetProductsQuery()
    const categoryData = useGetCategoriesQuery()
    const btnClassName = 'btn btn-primary mt-5 float-end'

    const [category, setCategory] = useState();

    const [sponsor, setSponsor] = useState("1");
    const [selectedProduct, setSelectedProduct] = useState(null);

    const handleProductChange = (selectedOption) => {
        setSelectedProduct(selectedOption);
    };


    useEffect(() => {

        if (sponserData.isSuccess && id) {
            setSponsor(id)
            console.log(id);
        }

    }, [sponserData.isSuccess])


    const handleSubmit = (e) => {

        e.preventDefault();

        const form = e.target;

        if (form.checkValidity()) {

            const body = {
                studentName: form['Student Name'].value,
                mobileNumber: form["Mobile Number"].value,
                associateId: sponsor,
                adminId: 0,//current admin id from login
                appStudentId: form["App Student ID"].value,
                courseId: form["Product"].value,
                modeOfPayment: form["Mode of Payment"].value,
                refId: form["Ref. Id"].value || ' ',
                parentName: form["Parent Name"].value,
                registrationDate: form["Registration Date"].value,
            }
            postStudent(body)
                .then(async (res) => {
                    if (!res.error) {
                        const paymentbody = {
                            id: res.data.studentId,
                            amount: res.data.amount
                        }
                        await createPayment(paymentbody);

                        form.classList.remove('was-validated')
                        form.reset()
                    }
                })
        }
        else {
            form.classList.add('was-validated')
        }
    }



    if (sponserData.isLoading || productData.isLoading || categoryData.isLoading) return <Loading />

    return (
        <div className='container p-3 p-lg-5'>

            {postStudentResponse.isSuccess && <SuccessModal message={"Student has been created."} />}
            {postStudentResponse.isError && <ApiErrorModal response={postStudentResponse} />}

            <h4 className='fw-bold mb-3'>Create Student</h4>

            <h5 className='text-muted mb-3'>Personal Details</h5>
            <form onSubmit={handleSubmit} noValidate>
                <div className='row row-cols-1 row-cols-lg-3 g-3'>
                    <Input containerClass="" name={"Student Name"} required />
                    <Input name={"Mobile Number"} type="tel" minLength={10} maxLength={10} required />
                    {/* 
                    <SelectInput
                        name="SponsorName"
                        options={
                            sponserData?.data?.map((sponsor) => ({
                                value: sponsor?.associate?.id,
                                label: sponsor?.associate?.id + " " + sponsor?.associate?.name,
                            })) || []
                        }
                    /> */}

                    <SearchSelectInput
                        name={"Sponsor’s Associate ID"}
                        options={
                            sponserData?.data
                            ?.filter(item => item?.associate?.status == 'Approved')
                            .map((sponsor) => ({
                                value: sponsor?.associate?.id,
                                label: sponsor?.associate?.name,
                                serialNo: sponsor?.associate?.serialNo,
                            })) || []
                        }
                        value={sponsor} defaultValue={sponsor} onChange={(val) => setSponsor(val)}
                        containerClass={''}
                        width={400}

                    />



                    <Input containerClass="" name={"App Student ID"}  />
                    <Input containerClass="" name={"Parent Name"} required />
                </div>


                <h5 className='text-muted mt-5 mb-3'>Product Details</h5>
                <div className='row row-cols-1 row-cols-lg-3 g-3'>

                    <SelectInput
                        name="Category"
                        options={[
                            ...[{
                                value: '',
                                label: 'Select',
                            }],
                            ...categoryData?.data?.map((item) => ({
                                value: item.id,
                                label: item.name,
                            }))
                        ]}

                        value={category} onChange={(e) => setCategory(e.target.value)}
                    />



                    <SelectInput
                        name="Product"
                        options={
                            productData?.data?.filter((item) => {
                                return item.course.categoryId === category
                            }).map((item) => ({
                                value: item.course.id,
                                label: item.course.name,
                            })) || []
                        }
                    />

                </div>

                
                <div className='row row-cols-1 mt-3 row-cols-lg-3 g-3'>
                    {/* <Input containerClass="" name={"Mode of Payment"} required /> */}
                    <SelectInput
                        name={"Mode of Payment"}
                        options={
                            [
                                { value: 'Cash', label: 'Cash' },
                                { value: 'UPI', label: 'UPI' },
                                { value: 'NEFT', label: 'NEFT' },
                                { value: 'IMPS', label: 'IMPS' },
                                { value: 'Cheque', label: 'Cheque' },
                                { value: 'Card', label: 'Card' },
                            ]
                        }
                    />
                    <Input containerClass="" name={"Ref. Id"} />

                    <Input type={'date'} containerClass="" name={"Registration Date"} />
                </div>

                {
                    postStudentResponse.isLoading ?
                        <button class={btnClassName} type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Creating...
                        </button>
                        :
                        <button type='submit' className={btnClassName}>Create</button>
                }

            </form>

        </div>
    )
}

export default CreateStudent