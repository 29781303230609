import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import Input from '../../components/form/Input'
import Icons from '../../components/ui/Icons'
import SelectInput from '../../components/form/SelectInput'
import { useGetProductsQuery } from '../../services/productSlice'
import Loading from '../../components/ui/Loading'
import { useGetStudentsQuery } from '../../services/studentSlice'
import { useGetDesignationsQuery } from '../../services/designationSlice'
import { useGetAssociatesQuery } from '../../services/associateSlice'
import { convertToFiveDigits } from '../../utils/ConvertToFive'
import ReactToPrint from 'react-to-print';
import { useEffect } from 'react'

const DesignationUserReport = () => {

  const [selectValue, setSelectValue] = useState("")
  const [searchValue, setSearchValue] = useState("")

  const [selectFilter, setSelectFilter] = useState("")
  const [fdate, setFdate] = useState(null)



  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // const [totalAmount, setTotalAmount] = useState("")

  const modalBodyRef = useRef();



  const designationRes = useGetDesignationsQuery()
  const response = useGetAssociatesQuery()

  function convertToFourDigits(number) {

    return String(number).padStart(4, '0');
  }

  useEffect(() => {
    let filterStartDate;
    let filterEndDate;

    if (selectFilter === 'last 7 days') {
      filterEndDate = new Date();
      filterStartDate = new Date(new Date().setDate(new Date().getDate() - 6));
    } else if (selectFilter === 'last month') {
      filterEndDate = new Date();
      filterStartDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
    }

    //  else if (selectFilter === 'Previous year') {
    //   filterEndDate = new Date(new Date().getFullYear() - 1, 11, 31);
    //   filterStartDate = new Date(new Date().getFullYear() - 1, 0, 1);
    // } else if (selectFilter === 'Current year') {
    //   filterEndDate = new Date();
    //   filterStartDate = new Date(new Date().getFullYear(), 0, 1);
    // }

    else if (selectFilter === 'Previous year') {
      filterStartDate = new Date(new Date().getFullYear() - 1, 3, 1); // 1st April of previous year
      filterEndDate = new Date(new Date().getFullYear(), 2, 31); // 31st March of current year
    } else if (selectFilter === 'Current year') {
      filterStartDate = new Date(new Date().getFullYear(), 3, 1); // 1st April of current year
      filterEndDate = new Date(new Date().getFullYear() + 1, 2, 31); // 31st March of next year
    }


    else if (selectFilter === 'Current day') {
      filterEndDate = new Date();
      filterStartDate = new Date();
      filterStartDate.setHours(0, 0, 0, 0);
      filterEndDate.setHours(23, 59, 59, 999);
    } else if (selectFilter === 'Yesterday') {
      filterEndDate = new Date(new Date().setDate(new Date().getDate() - 1));
      filterStartDate = new Date(new Date().setDate(new Date().getDate() - 1));
      filterStartDate.setHours(0, 0, 0, 0);
      filterEndDate.setHours(23, 59, 59, 999);
    } else if (selectFilter === 'This Week') {
      const today = new Date();
      const dayOfWeek = today.getDay();
      filterStartDate = new Date(today.setDate(today.getDate() - dayOfWeek));
      filterEndDate = new Date();
      filterStartDate.setHours(0, 0, 0, 0);
      filterEndDate.setHours(23, 59, 59, 999);
    } else if (selectFilter === 'Current Month') {
      const today = new Date();
      filterStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
      filterEndDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      filterStartDate.setHours(0, 0, 0, 0);
      filterEndDate.setHours(23, 59, 59, 999);
    }
    else if (selectFilter === 'Custom' && startDate && endDate) {
      filterStartDate = new Date(startDate);
      filterEndDate = new Date(endDate);
    }
    setStartDate(filterStartDate)
    setEndDate(filterEndDate)
  }, [response.isSuccess, designationRes.isSuccess, selectFilter]);



  if (designationRes.isLoading || response.isLoading) return <Loading />

  return (
    <div className='p-5'>
    

      <h4 className='text-primary fw-bold mb-3'>Reports</h4>

      <div className='d-flex mb-3 flex-wrap align-items-center justify-content-between mb-5'>

        <div className='d-flex gap-4'>

          <SelectInput
            name={"Select Designation"}
            options={[
              ...[{
                value: '',
                label: 'Select',
              }],
              ...designationRes?.data?.map((item) => ({
                value: item?.id,
                label: item?.name,
              }))
            ]}

            value={selectValue} onChange={(e) => setSelectValue(e.target.value)}
            containerClass={'w-100'}
            required
          />

          <SelectInput
            name="Select Filter"
            options={[
              { value: '', label: 'Select' },
              { value: 'Current day', label: 'Today' },
              { value: 'Yesterday', label: 'Yesterday' },
              { value: 'This Week', label: 'This Week' },
              { value: 'Current Month', label: 'Current Month' },
              { value: 'last month', label: 'Previous month' },
              { value: 'Current year', label: 'Current year' },
              { value: 'Previous year', label: 'Previous year' },
              { value: 'Custom', label: 'Custom' },
            ]}
            value={selectFilter}
            onChange={(e) => setSelectFilter(e.target.value)}
            containerClass="w-100"
            required
          />

          {selectFilter === 'Custom' && (
            <>
              <div className=" mt-4 ">
                <input
                  type="date"
                  className="form-control"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div className="mt-4">
                <input
                  type="date"
                  className="form-control"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </>
          )}
        </div>


        <div className='d-flex align-items-center'>


          <ReactToPrint
            trigger={() => (
              <div className='bg-primary  rounded text-center px-2 py-1 text-nowrap'>
                <span className="text-white me-1">Download</span> {Icons.download('')}
              </div>
            )}
            content={() => modalBodyRef.current}
            pageStyle={`@page { size: A5; margin: 0; }`}
          />

        </div>

      </div>

      <div className='d-flex justify-content-between mb-3'>

        <div className='bg-primary text-white rounded-3 p-3 me-3'>{Icons.report()}</div>


        <div className='flex-grow-1'></div>

        <Input name={"Search Invoice"} containerClass={"ms-3"} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
      </div>

      {
        selectValue && <div className='card rounded-4 overflow-hidden shadow '>

        <div className="table-responsive">
        <table className="table table-borderless table-hover" ref={modalBodyRef}>
            <thead>

              {/* 04 - Associate Report by Designation
Select Designation → Associate ID, Date, Name, Mobile Number, Designation Sponsor, Team size */}

              <tr className='border-bottom'>
                {/* <th scope="col" className='text-muted'>Invoice ID</th> */}
                <th scope="col" className='text-muted'>Associate ID</th>
                <th scope="col" className='text-muted'>Date</th>
                <th scope="col" className='text-muted'>Name</th>
                <th scope="col" className='text-muted'>Mobile No</th>
                <th scope="col" className='text-muted'>Designation</th>
                <th scope="col" className='text-muted'>Sponsor</th>
                <th scope="col" className='text-muted'>Team Size</th>
              </tr>
            </thead>
            <tbody>
              {response.data
                ?.filter((item) => item.associate.status == 'Approved')
                ?.filter((item) => {

                  if (searchValue.length === 0 && selectValue.length === 0) return true
                  const isSearchMatch = searchValue?.length > 0 && (
                    `S2A${convertToFiveDigits(item?.associate?.serialNo)}`.toLowerCase().includes(searchValue.toLowerCase())
                  );
                  const isProductMatch = selectValue.length > 0 && item?.associate.designationId === selectValue;

                  if (searchValue.length === 0 && isProductMatch) return (isProductMatch);
                  if (selectValue.length === 0 && isSearchMatch) return (isSearchMatch);

                  return (isSearchMatch && isProductMatch);
                })
                .filter((item) => {
                  if (startDate === null || endDate === null || startDate === '' || endDate === '' || selectFilter == '') return true;
                  const studentDate = new Date(item?.student?.createdAt);
                  const associateDate = new Date(item?.associate?.createdAt);
                  const startDateObj = new Date(startDate);
                  const endDateObj = new Date(endDate);
                  return (studentDate >= startDateObj && studentDate <= endDateObj) ||
                    (associateDate >= startDateObj && associateDate <= endDateObj);
                })

                .map((item, i) =>
                  <tr key={i}>

                    <th style={{ backgroundColor: item?.designation?.color + "88" }} scope="row">S2A{convertToFiveDigits(item?.associate?.serialNo)}</th>
                    <th style={{ backgroundColor: item?.designation?.color + "88" }} className=''>{item?.associate?.createdAt && !isNaN(new Date(item?.associate?.createdAt)) ? new Date(item?.associate?.createdAt).toLocaleDateString('en-GB').split('/').join('-') : ''}</th>
                    <th style={{ backgroundColor: item?.designation?.color + "88" }}>{item?.associate?.name}</th>
                    <th style={{ backgroundColor: item?.designation?.color + "88" }}>{item?.associate?.mobileNumber}</th>
                    <th style={{ backgroundColor: item?.designation?.color + "88" }}>{item?.designation?.name}</th>
                    <th style={{ backgroundColor: item?.designation?.color + "88" }}>{item?.sponsor?.name}</th>
                    <th style={{ backgroundColor: item?.designation?.color + "88" }}>{item?.allAssoc?.length + item?.allStudent?.length}</th>
                  </tr>
                )}


            </tbody>
          </table>
        </div>

        </div>
      }

    </div>
  )
}

export default DesignationUserReport