import React from 'react'

const Input = ({ name, placeholder, type, containerClass, inputClass, ...attributes }) => {


    const containerClassName = "d-flex flex-column align-items-start col " + (containerClass ? containerClass : "");
    const inputClassName = "form-control rounded-pill px-3 border-primary border-2  " + (inputClass ? inputClass : "");
    const inputType = type ? type : "text";
    const lableClass = "fw-bold mb-1 ms-1";
   

    return (
        <div className={containerClassName}>
           <label htmlFor={name} className={lableClass}>{placeholder ? placeholder : name}</label>
            <input
                type={inputType}
                className={inputClassName}
                id={name}
                name={name}
                placeholder={placeholder ? placeholder : name}
                {...attributes}
            />
        </div>
    )
}

export default Input