import React from 'react'
import Input from '../../components/form/Input'
import SelectInput from '../../components/form/SelectInput'
import { useGetAssociatesQuery } from '../../services/associateSlice'
import { usePostProductMutation } from '../../services/productSlice'
import { useGetCategoriesQuery } from '../../services/categorySlice'
import SuccessModal from '../../components/modals/SuccessModal'
import ApiErrorModal from '../../components/modals/ApiErrorModal'

const CreateProduct = () => {

    const [postProduct, postProductResponse] = usePostProductMutation()
    const categoryData = useGetCategoriesQuery()


    const handleSubmit = (e) => {

        e.preventDefault();
        const form = e.target;

        if (form.checkValidity()) {
            const body = {
                name: e.target['Name'].value,
                description: e.target['Description'].value,
                amount: e.target["Amount"].value,
                categoryId: e.target["Category Id"].value,
                // order: e.target["Order"].value
            }

            postProduct(body)
                .then(() => {
                    categoryData.refetch()
                    form.classList.remove('was-validated')
                    form.reset()
                })
        }
        else {
            form.classList.add('was-validated')
        }
    }

    return (
        <div className='container p-5'>

            {postProductResponse.isSuccess && <SuccessModal message={"Product has been created."} />}
            {postProductResponse.isError && <ApiErrorModal response={postProductResponse} />}

            <h4 className='fw-bold mb-3'>Create Product</h4>

            <h5 className='text-muted mb-3'> Details</h5>
            <form onSubmit={handleSubmit} noValidate>
                <div className='row row-cols-3 g-3'>
                    <Input containerClass="" name={"Name"} required />
                    <Input containerClass="" name={"Description"} required />
                    <Input containerClass="" type={"number"} name={"Amount"} required />
                    {/* <Input containerClass="" name={"Category Id"} /> */}
                    <SelectInput
                        name={"Category Id"}
                        options={
                            categoryData?.data?.map((item) => ({
                                value: item.id,
                                label: item.name,
                            })) || []
                        }
                        required
                    />
                    {/* <Input containerClass="" name={"Order"} required /> */}
                    {/* <Input containerClass="" name={"Sponser Name"} /> */}

                    {/* <SelectInput
                        name="SponsorName"
                        options={
                            sponserData?.data?.map((sponsor) => ({
                                value: sponsor.associate.id,
                                label: sponsor.associate.name,
                              })) || []
                            }
                    /> */}
                </div>

                {
                    postProductResponse.isLoading ?
                        <button class='btn btn-primary mt-5 float-end' type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Submitting...
                        </button>
                        :
                        <button className='btn btn-primary mt-5 float-end'>Submit</button>
                }



            </form>

        </div>
    )
}

export default CreateProduct