import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Input from '../../components/form/Input'
import Icons from '../../components/ui/Icons'
import SelectInput from '../../components/form/SelectInput'
import { useGetProductsQuery } from '../../services/productSlice'
import Loading from '../../components/ui/Loading'
import { useGetStudentsQuery } from '../../services/studentSlice'
import { useGetAssociatesQuery } from '../../services/associateSlice'
import ReactToPrint from 'react-to-print';
import { useParams } from 'react-router-dom';
import { convertToFiveDigits } from '../../utils/ConvertToFive'
import SearchSelectInput from '../../components/form/SearchSelectInput'



const UserSalesReport = () => {

  const [selectValue, setSelectValue] = useState("")
  const [totalSales, setTotalSales] = useState(0);
  const [fdate, setFdate] = useState(null)
  const [selectFilter, setSelectFilter] = useState("")

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const { id } = useParams();

  let count = 1;
  const modalBodyRef = useRef();
  const response = useGetAssociatesQuery()


  useEffect(() => {
    // Calculate total sales when the selectValue or response.data changes
    calculateTotalSales();
  }, [selectValue, response.data]);

  const calculateTotalSales = () => {
    let total = 0;

    response.data
      ?.filter((item) => {
        if (startDate === null || endDate === null || startDate === '' || endDate === '' || selectFilter == '') return true;
        const associateDate = new Date(item?.associate?.createdAt);
        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);
        return (associateDate >= startDateObj && associateDate <= endDateObj);
      })
      ?.forEach((item) => {

        if (selectValue.length === 0 || item?.associate?.id === selectValue) {
          item.allStudent.forEach((subItem) => {
            subItem?.payments?.forEach((payment) => {
              total += parseFloat(payment.amount);
            });
          });
        }
      });

    setTotalSales(total);
  };




  useEffect(() => {

    if (response.isSuccess && id) {
      setSelectValue(id)
      console.log(id);
    }

  }, [response.isSuccess])

  useEffect(() => {
    let filterStartDate;
    let filterEndDate;

    if (selectFilter === 'last 7 days') {
      filterEndDate = new Date();
      filterStartDate = new Date(new Date().setDate(new Date().getDate() - 6));
    } else if (selectFilter === 'last month') {
      filterEndDate = new Date();
      filterStartDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
    }

    // else if (selectFilter === 'Previous year') {
    //   filterEndDate = new Date(new Date().getFullYear() - 1, 11, 31);
    //   filterStartDate = new Date(new Date().getFullYear() - 1, 0, 1);
    // } else if (selectFilter === 'Current year') {
    //   filterEndDate = new Date();
    //   filterStartDate = new Date(new Date().getFullYear(), 0, 1);
    // }

    else if (selectFilter === 'Previous year') {
      filterStartDate = new Date(new Date().getFullYear() - 1, 3, 1); // 1st April of previous year
      filterEndDate = new Date(new Date().getFullYear(), 2, 31); // 31st March of current year
    } else if (selectFilter === 'Current year') {
      filterStartDate = new Date(new Date().getFullYear(), 3, 1); // 1st April of current year
      filterEndDate = new Date(new Date().getFullYear() + 1, 2, 31); // 31st March of next year
    }


    else if (selectFilter === 'Current day') {
      filterEndDate = new Date();
      filterStartDate = new Date();
      filterStartDate.setHours(0, 0, 0, 0);
      filterEndDate.setHours(23, 59, 59, 999);
    } else if (selectFilter === 'Yesterday') {
      filterEndDate = new Date(new Date().setDate(new Date().getDate() - 1));
      filterStartDate = new Date(new Date().setDate(new Date().getDate() - 1));
      filterStartDate.setHours(0, 0, 0, 0);
      filterEndDate.setHours(23, 59, 59, 999);
    } else if (selectFilter === 'This Week') {
      const today = new Date();
      const dayOfWeek = today.getDay();
      filterStartDate = new Date(today.setDate(today.getDate() - dayOfWeek));
      filterEndDate = new Date();
      filterStartDate.setHours(0, 0, 0, 0);
      filterEndDate.setHours(23, 59, 59, 999);
    } else if (selectFilter === 'Current Month') {
      const today = new Date();
      filterStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
      filterEndDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      filterStartDate.setHours(0, 0, 0, 0);
      filterEndDate.setHours(23, 59, 59, 999);
    }
    else if (selectFilter === 'Custom' && startDate && endDate) {
      filterStartDate = new Date(startDate);
      filterEndDate = new Date(endDate);
    }
    setStartDate(filterStartDate)
    setEndDate(filterEndDate)
  }, [response.isSuccess, selectFilter]);




  if (response.isLoading) return <Loading />


  const checkData = response.data
    ?.filter((item) => {
      if (selectValue.length === 0) return true;
      return selectValue.length > 0 && item?.associate?.id === selectValue;
    })
    ?.filter((item) => item?.associate?.status == 'Approved')



  return (
    <div className='p-5'>
      <h4 className='text-primary fw-bold mb-3'>Reports</h4>
      <div className='d-flex mb-3 flex-wrap align-items-center justify-content-between mb-5'>
        <div className='d-flex gap-3'>
          <SearchSelectInput
            name={"Select Associate"}
            options={[
              ...[{
                value: '',
                label: 'Select',
              }],
              ...response?.data?.filter(item => item?.associate?.status == 'Approved')?.map((item) => ({
                value: item?.associate?.id,
                label: item?.associate?.name,
                serialNo: item?.associate?.serialNo,
              }))
            ]}
            value={selectValue} defaultValue={selectValue} onChange={(val) => setSelectValue(val)}
            containerClass={''}
          />

          <SelectInput
            name="Select Filter"
            options={[
              { value: '', label: 'Select' },
              { value: 'Current day', label: 'Today' },
              { value: 'Yesterday', label: 'Yesterday' },
              { value: 'This Week', label: 'This Week' },
              { value: 'Current Month', label: 'Current Month' },
              { value: 'last month', label: 'Previous month' },
              { value: 'Current year', label: 'Current year' },
              { value: 'Previous year', label: 'Previous year' },
              { value: 'Custom', label: 'Custom' },
            ]}
            value={selectFilter}
            onChange={(e) => setSelectFilter(e.target.value)}
            containerClass="w-100"
            required
          />

          {selectFilter === 'Custom' && (
            <>
              <div className=" mt-4 ">
                <input
                  type="date"
                  className="form-control"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div className="mt-4">
                <input
                  type="date"
                  className="form-control"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </>
          )}
        </div>


        <div className='d-flex align-items-center'>


          <ReactToPrint
            trigger={() => (
              <div className='bg-primary  rounded text-center px-2 py-1 text-nowrap'>
                <span className="text-white me-1">Download</span> {Icons.download('')}
              </div>
            )}
            content={() => modalBodyRef.current}
            pageStyle={`@page { size: A5; margin: 0; }`}
          />

        </div>

      </div>





      {
        selectValue &&

        <>
          <div className="">
            {
              checkData?.map((item, associateIndex) => (
                <div class="my-4">
                  {
                    selectValue && <>
                      <h3 class="text-primary fw-bold">#S3A{convertToFiveDigits(item?.associate.serialNo)} - {item?.associate?.name}</h3>
                      <p>{item?.associate?.mobileNumber}</p>
                      <p>Team Size: {item.allStudent?.length}</p>
                      <p>Total Invoice: {item.allStudent.filter((itm) => itm.paid == 1)?.length}</p>
                    </>
                  }
                </div>

              ))
            }
          </div>

          <div className='card rounded-4 overflow-hidden shadow '>


            <div className="table-responsive">

              <table className="table table-borderless table-hover" >
                <thead>
                  <tr className='border-bottom'>
                    {/* 03- Associate Sales Report
Select Associate → Order ID, Date, Student Name, Mobile, Associate Product, App ID, Amount. */}

                    <th scope="col" className='text-muted'>Order Id</th>
                    <th scope="col" className='text-muted'>Date</th>
                    <th scope="col" className='text-muted'>Name</th>
                    <th scope="col" className='text-muted'>Mobile No</th>
                    <th scope="col" className='text-muted'>Product</th>
                    <th scope="col" className='text-muted'>App Id</th>
                    <th scope="col" className='text-muted'>amount</th>
                  </tr>
                </thead>
                <tbody>
                  {response.data
                    ?.filter((item) => {
                      if (selectValue.length === 0) return true;
                      return selectValue.length > 0 && item?.associate?.id === selectValue;
                    })
                    ?.filter((item) => {
                      if (startDate === null || endDate === null || startDate === '' || endDate === '' || selectFilter == '') return true;
                      const associateDate = new Date(item?.associate?.createdAt);
                      const startDateObj = new Date(startDate);
                      const endDateObj = new Date(endDate);
                      return (associateDate >= startDateObj && associateDate <= endDateObj);
                    })
                    ?.filter((item) => item?.associate?.status == 'Approved')
                    .map((item, associateIndex) => (
                      <React.Fragment key={associateIndex}>
                        {item?.allStudent?.map((subItem, studentIndex) => (
                          <React.Fragment key={studentIndex}>
                            {subItem?.payments?.length > 0 ? (
                              subItem.payments.map((payment, paymentIndex) => (
                                <tr key={paymentIndex}>
                                  <th>SU{convertToFiveDigits(subItem?.orders?.id)}</th>
                                  <th>{payment?.createdAt && !isNaN(new Date(payment?.createdAt)) ? new Date(payment.createdAt).toLocaleDateString('en-GB').split('/').join('-') : 'nil'}</th>
                                  <th>{subItem?.studentName}</th>
                                  <th>{subItem?.mobileNumber}</th>
                                  <th>product</th>
                                  <th>{subItem.appStudentId}</th>
                                  <th>₹{payment.amount}</th>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <th>SU{convertToFiveDigits(subItem?.orders?.id)}</th>
                                <th>-</th> {/* No payment date available */}
                                <th>{subItem?.studentName}</th>
                                <th>{subItem?.mobileNumber}</th>
                                <th>product</th>
                                <th>{subItem.appStudentId}</th>
                                <th>-</th> {/* No payment amount available */}
                              </tr>
                            )}
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    ))}
                  <tr>
                    <th>Total Sales : </th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>₹{totalSales}</th>
                  </tr>
                </tbody>

              </table>
            </div>



          </div>
        </>

      }



      <div className='d-none'>
        <div className="mt-5 mx-2" ref={modalBodyRef}>
          <div className="" >
            {
              checkData?.map((item, associateIndex) => (
                <div class="my-4">
                  {
                    <>
                      <h3 class="text-primary fw-bold">#S3A{convertToFiveDigits(item?.associate.serialNo)} - {item?.associate?.name}</h3>
                      <p>{item?.associate?.mobileNumber}</p>
                      <p>Team Size: {item.allStudent?.length}</p>
                    </>
                  }
                </div>

              ))
            }
          </div>

          <div className='card rounded-4 overflow-hidden shadow '>



            <table className="table table-borderless table-hover" style={{ whiteSpace: 'normal', zoom: '50%' }}>
              <thead>
                <tr className='border-bottom'>
                  {/* 03- Associate Sales Report
Select Associate → Order ID, Date, Student Name, Mobile, Associate Product, App ID, Amount. */}

                  <th scope="col" className='text-muted'>Order Id</th>
                  <th scope="col" className='text-muted'>Date</th>
                  <th scope="col" className='text-muted'>Name</th>
                  <th scope="col" className='text-muted'>Mobile No</th>
                  <th scope="col" className='text-muted'>Product</th>
                  <th scope="col" className='text-muted'>App Id</th>
                  <th scope="col" className='text-muted'>amount</th>
                </tr>
              </thead>
              <tbody>
                {response.data
                  ?.filter((item) => {
                    if (selectValue.length === 0) return true;
                    return selectValue.length > 0 && item?.associate?.id === selectValue;
                  })
                  ?.filter((item) => {
                    if (startDate === null || endDate === null || startDate === '' || endDate === '' || selectFilter == '') return true;
                    const associateDate = new Date(item?.associate?.createdAt);
                    const startDateObj = new Date(startDate);
                    const endDateObj = new Date(endDate);
                    return (associateDate >= startDateObj && associateDate <= endDateObj);
                  })
                  ?.filter((item) => item?.associate?.status == 'Approved')
                  .map((item, associateIndex) => (
                    <React.Fragment key={associateIndex}>
                      {item?.allStudent?.map((subItem, studentIndex) => (
                        <React.Fragment key={studentIndex}>
                          {subItem?.payments?.length > 0 ? (
                            subItem.payments.map((payment, paymentIndex) => (
                              <tr key={paymentIndex}>
                                <th>SU{convertToFiveDigits(subItem?.orders?.id)}</th>
                                <th>{payment?.createdAt && !isNaN(new Date(payment?.createdAt)) ? new Date(payment.createdAt).toLocaleDateString('en-GB').split('/').join('-') : 'nil'}</th>
                                <th>{subItem?.studentName}</th>
                                <th>{subItem?.mobileNumber}</th>
                                <th>product</th>
                                <th>{subItem.appStudentId}</th>
                                <th>₹{payment.amount}</th>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <th>SU{convertToFiveDigits(subItem?.orders?.id)}</th>
                              <th>-</th> {/* No payment date available */}
                              <th>{subItem?.studentName}</th>
                              <th>{subItem?.mobileNumber}</th>
                              <th>product</th>
                              <th>{subItem.appStudentId}</th>
                              <th>-</th> {/* No payment amount available */}
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
                <tr>
                  <th>Total Sales : </th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>₹{totalSales}</th>
                </tr>
              </tbody>
            </table>



          </div>
        </div>
      </div>


    </div>
  )
}

export default UserSalesReport