import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Headbar from './Headbar'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import SignOutConfirmation from '../modals/SignOutConfirmation'

const Layout = () => {

    const { pathname } = useLocation()

    useEffect(() => {
        const classList = document.body.classList
        if (classList.contains("modal-open")) classList.remove("modal-open")

        const backdrops = document.getElementsByClassName("modal-backdrop")
        for (const backdrop of backdrops) backdrop.remove()
    }, [pathname])

    return (
        <div className='d-flex overflow-hidden h-100 w-100 '>
            <Sidebar />
            <main className='d-flex flex-column overflow-hidden flex-grow-1 bg-light'>
                <div className='overflow-auto h-100'>
                    <Navbar />
                    <Outlet />
                </div>

                <SignOutConfirmation />
            </main>
        </div>
    )
}

export default Layout