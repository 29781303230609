import React from 'react'
import Input from '../../components/form/Input'
import SelectInput from '../../components/form/SelectInput'
import { useGetAssociatesQuery } from '../../services/associateSlice'
import { usePostOrderMutation } from '../../services/orderSlice'
import { useGetDesignationsQuery } from '../../services/designationSlice'
import { useGetProductsQuery } from '../../services/productSlice'

const CreateOrder = () => {

    const [postOrder, postStudentResponse] = usePostOrderMutation()

    const sponserData = useGetAssociatesQuery()
    const productData = useGetProductsQuery()

    const designationData = useGetDesignationsQuery()


    const handleSubmit = (e) => {

        e.preventDefault();

        const body = {
            designationId: e.target['Designation Id'].value,
            courseId: e.target["Course Id"].value,
            mobileNumber: e.target["Mobile Number"].value
        }

    

        postOrder(body)
            .then(() => {
                productData.refetch()
                sponserData.refetch()
                designationData.refetch()
                alert("Successfully Created")
            })

    }


    return (
        <div className='container p-5'>
            <h4 className='fw-bold mb-3'>Create Order</h4>


      <h5 className='text-muted mb-3'>Personal Details</h5>
            <form onSubmit={handleSubmit} >
                <div className='row row-cols-3 g-3'>
                    {/* <Input containerClass="" name={"Designation Id"} /> */}
                     <SelectInput
                        name={"Designation Id"}
                        options={
                            designationData?.data?.map((item) => ({
                                value: item?.id,
                                label: item?.name,
                              })) || []
                            }
                    />
                    <SelectInput
                        name={"Course Id"}
                        options={
                            productData?.data?.map((item) => ({
                                value: item?.course?.id,
                                label: item?.course?.name,
                              })) || []
                            }
                    />
                    <Input containerClass="" name={"Mobile Number"} />
                    {/* <Input containerClass="" name={"Sponser Name"} /> */}

                    {/* <SelectInput
                        name="SponsorName"
                        options={
                            sponserData?.data?.map((sponsor) => ({
                                value: sponsor.associate.id,
                                label: sponsor.associate.name,
                              })) || []
                            }
                    /> */}



                </div>


              

                <input type='submit' value='Submit' className='btn btn-primary mt-5 float-end' />
            </form>

        </div>
    )
}

export default CreateOrder