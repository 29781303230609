import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import Input from '../../components/form/Input'
import Icons from '../../components/ui/Icons'
import SelectInput from '../../components/form/SelectInput'
import { useGetProductsQuery } from '../../services/productSlice'
import Loading from '../../components/ui/Loading'
import { useGetStudentsQuery } from '../../services/studentSlice'
import ReactToPrint from 'react-to-print';
import { useEffect } from 'react'
import { useGetCategoriesQuery } from '../../services/categorySlice'

const ProductSalesReport = () => {

  const [selectValue, setSelectValue] = useState("")
  const [selectCat, setSelectCat] = useState("")
  const [searchValue, setSearchValue] = useState("")
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [searchYear, setSearchYear] = useState("")

  const [selectFilter, setSelectFilter] = useState("")




  const modalBodyRef = useRef();


  const courseRes = useGetProductsQuery()
  const categoryRes = useGetCategoriesQuery()
  const response = useGetStudentsQuery()

  function convertToFiveDigits(number) {

    return String(number).padStart(5, '0');
  }

  useEffect(() => {
    let filterStartDate;
    let filterEndDate;

    if (selectFilter === 'last 7 days') {
      filterEndDate = new Date();
      filterStartDate = new Date(new Date().setDate(new Date().getDate() - 6));
    } else if (selectFilter === 'last month') {
      filterEndDate = new Date();
      filterStartDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
    } else if (selectFilter === 'Previous year') {
      filterEndDate = new Date(new Date().getFullYear() - 1, 11, 31);
      filterStartDate = new Date(new Date().getFullYear() - 1, 0, 1);
    } else if (selectFilter === 'Current year') {
      filterEndDate = new Date();
      filterStartDate = new Date(new Date().getFullYear(), 0, 1);
    } else if (selectFilter === 'Current day') {
      filterEndDate = new Date();
      filterStartDate = new Date();
      filterStartDate.setHours(0, 0, 0, 0);
      filterEndDate.setHours(23, 59, 59, 999);
    } else if (selectFilter === 'Yesterday') {
      filterEndDate = new Date(new Date().setDate(new Date().getDate() - 1));
      filterStartDate = new Date(new Date().setDate(new Date().getDate() - 1));
      filterStartDate.setHours(0, 0, 0, 0);
      filterEndDate.setHours(23, 59, 59, 999);
    } else if (selectFilter === 'This Week') {
      const today = new Date();
      const dayOfWeek = today.getDay();
      filterStartDate = new Date(today.setDate(today.getDate() - dayOfWeek));
      filterEndDate = new Date();
      filterStartDate.setHours(0, 0, 0, 0);
      filterEndDate.setHours(23, 59, 59, 999);
    } else if (selectFilter === 'Current Month') {
      const today = new Date();
      filterStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
      filterEndDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      filterStartDate.setHours(0, 0, 0, 0);
      filterEndDate.setHours(23, 59, 59, 999);
    }
    else if (selectFilter === 'Custom' && startDate && endDate) {
      filterStartDate = new Date(startDate);
      filterEndDate = new Date(endDate);
    }
    setStartDate(filterStartDate)
    setEndDate(filterEndDate)
  }, [response.isSuccess, courseRes.isSuccess, selectFilter]);


  if (courseRes.isLoading || response.isLoading || categoryRes.isLoading) return <Loading />

  const filteredData = response.data
    ?.filter((item) => {
      if (searchValue?.length === 0 && selectValue?.length === 0) return true;
      const isSearchMatch = searchValue?.length > 0 && (
        `SU${convertToFiveDigits(item?.orders[0]?.id)}`.toLowerCase().includes(searchValue.toLowerCase())
      );
      const isProductMatch = selectValue?.length > 0 && item?.course?.id === selectValue;
      if (searchValue?.length === 0 && isProductMatch) return isProductMatch;
      if (selectValue?.length === 0 && isSearchMatch) return isSearchMatch;
      return isSearchMatch && isProductMatch;
    })
    ?.filter((item) => {
      if (selectCat?.length === 0 && selectCat?.length === 0) return true;
      const isCategoryMatch = selectCat?.length > 0 && item?.course?.cateogryId == selectCat;
      return isCategoryMatch;
    })
    ?.filter((item) => {
      if (startDate === null || endDate === null || startDate === '' || endDate === '' || selectFilter == '') return true;
      const studentDate = new Date(item?.student?.createdAt);
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);
      return (studentDate >= startDateObj && studentDate <= endDateObj)
    })


  const totalRecords = filteredData?.length;
  const tm = filteredData?.reduce((total, item) => total + (parseFloat(item?.course?.amount) || 0), 0)



  return (
    <div className='p-5'>

      <h4 className='text-primary fw-bold mb-3'>Reports</h4>

      <div className='d-flex mb-3 flex-wrap align-items-center justify-content-between mb-5'>

        <div className='d-flex  w-100 me-5 gap-4'>
          {/* <SelectInput
            name={"C FY"}
            options={
              [{ label: "2023-24", value: "2023-24" }]
            }
            required
            containerClass={' w-100'}
          /> */}


          <SelectInput
            name={"Category"}
            options={[
              ...[{
                value: '',
                label: 'Select',
              }],
              ...categoryRes?.data?.map((item) => ({
                value: item.id,
                label: item.name,
              }))
            ]}

            value={selectCat} onChange={(e) => setSelectCat(e.target.value)}
            containerClass={'w-100 '}
            required
          />

          <SelectInput
            name={"Product"}
            options={[
              ...[{
                value: '',
                label: 'Select',
              }],
              ...courseRes?.data?.filter((item) => item.course.categoryId == selectCat)?.map((item) => ({
                value: item.course.id,
                label: item.course.name,
              }))
            ]}

            value={selectValue} onChange={(e) => setSelectValue(e.target.value)}
            containerClass={'w-100 '}
            required
          />



          <SelectInput
            name="Select Filter"
            options={[
              { value: '', label: 'Select' },
              { value: 'Current day', label: 'Today' },
              { value: 'Yesterday', label: 'Yesterday' },
              { value: 'This Week', label: 'This Week' },
              { value: 'Current Month', label: 'Current Month' },
              { value: 'last month', label: 'Previous month' },
              { value: 'Current year', label: 'Current year' },
              { value: 'Previous year', label: 'Previous year' },
              { value: 'Custom', label: 'Custom' },
            ]}
            value={selectFilter}
            onChange={(e) => setSelectFilter(e.target.value)}
            containerClass="w-100"
            required
          />

          {selectFilter === 'Custom' && (
            <>
              <div className=" mt-4 ">
                <input
                  type="date"
                  className="form-control"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div className="mt-4">
                <input
                  type="date"
                  className="form-control"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </>
          )}


        </div>


        <div className='d-flex align-items-center'>


          <ReactToPrint
            trigger={() => (
              <div className='bg-primary  rounded text-center px-2 py-1 text-nowrap'>
                <span className="text-white me-1">Download</span> {Icons.download('')}
              </div>
            )}
            content={() => modalBodyRef.current}
            pageStyle={`@page { size: A5; margin: 0; }`}
          />

        </div>

      </div>



      {
        selectValue &&

        <>
          <div className='d-flex justify-content-between mb-3'>

            <div className='bg-primary text-white rounded-3 p-3 me-3'>{Icons.report()}</div>
            <div>
              <p>Total Sales</p>
              <p className='text-primary'>₹{tm}</p>
            </div>

            <div className='ms-2'>
              <p>Total Invoice</p>
              <p className='text-primary'>{totalRecords}</p>
            </div>

            <div className='flex-grow-1'></div>

            <Input name={"Search Invoice"} containerClass={"ms-3"} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
          </div>

          <div className='card rounded-4 overflow-hidden shadow ' >

            <div className="table-responsive">
              <table className="table table-borderless table-hover" ref={modalBodyRef}>
                <thead>


                  <tr className='border-bottom'>
                    <th scope="col" className='text-muted'>Order ID</th>
                    <th scope="col" className='text-muted'>Date</th>
                    <th scope="col" className='text-muted'>Name</th>
                    <th scope="col" className='text-muted'>Mobile No</th>
                    <th scope="col" className='text-muted'>App Id</th>
                    <th scope="col" className='text-muted'>Product</th>
                    <th scope="col" className='text-muted'>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {response.data
                    ?.filter((item) => {

                      if (searchValue?.length === 0 && selectValue?.length === 0) return true
                      const isSearchMatch = searchValue?.length > 0 && (
                        `SU${convertToFiveDigits(item?.orders[0]?.id)}`.toLowerCase().includes(searchValue.toLowerCase())
                      );
                      const isProductMatch = selectValue?.length > 0 && item?.course?.id === selectValue;
                      if (searchValue?.length === 0 && isProductMatch) return (isProductMatch);
                      if (selectValue?.length === 0 && isSearchMatch) return (isSearchMatch);
                      return (isSearchMatch && isProductMatch);
                    })
                    ?.filter((item) => {
                      if (selectCat?.length === 0 && selectCat?.length === 0) return true;
                      const isCategoryMatch = selectCat?.length > 0 && item?.course?.categoryId == selectCat;
                      return isCategoryMatch;
                    })
                    ?.filter((item) => {
                      if (startDate === null || endDate === null || startDate === '' || endDate === '' || selectFilter == '') return true;
                      const studentDate = new Date(item?.student?.createdAt);
                      const startDateObj = new Date(startDate);
                      const endDateObj = new Date(endDate);
                      return (studentDate >= startDateObj && studentDate <= endDateObj)
                    })
                    ?.map((item, i) =>
                      <tr key={i}>
                        <th scope="row">SU{convertToFiveDigits(item?.orders[0]?.id)}</th>
                        <th className=''>{item?.student?.createdAt && !isNaN(new Date(item?.student?.createdAt)) ? new Date(item?.student?.createdAt).toLocaleDateString('en-GB').split('/').join('-') : ''}</th>
                        <th>{item?.student?.studentName}</th>
                        <th>{item?.student?.mobileNumber}</th>
                        <th>{item?.student?.appStudentId}</th>
                        <th>{item?.course?.name}</th>
                        <th className=''>₹{item?.course?.amount}</th>
                      </tr>
                    )}


                </tbody>
              </table>
            </div>

          </div>
        </>
      }

    </div>
  )
}

export default ProductSalesReport