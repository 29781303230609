import React from 'react';

const SelectInput = ({ name, options, containerClass, placeholder, inputClass,noLabel, ...attributes }) => {
  const containerClassName = 'd-flex flex-column align-items-start col   ' + (containerClass ? containerClass : '');
  const selectClassName = 'form-select rounded-pill px-3 border-primary border-2 ' + (inputClass ? inputClass : '');
  const lableClass = "fw-bold mb-1 ms-1";

  return (
    <div className={containerClassName}>
      {noLabel || <label htmlFor={name} className={lableClass}>{placeholder ? placeholder : name}</label>}
      <select className={selectClassName} id={name} name={name} {...attributes}>
        {options &&
          options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
      </select>
    </div>
  );
};

export default SelectInput;
