import React, { useRef } from 'react';
import Icons from '../../components/ui/Icons';
import Api from '../../constants/Api';
import ReactToPrint from 'react-to-print';
import logo from '../../assets/images/logo_sudikshya.png'
import { convertToFiveDigits } from '../../utils/ConvertToFive';
import { Link } from 'react-router-dom';
import { useGetPackageQuery } from '../../services/adminSlice';
import numberToWords from 'number-to-words';

const AssociateInvoiceModal = ({ item, linkk }) => {

    const getpackage = useGetPackageQuery(1)

    console.log("itms",item)

    const modalId = "AssociateInvoiceDownloadModal" + item.associate.id;
    const modalBodyRef = useRef();

    const invoiceData = {
        invoiceNumber: item?.orders[0]?.id && `INV${convertToFiveDigits(item?.orders[0]?.id)}`,
        date: item.payment?.createdAt,
        items: [
            { associateId: item.associate.serialNo, associateName: item.associate.name, trackingId:getpackage?.data?.packageName, status: item.payment?.status, amount: item.payment?.amount, },
            // Add more items as needed
        ],
        subtotal: item.payment?.amount,

        total: item.payment?.amount,
    };

    const ModalBody = () => (
        <div className="modal-body p-0" id={modalId + "ModalBody"} ref={modalBodyRef} style={{zoom:'60%'}}>

            <div className="card invoice-preview-card p-5">
                <div className="card-body p-0">
                    <div className="d-flex justify-content-between flex-row">
                        <div className="mb-xl-0 mb-4 d-flex flex-column justify-content-center">
                            <h1 className="text-primary fw-bold" style={{ fontFamily: "'Concert One', sans-serif", textTransform: "uppercase" }}>Invoice</h1>
                            <p className="mb-1">Invoice# {invoiceData.invoiceNumber}</p>
                        </div>
                        <div className="d-flex align-items-end flex-column p-0">
                            <img alt="" className="w-3 mb-4" src="https://i.imgur.com/MF6POBh.png" style={{ maxWidth: '270px' }} />
                            <h5 className="mb-2 text-primary fw-bold" style={{ fontFamily: "'Concert One', sans-serif", textTransform: "uppercase" }}>Sudikshya Learning Private Limited</h5>
                            <div style={{ fontSize: '14px' }}>
                                <span className="me-1">CIN</span> <span className="fw-bold">U80904OR2022PTC038955</span>
                            </div>
                            <div style={{ fontSize: '14px' }}>
                                <span>Bhubaneswar, Odisha, Pin-751019</span>
                            </div>
                            <div style={{ fontSize: '14px' }}>
                                <span>support@sudikshya.com</span>
                            </div>
                            <div style={{ fontSize: '14px' }}>
                                <span>www.sudikshya.com</span>
                            </div>
                            <div style={{ fontSize: '14px' }}>
                                <span>1800 103 5040</span>
                            </div>
                            <div style={{ fontSize: '14px' }}>
                                <span className="mt-2 badge rounded-pill bg-success p-2 px-4 fs-6">Paid</span>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="my-0 mt-4" style={{ opacity: '0.04' }} />
                <div className="card-body p-0 py-4">
                    <div className="row">
                        <div className="col-8">
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="pe-3">Invoice Date</td>
                                        <td className="text-primary">: { invoiceData.date && !isNaN(new Date(invoiceData.date)) ? new Date(invoiceData.date).toLocaleDateString('en-GB').split('/').join('-') : '' }</td>
                                    </tr>
                                    <tr>
                                        <td className="pe-3">Contact Person</td>
                                        <td className="text-primary">: {item?.sponsor?.name} </td>

                                    </tr>
                                    <tr>
                                        <td className="pe-3">Contact Person Id</td>
                                        <td className="text-primary">: {`S3A${convertToFiveDigits(item?.sponsor?.serialNo)}`}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-4">
                            <div className="mb-2" style={{ fontSize: '14px' }}>
                                <span className="me-1 d-block">Associate Name</span> <span className="text-primary fw-bold">{item?.associate?.name}</span>
                            </div>
                            <div className="mb-2" style={{ fontSize: '14px' }}>
                                <span className="me-1 d-block">Order Id</span> <span className="text-primary fw-bold">{`SU${convertToFiveDigits(item?.orders[0]?.id)}`}</span>
                            </div>
                            <div className="mb-2" style={{ fontSize: '14px' }}>
                                <span className="me-1 d-block">Phone Number</span> <span className="text-primary fw-bold">{item?.associate?.mobileNumber}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                 
                    <div className="table-responsive">
                    <table className="table   m-0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Description</th>
                                <th></th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoiceData.items.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td >  {item.trackingId}</td>
                                    <td></td>
                                    <td>Rs.{item.amount}</td>
                                </tr>
                            ))}
                            <tr  className='border border-0 '  >
                                <td className="align-top px-4 py-5 border border-0 " colSpan={2}>

                                    
                                </td>


                                <td className="text-end px-4 py-5 border border-0">
                                    <p className="mb-2">Subtotal:</p>
                                    <p className="mb-2">Discount:</p>
                                    <p className="mb-0" style={{ background: '#f9f0ff', padding: '8px', borderRadius: '10px' }}>Total:</p>
                                </td>
                                <td className="px-4 py-5 border border-0">
                                    <p className="fw-medium mb-2 text-primary">₹{invoiceData.subtotal}</p>
                                    <p className="fw-medium mb-2 text-danger">(-) ₹0</p>
                                    <p className="fw-medium mb-0" style={{ background: '#f9f0ff', padding: '8px', borderRadius: '10px' }}>₹{invoiceData.total}</p>
                                </td>
                            </tr>

                          
                            <tr className='border border-0' >
                                <td className="align-top p-0 pb-3 border border-0  fs-6" colSpan={2}>
                             
                                    <div>Amount In Words: <span className="fw-bold text-primary  text-uppercase"> {numberToWords.toWords(Number(invoiceData?.total)||0 ) }</span> </div>
                                    <div>Payment Mode: <span className="fw-bold text-primary">{item?.associate?.paymentMode}</span></div>
                                </td>


                               
                            </tr>


                            

                        </tbody>
                    </table>

                </div>

                </div>
                <div className="card-body p-0">
                    <div className="row">
                        <div className="col-12">
                            <h5 className="fw-bold text-primary d-block fst-italic mb-3">Terms &amp; Conditions</h5>
                            <ul style={{ fontSize: '14px', listStyle: 'devanagari' }}>
                                <li><b>Payment:</b> Full payment is due upon invoice receipt; late payments may incur charges or access suspension.</li>
                                <li><b>Usage:</b> Users agree not to modify, distribute, or create derivative works without explicit permission.</li>
                                <li><b>Access & Support:</b>  Access provided post-payment; limited technical support available during specified hours.</li>
                                <li><b>Refund & Cancellation:</b> We do not entertain requests for refunds or cancellations under any circumstances.</li>
                                <li><b>Disclaimer:</b> No warranty on uninterrupted or flawless use; usage is at the user's risk.</li>
                                <li><b>Liability Limitation:</b>  Sudikshya is not liable for direct, indirect, or consequential damages.</li>
                                <li><b>Modifications:</b> Sudikshya reserves the right to modify terms with prior notice.</li>
                            </ul>
                            <p style={{ fontSize: '14px' }}><b>Associate Declaration:</b> By making payment and accessing the Associate app, I affirm my acceptance of the outlined terms and conditions.</p>
                            <p style={{ fontSize: '14px' }} className="text-primary mt-2"><b>Digitally Signed By Associate</b></p>
                        </div>
                    </div>
                </div>
                <div className="card-body p-0 mt-3">
                    <div className="row">
                        <div className="col-12">
                            <p className="fw-bold text-primary d-block mb-4" style={{ fontSize: '14px', textAlign: 'right' }}>For Sudikshya Learning Private Limited</p><br />
                            <p className="fw-bold text-primary d-block mt-4" style={{ fontSize: '14px', textAlign: 'right' }}>Authorised Signatory</p>
                        </div>
                    </div>
                </div>
            </div>





            {/* //---------------9999999999999999 */}
        </div>
    );

    return (
        <>

            {

                (!linkk) ? <div className='bg-primary rounded text-center px-2 py-1 text-white text-nowrap'>
                    <span className='' data-bs-toggle="modal" data-bs-target={"#" + modalId}>Invoice</span>
                </div> :
                    <Link className=''>
                        <span className='' data-bs-toggle="modal" data-bs-target={"#" + modalId}>Download Invoice Report</span>
                    </Link>

            }

            <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby={modalId + "Label"} aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id={modalId + "Label"}>{item.associate.name}'s Invoice</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <ModalBody />
                        <div className="modal-footer">
                            {/* <ReactToPrint
                                trigger={() => (
                                    <div className='bg-primary  rounded text-center px-2 py-1 text-nowrap'>
                                        <span className="text-white me-1">Download</span> <span>{Icons.download('')}</span>
                                    </div>
                                )}
                                content={() => modalBodyRef.current}
                            /> */}

                            <ReactToPrint
                                trigger={() => (
                                    (linkk) ? <div className='bg-primary  rounded text-center px-2 py-1 text-nowrap'>
                                        <span className="text-white me-1">Download</span> {Icons.download('')}
                                    </div> : <Link>Download</Link>
                                )}
                                content={() => modalBodyRef.current}
                                pageStyle={`@page { size: A5; margin: 0; }`}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AssociateInvoiceModal;
