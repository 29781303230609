import React from 'react'
import Input from '../../components/form/Input'
import SelectInput from '../../components/form/SelectInput'
import { useGetAssociatesQuery } from '../../services/associateSlice'
import { useGetProductQuery, usePostProductMutation, useUpdateProductMutation } from '../../services/productSlice'
import { useGetCategoriesQuery } from '../../services/categorySlice'
import SuccessModal from '../../components/modals/SuccessModal'
import ApiErrorModal from '../../components/modals/ApiErrorModal'
import { useParams } from 'react-router-dom'
import Loading from '../../components/ui/Loading'

const EditProducts = () => {

    const {id} = useParams()

    const [updateProduct, updateProductResponse] = useUpdateProductMutation()
    const categoryData = useGetCategoriesQuery()
    const productData = useGetProductQuery(id);
    




    const handleSubmit = (e) => {

        e.preventDefault();
        const form = e.target;

        if (form.checkValidity()) {
            const body = {
                name: e.target['Name'].value,
                description: e.target['Description'].value,
                amount: e.target["Amount"].value,
                categoryId: e.target["Category Id"].value,
                // order: e.target["Order"].value
            }

            updateProduct({id,body})
                .then(() => {
                    form.classList.remove('was-validated')
                    form.reset()
                    productData.refetch();
                    categoryData.refetch();
                })
        }
        else {
            form.classList.add('was-validated')
        }
    }

    if(categoryData.isLoading) return <Loading />
    if(productData.isLoading) return <Loading />

    return (
        <div className='container p-5'>

            {updateProductResponse.isSuccess && <SuccessModal message={"Product has been Updated."} />}
            {updateProductResponse.isError && <ApiErrorModal response={updateProductResponse} />}

            <h4 className='fw-bold mb-3'>Edit Product</h4>

            <h5 className='text-muted mb-3'> Details</h5>
            <form onSubmit={handleSubmit} noValidate>
                <div className='row row-cols-3 g-3'>
                    <Input containerClass="" name={"Name"} defaultValue={productData?.data?.course?.name} required />
                    <Input containerClass="" name={"Description"} defaultValue={productData?.data?.course?.description} required />
                    <Input containerClass="" type={"number"} name={"Amount"} defaultValue={productData?.data?.course?.amount} required />
                    {/* <Input containerClass="" name={"Category Id"} /> */}
                    <SelectInput
                        name={"Category Id"}
                        options={
                            categoryData?.data?.map((item) => ({
                                value: item?.id,
                                label: item?.name,
                            })) || []
                        }
                        defaultValue={productData?.data?.category?.id}
                        required
                    />
                    {/* <Input containerClass="" name={"Order"} required /> */}
                    {/* <Input containerClass="" name={"Sponser Name"} /> */}

                    {/* <SelectInput
                        name="SponsorName"
                        options={
                            sponserData?.data?.map((sponsor) => ({
                                value: sponsor.associate.id,
                                label: sponsor.associate.name,
                              })) || []
                            }
                    /> */}
                </div>

                {
                    updateProductResponse.isLoading ?
                        <button class='btn btn-primary mt-5 float-end' type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Submitting...
                        </button>
                        :
                        <button className='btn btn-primary mt-5 float-end'>Submit</button>
                }



            </form>

        </div>
    )
}

export default EditProducts